import { Typography } from '@mui/material';
import styled from 'styled-components';
import { Spacing } from 'Themes/spacing';

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  gap: ${Spacing.small};
  justify-content: center;
  border-radius: 8px 8px 0 0;
`;

export const MissingPreviewLinkIcon = styled.img`
  font-size: clamp(20px, 2.5vw, 3rem);
`;

export const InfoText = styled(Typography)`
  margin-top: 4px;
  text-align: center;
  font-style: italic;
`;

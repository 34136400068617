import { MenuItem, FormControl, Select, SelectChangeEvent } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'Store';
import { supportedLanguages } from 'Consts/language';
import { useCurrentLanguage } from 'Utils/customHooks';
import { LoginState, selectLoginState } from 'Auth/authSlice';
import { saveLanguage } from 'User/userSlice';

export const LanguageBar = () => {
  const dispatch = useAppDispatch();

  const currentLanguage = useCurrentLanguage();
  const loginState = useAppSelector(selectLoginState);

  const onLanguageChange = (event: SelectChangeEvent) => {
    const isLogged = loginState === LoginState.LOGGED;
    dispatch(saveLanguage({ languageCode: event.target.value, isLogged }));
  };

  const menuItems = supportedLanguages.map(language => (
    <MenuItem key={language.code} value={language.code}>
      {language.displayName}
    </MenuItem>
  ));

  return (
    <FormControl size="small">
      <Select value={currentLanguage.code} onChange={onLanguageChange}>
        {menuItems}
      </Select>
    </FormControl>
  );
};

export enum ELanguageCodes {
  Pl = 'pl-PL',
  En = 'en-US'
}

export const appDefaultLanguageCode = ELanguageCodes.En;

export enum ELanguageDisplayNames {
  Polski = 'PL',
  English = 'EN'
}

export interface ILanguage {
  code: string;
  displayName: ELanguageDisplayNames;
}

export const supportedLanguages: ILanguage[] = [
  { code: ELanguageCodes.Pl, displayName: ELanguageDisplayNames.Polski } as ILanguage,
  { code: ELanguageCodes.En, displayName: ELanguageDisplayNames.English } as ILanguage
];

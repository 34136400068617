import { useTranslation } from 'react-i18next';
import { CheckboxElement, FormContainer } from 'react-hook-form-mui';
import { LogInRequest } from 'Api';
import { AlertError } from 'Common/AlertError';
import { useAppForm } from 'Utils/customHooks';
import { StyledAuthFormContainer, StyledButton } from 'Components/Styled';
import { EmailField, PasswordField } from 'Common/Form';
import { LoginSchema } from 'Common/Form/ValidationSchemas';

const formFieldNames = {
  email: 'email',
  password: 'password',
  rememberMe: 'rememberMe'
} as const;

interface LoginFormProps {
  onSubmit: (formData: LogInRequest) => void;
  onEmailChange: (email: string) => void;
  error: string;
}

export function LoginForm({ onSubmit, onEmailChange, error }: LoginFormProps) {
  const { t } = useTranslation();

  const formContext = useAppForm<LogInRequest>(LoginSchema);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onEmailChange(event.target.value);

  return (
    <FormContainer onSuccess={onSubmit} formContext={formContext}>
      <StyledAuthFormContainer>
        {error && <AlertError text={t(error)} />}

        <EmailField name={formFieldNames.email} onChange={handleEmailChange} />

        <PasswordField name={formFieldNames.password} />

        <CheckboxElement name={formFieldNames.rememberMe} label={t('auth.rememberMe')} />

        <StyledButton type="submit" size="large">
          {t('auth.login')}
        </StyledButton>
      </StyledAuthFormContainer>
    </FormContainer>
  );
}

export default LoginForm;

import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { SaveIsFreshStartMachineReadyToCloneRequest } from 'Api';
import { useAppDispatch, useAppSelector } from 'Store';
import { openExternalUrl } from 'Utils/redirects';
import { useBoolean } from 'Utils/customHooks';
import ConfirmationDialog from 'Common/ConfirmationDialog/ConfirmationDialog';
import {
  connectToFreshStartDesktop,
  selectIsFreshStartDesktopStartPending,
  selectIsFreshStartDesktopStopPending,
  selectIsFreshStartDesktopDeletePending,
  selectFreshStartDesktopLink,
  saveIsFreshStartMachineReadyToClone
} from 'Courses/coursesSlice';
import { ConnectToDesktopButton } from './ConnectToDesktopBase';

interface ConnectToFreshStartDesktopButtonProps {
  courseId: string;
  isFreshStartMachineReadyToClone?: boolean;
  disabled?: boolean;
}

const ConnectToFreshStartDesktopButton = ({
  courseId,
  isFreshStartMachineReadyToClone,
  disabled
}: ConnectToFreshStartDesktopButtonProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [
    connectToFreshStartDialogIsDisplayed,
    displayConnectToFreshStartDialog,
    hideConnectToFreshStartDialog
  ] = useBoolean();

  const isFreshStartDesktopStartPending = useAppSelector(state =>
    selectIsFreshStartDesktopStartPending(state, courseId)
  );

  const isFreshStartDesktopStopPending = useAppSelector(state =>
    selectIsFreshStartDesktopStopPending(state, courseId)
  );

  const isFreshStartDesktopDeletePending = useAppSelector(state =>
    selectIsFreshStartDesktopDeletePending(state, courseId)
  );

  const freshStartDesktopLink = useAppSelector(state =>
    selectFreshStartDesktopLink(state, courseId)
  );

  const redirectToDesktop = (desktopLink: string) => openExternalUrl(desktopLink);

  const shouldNotRedirectToNewTab = true;

  const connectToDesktop = () =>
    dispatch(connectToFreshStartDesktop(courseId)).then(() => {
      !shouldNotRedirectToNewTab &&
        freshStartDesktopLink &&
        redirectToDesktop(freshStartDesktopLink as string);
    });

  const handleConnect = () => {
    connectToDesktop();

    dispatch(
      saveIsFreshStartMachineReadyToClone({
        courseId,
        isFreshStartMachineReadyToClone: false
      } as SaveIsFreshStartMachineReadyToCloneRequest)
    );

    hideConnectToFreshStartDialog();
  };

  const onConnectToDesktopClick = () => {
    if (isFreshStartMachineReadyToClone) {
      displayConnectToFreshStartDialog();
    } else {
      handleConnect();
    }
  };

  return (
    <>
      <ConnectToDesktopButton
        buttonText={t('Connect')}
        onConnectToDesktopClick={onConnectToDesktopClick}
        disabled={disabled}
        isDesktopStartPending={isFreshStartDesktopStartPending}
        isDesktopStopPending={isFreshStartDesktopStopPending}
        isDesktopDeletePending={isFreshStartDesktopDeletePending}
        isFreshStart
      />

      <ConfirmationDialog
        open={connectToFreshStartDialogIsDisplayed}
        content={`${t('ConnectToFreshStartWarning')} ${t('AreYouSure')}`}
        onNo={hideConnectToFreshStartDialog}
        onYes={handleConnect}
      />
    </>
  );
};

export default memo(ConnectToFreshStartDesktopButton);

import styled from 'styled-components';
import { Colors } from 'Themes/colors';

export const StyledWrapper = styled.nav`
  display: flex;
  margin-bottom: 10px;
  background: ${Colors.white};
  position: relative;
  height: 76px;
  align-items: center;
  &:before {
    content: '';
    background: ${Colors.white};
    position: absolute;
    height: 100%;
    width: 100vw;
    z-index: -1;
    margin: 0 calc(-50vw + 50%);
  }
`;

export const NavbarDivider = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex: 1;
`;

export const NavbarSide = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

import { Link as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Colors } from 'Themes/colors';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  color: ${Colors.blue};
`;

export const linkStyle = css`
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 100%;
  text-decoration-line: underline;
  white-space: nowrap;
  cursor: pointer;
  color: ${Colors.blue} !important;
  &:visited {
    color: ${Colors.blue} !important;
  }
`;

const StyledLink = styled(RouterLink)`
  ${linkStyle}
`;

interface Props {
  to: string;
  label: string;
  leftIcon?: React.ReactNode;
  shouldOpenInNewTab?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state?: any;
}

export function Link({ to, label, leftIcon, shouldOpenInNewTab = true, state }: Props) {
  return (
    <Container>
      {leftIcon}
      <StyledLink to={to} target={shouldOpenInNewTab ? '_blank' : '_self'} state={state}>
        {label}
      </StyledLink>
    </Container>
  );
}

import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { useOnClickOutside } from 'usehooks-ts';
import { Button, Typography } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import styled from 'styled-components';
import { Colors } from 'Themes/colors';
import { Spacing } from 'Themes/spacing';
import { Typo } from 'Themes/typo';
import { useToggle } from 'Utils/customHooks';
import copyToClipboard from 'Utils/copyToClipboard';
import { OptionsModal, ActionPosition, HeadDialog } from 'Components';
import InsertLinkIcon from '@mui/icons-material/InsertLink';

const MainContainer = styled.div`
  position: relative;
`;

const SettingsButton = styled(Button)`
  padding: 0 !important;
  color: ${Colors.darkGrey} !important;

  &:hover {
    color: ${Colors.white} !important;
  }
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${Colors.white};
  gap: ${Spacing.small};
`;

const StyledInsertLinkIcon = styled(InsertLinkIcon)`
  transform: rotateZ(138deg);
`;

const ShowLinkContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: ${Spacing.medium};
`;

const BigTypography = styled(Typography)`
  color: ${Colors.black} !important;
  font-size: 44px !important;
  font-weight: 700 !important;
`;

interface Props {
  onStartParticipantsMachines: () => void;
  onStopParticipantsMachines: () => void;
  onRemoveParticipantsMachines: () => void;
  shouldShowStartParticipantsMachines: boolean;
  shouldShowStopParticipantsMachines: boolean;
  shouldShowRemoveParticipantsMachines: boolean;
  joinLink: string;
}

export function SettingsButtonModal({
  onStartParticipantsMachines,
  onStopParticipantsMachines,
  onRemoveParticipantsMachines,
  shouldShowStartParticipantsMachines,
  shouldShowStopParticipantsMachines,
  shouldShowRemoveParticipantsMachines,
  joinLink
}: Props) {
  const [showOptions, toggleOptions] = useToggle();
  const handleClickOutside = useCallback(() => {
    toggleOptions();
  }, [toggleOptions]);

  const ref = useRef(null);
  useOnClickOutside(ref, handleClickOutside);

  const { t } = useTranslation();

  const handleCopyJoinLink = () => {
    copyToClipboard(joinLink);
    toast(t('SuccessfullyCopiedToTheClipboard'));
  };

  const [showJoinLink, toggleShowJoinLink] = useToggle();

  const handleShowLink = () => {
    toggleShowJoinLink();
    toggleOptions();
  };

  return (
    <MainContainer>
      <SettingsButton variant="outlined" onClick={toggleOptions}>
        <SettingsIcon />
      </SettingsButton>

      {showOptions && (
        <OptionsModal onClose={toggleOptions}>
          <Options>
            {shouldShowStartParticipantsMachines ? (
              <ActionPosition
                onClick={onStartParticipantsMachines}
                icon={<PlayCircleIcon style={{ color: Colors.green }} />}
                text={t('coursePanel.startAllComputers')}
              />
            ) : (
              ''
            )}
            {shouldShowStopParticipantsMachines ? (
              <ActionPosition
                onClick={onStopParticipantsMachines}
                icon={<RemoveCircleIcon style={{ color: Colors.green }} />}
                text={t('coursePanel.stopAllComputers')}
              />
            ) : (
              ''
            )}

            {shouldShowRemoveParticipantsMachines ? (
              <ActionPosition
                onClick={onRemoveParticipantsMachines}
                icon={<DeleteIcon style={{ color: Colors.green }} />}
                text={t('coursePanel.deleteAllComputers')}
              />
            ) : (
              ''
            )}

            <ActionPosition
              onClick={handleShowLink}
              icon={<StyledInsertLinkIcon style={{ color: Colors.green }} />}
              text={t('coursePanel.showJoinLink')}
            />
          </Options>
        </OptionsModal>
      )}

      {showJoinLink && (
        <HeadDialog
          onClose={toggleShowJoinLink}
          open={showJoinLink}
          content={
            <ShowLinkContent>
              <Typography variant={Typo.regular}>{t('coursePanel.showLinkFirstRow')}</Typography>
              <BigTypography>{joinLink}</BigTypography>
            </ShowLinkContent>
          }
          buttons={<Button onClick={handleCopyJoinLink}>{t('coursePanel.copyJoinLink')}</Button>}
        />
      )}
    </MainContainer>
  );
}

import styled from 'styled-components';
import { Colors } from 'Themes/colors';

export const Chip = styled.span<{ color: string }>`
  background: ${props => props.color};
  padding: 8px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.white};
`;

import { Card, Grid, Skeleton } from '@mui/material';
import styled from 'styled-components';
import { ApplicationLogo } from 'AppLayout/ApplicationLogo';

namespace CardConsts {
  export const verticalPadding = '36px';
  export const horizontalPadding = '48px';
}

const defaultGridStyles = `
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledGrid = styled(Grid)`
  max-width: 968px;
  ${defaultGridStyles}
  margin: 10% auto;
`;

export const StyledCard = styled(Card)`
  && {
    position: relative;
    padding: ${CardConsts.verticalPadding} ${CardConsts.horizontalPadding};
  }
`;

export const StyledApplicationLogo = styled(ApplicationLogo)`
  position: absolute;
  width: 164px;
  right: ${CardConsts.horizontalPadding};
`;

export const StyledJoinCourseSkeleton = styled(Skeleton)`
  height: 350px !important;
  ${defaultGridStyles}
`;

import React, { memo, useMemo } from 'react';
import { toDateFormat } from 'Utils/dateFormat';

interface DateInfoProps {
  date: Date;
}

const DateInfo = ({ date }: DateInfoProps) => {
  const dateToDisplay = useMemo(() => toDateFormat(date), [date]);

  return date && <span>{dateToDisplay}</span>;
};

export default memo(DateInfo);

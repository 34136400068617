import { useState, useMemo } from 'react';
import {
  ListMode,
  ParticipantListComponent,
  ParticipantListConsts
} from './ParticipantList.consts';

type UseListMode = () => [ListMode, ParticipantListComponent, (listMode: ListMode) => void];

export const useListMode: UseListMode = () => {
  const [listMode, setListMode] = useState<ListMode>(ParticipantListConsts.defaultListMode);

  const ListComponent = useMemo(() => {
    return ParticipantListConsts.listComponentMap[listMode];
  }, [listMode]);

  return [listMode, ListComponent, setListMode];
};

import styled from 'styled-components';
import { Spacing } from 'Themes/spacing';

export const StyledLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${Spacing.small};
`;

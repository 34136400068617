/* tslint:disable */
/* eslint-disable */
/**
 * Treneo.Bootstrapper
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface ChangePasswordRequest
 */
export interface ChangePasswordRequest {
    /**
     *
     * @type {string}
     * @memberof ChangePasswordRequest
     */
    'oldPassword'?: string;
    /**
     *
     * @type {string}
     * @memberof ChangePasswordRequest
     */
    'password'?: string;
}
/**
 *
 * @export
 * @interface ConfirmEmailAndActivateAccountRequest
 */
export interface ConfirmEmailAndActivateAccountRequest {
    /**
     *
     * @type {string}
     * @memberof ConfirmEmailAndActivateAccountRequest
     */
    'code'?: string;
}
/**
 *
 * @export
 * @interface ConnectToFreshStartDesktopRequest
 */
export interface ConnectToFreshStartDesktopRequest {
    /**
     *
     * @type {string}
     * @memberof ConnectToFreshStartDesktopRequest
     */
    'courseId'?: string;
}
/**
 *
 * @export
 * @interface ConnectToMyDesktopRequest
 */
export interface ConnectToMyDesktopRequest {
    /**
     *
     * @type {string}
     * @memberof ConnectToMyDesktopRequest
     */
    'courseId'?: string;
}
/**
 *
 * @export
 * @interface ConnectToParticipantDesktopRequest
 */
export interface ConnectToParticipantDesktopRequest {
    /**
     *
     * @type {string}
     * @memberof ConnectToParticipantDesktopRequest
     */
    'courseId'?: string;
    /**
     *
     * @type {string}
     * @memberof ConnectToParticipantDesktopRequest
     */
    'participantId'?: string;
}
/**
 *
 * @export
 * @interface ConnectToTrainerDesktopRequest
 */
export interface ConnectToTrainerDesktopRequest {
    /**
     *
     * @type {string}
     * @memberof ConnectToTrainerDesktopRequest
     */
    'courseId'?: string;
}
/**
 *
 * @export
 * @interface CourseDates
 */
export interface CourseDates {
    /**
     *
     * @type {string}
     * @memberof CourseDates
     */
    'startDateTime'?: string | null;
    /**
     *
     * @type {string}
     * @memberof CourseDates
     */
    'endDateTime'?: string | null;
}
/**
 *
 * @export
 * @interface CourseModelBase
 */
export interface CourseModelBase {
    /**
     *
     * @type {string}
     * @memberof CourseModelBase
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof CourseModelBase
     */
    'title'?: string;
    /**
     *
     * @type {boolean}
     * @memberof CourseModelBase
     */
    'isFreshStartMachineReadyToClone'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof CourseModelBase
     */
    'whetherTrainerAllowedPreview'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof CourseModelBase
     */
    'hasFreshStart'?: boolean | null;
}
/**
 *
 * @export
 * @interface CourseMyDesktop
 */
export interface CourseMyDesktop {
    /**
     *
     * @type {string}
     * @memberof CourseMyDesktop
     */
    'courseId'?: string;
    /**
     *
     * @type {GetCourseDesktopsResponseMyDesktop}
     * @memberof CourseMyDesktop
     */
    'myDesktop'?: GetCourseDesktopsResponseMyDesktop | null;
}
/**
 *
 * @export
 * @interface CourseResult
 */
export interface CourseResult {
    /**
     *
     * @type {string}
     * @memberof CourseResult
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof CourseResult
     */
    'title'?: string;
    /**
     *
     * @type {boolean}
     * @memberof CourseResult
     */
    'isFreshStartMachineReadyToClone'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof CourseResult
     */
    'whetherTrainerAllowedPreview'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof CourseResult
     */
    'hasFreshStart'?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof CourseResult
     */
    'description'?: string | null;
    /**
     *
     * @type {Array<CourseDates>}
     * @memberof CourseResult
     */
    'dates'?: Array<CourseDates>;
    /**
     *
     * @type {string}
     * @memberof CourseResult
     */
    'meetingLink'?: string | null;
    /**
     *
     * @type {string}
     * @memberof CourseResult
     */
    'meetingPlatform'?: string | null;
    /**
     *
     * @type {UserBaseModel}
     * @memberof CourseResult
     */
    'trainer'?: UserBaseModel;
    /**
     *
     * @type {boolean}
     * @memberof CourseResult
     */
    'isTrainer'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof CourseResult
     */
    'isDemoCourse'?: boolean;
}
/**
 *
 * @export
 * @interface CourseResultAllOf
 */
export interface CourseResultAllOf {
    /**
     *
     * @type {string}
     * @memberof CourseResultAllOf
     */
    'description'?: string | null;
    /**
     *
     * @type {Array<CourseDates>}
     * @memberof CourseResultAllOf
     */
    'dates'?: Array<CourseDates>;
    /**
     *
     * @type {string}
     * @memberof CourseResultAllOf
     */
    'meetingLink'?: string | null;
    /**
     *
     * @type {string}
     * @memberof CourseResultAllOf
     */
    'meetingPlatform'?: string | null;
    /**
     *
     * @type {UserBaseModel}
     * @memberof CourseResultAllOf
     */
    'trainer'?: UserBaseModel;
    /**
     *
     * @type {boolean}
     * @memberof CourseResultAllOf
     */
    'isTrainer'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof CourseResultAllOf
     */
    'isDemoCourse'?: boolean;
}
/**
 *
 * @export
 * @interface CourseTrainerDesktop
 */
export interface CourseTrainerDesktop {
    /**
     *
     * @type {string}
     * @memberof CourseTrainerDesktop
     */
    'courseId'?: string;
    /**
     *
     * @type {GetCourseDesktopsResponseMyDesktop}
     * @memberof CourseTrainerDesktop
     */
    'trainerDesktop'?: GetCourseDesktopsResponseMyDesktop | null;
}
/**
 *
 * @export
 * @interface CreateFreshStartFromOsTemplateRequest
 */
export interface CreateFreshStartFromOsTemplateRequest {
    /**
     *
     * @type {string}
     * @memberof CreateFreshStartFromOsTemplateRequest
     */
    'courseId'?: string;
    /**
     *
     * @type {string}
     * @memberof CreateFreshStartFromOsTemplateRequest
     */
    'serverName'?: string;
    /**
     *
     * @type {string}
     * @memberof CreateFreshStartFromOsTemplateRequest
     */
    'template'?: string;
    /**
     *
     * @type {number}
     * @memberof CreateFreshStartFromOsTemplateRequest
     */
    'cpu'?: number | null;
    /**
     *
     * @type {number}
     * @memberof CreateFreshStartFromOsTemplateRequest
     */
    'memory'?: number | null;
    /**
     *
     * @type {number}
     * @memberof CreateFreshStartFromOsTemplateRequest
     */
    'diskSize'?: number | null;
}
/**
 *
 * @export
 * @interface CreateFreshStartFromStandaloneTemplateRequest
 */
export interface CreateFreshStartFromStandaloneTemplateRequest {
    /**
     *
     * @type {string}
     * @memberof CreateFreshStartFromStandaloneTemplateRequest
     */
    'courseId'?: string;
    /**
     *
     * @type {number}
     * @memberof CreateFreshStartFromStandaloneTemplateRequest
     */
    'standaloneId'?: number;
}
/**
 *
 * @export
 * @interface CurrentUserInfoResult
 */
export interface CurrentUserInfoResult {
    /**
     *
     * @type {string}
     * @memberof CurrentUserInfoResult
     */
    'displayName'?: string | null;
    /**
     *
     * @type {string}
     * @memberof CurrentUserInfoResult
     */
    'avatar'?: string | null;
    /**
     *
     * @type {string}
     * @memberof CurrentUserInfoResult
     */
    'email'?: string;
    /**
     *
     * @type {string}
     * @memberof CurrentUserInfoResult
     */
    'firstName'?: string | null;
    /**
     *
     * @type {string}
     * @memberof CurrentUserInfoResult
     */
    'lastName'?: string | null;
    /**
     *
     * @type {string}
     * @memberof CurrentUserInfoResult
     */
    'avatarColor'?: string;
    /**
     *
     * @type {string}
     * @memberof CurrentUserInfoResult
     */
    'languageCode'?: string;
    /**
     *
     * @type {string}
     * @memberof CurrentUserInfoResult
     */
    'theme'?: string;
    /**
     *
     * @type {boolean}
     * @memberof CurrentUserInfoResult
     */
    'isStartBannerHidden'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof CurrentUserInfoResult
     */
    'isMachineBarPinned'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof CurrentUserInfoResult
     */
    'areTipsHidden'?: boolean;
}
/**
 *
 * @export
 * @interface CurrentUserInfoResultAllOf
 */
export interface CurrentUserInfoResultAllOf {
    /**
     *
     * @type {string}
     * @memberof CurrentUserInfoResultAllOf
     */
    'email'?: string;
    /**
     *
     * @type {string}
     * @memberof CurrentUserInfoResultAllOf
     */
    'firstName'?: string | null;
    /**
     *
     * @type {string}
     * @memberof CurrentUserInfoResultAllOf
     */
    'lastName'?: string | null;
    /**
     *
     * @type {string}
     * @memberof CurrentUserInfoResultAllOf
     */
    'avatarColor'?: string;
    /**
     *
     * @type {string}
     * @memberof CurrentUserInfoResultAllOf
     */
    'languageCode'?: string;
    /**
     *
     * @type {string}
     * @memberof CurrentUserInfoResultAllOf
     */
    'theme'?: string;
    /**
     *
     * @type {boolean}
     * @memberof CurrentUserInfoResultAllOf
     */
    'isStartBannerHidden'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof CurrentUserInfoResultAllOf
     */
    'isMachineBarPinned'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof CurrentUserInfoResultAllOf
     */
    'areTipsHidden'?: boolean;
}
/**
 *
 * @export
 * @interface DeleteDesktopRequest
 */
export interface DeleteDesktopRequest {
    /**
     *
     * @type {string}
     * @memberof DeleteDesktopRequest
     */
    'courseId'?: string;
    /**
     *
     * @type {number}
     * @memberof DeleteDesktopRequest
     */
    'desktopInstanceId'?: number;
}
/**
 *
 * @export
 * @interface DeleteFreshStartDesktopRequest
 */
export interface DeleteFreshStartDesktopRequest {
    /**
     *
     * @type {string}
     * @memberof DeleteFreshStartDesktopRequest
     */
    'courseId'?: string;
}
/**
 *
 * @export
 * @interface DesktopModel
 */
export interface DesktopModel {
    /**
     *
     * @type {number}
     * @memberof DesktopModel
     */
    'instanceId'?: number | null;
    /**
     *
     * @type {string}
     * @memberof DesktopModel
     */
    'instanceName'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DesktopModel
     */
    'link'?: string | null;
    /**
     *
     * @type {string}
     * @memberof DesktopModel
     */
    'viewLink'?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof DesktopModel
     */
    'isRunning'?: boolean;
}
/**
 *
 * @export
 * @interface ForgotPasswordRequest
 */
export interface ForgotPasswordRequest {
    /**
     *
     * @type {string}
     * @memberof ForgotPasswordRequest
     */
    'email'?: string;
    /**
     *
     * @type {string}
     * @memberof ForgotPasswordRequest
     */
    'origin'?: string;
}
/**
 *
 * @export
 * @interface FormsDefaultValuesSettings
 */
export interface FormsDefaultValuesSettings {
    /**
     *
     * @type {FormsDefaultValuesSettingsFreshStartForm}
     * @memberof FormsDefaultValuesSettings
     */
    'freshStartForm'?: FormsDefaultValuesSettingsFreshStartForm | null;
}
/**
 * @type FormsDefaultValuesSettingsFreshStartForm
 * @export
 */
export type FormsDefaultValuesSettingsFreshStartForm = FreshStartFormSettings;

/**
 *
 * @export
 * @interface FreshStartFormSettings
 */
export interface FreshStartFormSettings {
    /**
     *
     * @type {string}
     * @memberof FreshStartFormSettings
     */
    'serverName'?: string | null;
    /**
     *
     * @type {string}
     * @memberof FreshStartFormSettings
     */
    'memory'?: string | null;
    /**
     *
     * @type {string}
     * @memberof FreshStartFormSettings
     */
    'cpu'?: string | null;
    /**
     *
     * @type {string}
     * @memberof FreshStartFormSettings
     */
    'diskSize'?: string | null;
}
/**
 *
 * @export
 * @interface GetConfigResult
 */
export interface GetConfigResult {
    /**
     *
     * @type {SentrySettings}
     * @memberof GetConfigResult
     */
    'sentrySettings'?: SentrySettings;
    /**
     *
     * @type {FormsDefaultValuesSettings}
     * @memberof GetConfigResult
     */
    'formsDefaultValuesSettings'?: FormsDefaultValuesSettings;
}
/**
 *
 * @export
 * @interface GetCourseAdditionalDataResult
 */
export interface GetCourseAdditionalDataResult {
    /**
     *
     * @type {number}
     * @memberof GetCourseAdditionalDataResult
     */
    'machineIframeSize'?: number;
    /**
     *
     * @type {string}
     * @memberof GetCourseAdditionalDataResult
     */
    'joinCode'?: string | null;
}
/**
 *
 * @export
 * @interface GetCourseDesktopsResponse
 */
export interface GetCourseDesktopsResponse {
    /**
     *
     * @type {GetCourseDesktopsResponseMyDesktop}
     * @memberof GetCourseDesktopsResponse
     */
    'myDesktop'?: GetCourseDesktopsResponseMyDesktop | null;
    /**
     *
     * @type {GetCourseDesktopsResponseMyDesktop}
     * @memberof GetCourseDesktopsResponse
     */
    'trainerDesktop'?: GetCourseDesktopsResponseMyDesktop | null;
}
/**
 * @type GetCourseDesktopsResponseMyDesktop
 * @export
 */
export type GetCourseDesktopsResponseMyDesktop = DesktopModel;

/**
 *
 * @export
 * @interface GetCourseResult
 */
export interface GetCourseResult {
    /**
     *
     * @type {string}
     * @memberof GetCourseResult
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof GetCourseResult
     */
    'title'?: string;
    /**
     *
     * @type {boolean}
     * @memberof GetCourseResult
     */
    'isFreshStartMachineReadyToClone'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof GetCourseResult
     */
    'whetherTrainerAllowedPreview'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof GetCourseResult
     */
    'hasFreshStart'?: boolean | null;
    /**
     *
     * @type {number}
     * @memberof GetCourseResult
     */
    'machineIframeSize'?: number;
    /**
     *
     * @type {string}
     * @memberof GetCourseResult
     */
    'joinCode'?: string | null;
}
/**
 *
 * @export
 * @interface GetCourseResultAllOf
 */
export interface GetCourseResultAllOf {
    /**
     *
     * @type {number}
     * @memberof GetCourseResultAllOf
     */
    'machineIframeSize'?: number;
    /**
     *
     * @type {string}
     * @memberof GetCourseResultAllOf
     */
    'joinCode'?: string | null;
}
/**
 *
 * @export
 * @interface GetCourseStatusResult
 */
export interface GetCourseStatusResult {
    /**
     *
     * @type {string}
     * @memberof GetCourseStatusResult
     */
    'title'?: string | null;
    /**
     *
     * @type {string}
     * @memberof GetCourseStatusResult
     */
    'startDate'?: string | null;
    /**
     *
     * @type {string}
     * @memberof GetCourseStatusResult
     */
    'errorTranslationCode'?: string | null;
    /**
     *
     * @type {string}
     * @memberof GetCourseStatusResult
     */
    'courseId'?: string;
}
/**
 *
 * @export
 * @interface GetCoursesResult
 */
export interface GetCoursesResult {
    /**
     *
     * @type {Array<CourseResult>}
     * @memberof GetCoursesResult
     */
    'courses'?: Array<CourseResult>;
}
/**
 *
 * @export
 * @interface GetFreshStartServersForCourseResult
 */
export interface GetFreshStartServersForCourseResult {
    /**
     *
     * @type {Array<string>}
     * @memberof GetFreshStartServersForCourseResult
     */
    'serverNames'?: Array<string>;
}
/**
 *
 * @export
 * @interface GetMyDesktopsForCoursesResult
 */
export interface GetMyDesktopsForCoursesResult {
    /**
     *
     * @type {Array<CourseMyDesktop>}
     * @memberof GetMyDesktopsForCoursesResult
     */
    'myDesktops'?: Array<CourseMyDesktop>;
}
/**
 *
 * @export
 * @interface GetOsTemplatesOfServerResult
 */
export interface GetOsTemplatesOfServerResult {
    /**
     *
     * @type {Array<string>}
     * @memberof GetOsTemplatesOfServerResult
     */
    'osTemplateNames'?: Array<string>;
}
/**
 *
 * @export
 * @interface GetStandalonesResult
 */
export interface GetStandalonesResult {
    /**
     *
     * @type {Array<Standalone>}
     * @memberof GetStandalonesResult
     */
    'standalones'?: Array<Standalone>;
}
/**
 *
 * @export
 * @interface GetTrainerDesktopsForCoursesResult
 */
export interface GetTrainerDesktopsForCoursesResult {
    /**
     *
     * @type {Array<CourseTrainerDesktop>}
     * @memberof GetTrainerDesktopsForCoursesResult
     */
    'trainerDesktops'?: Array<CourseTrainerDesktop>;
}
/**
 *
 * @export
 * @interface JoinToCourseRequest
 */
export interface JoinToCourseRequest {
    /**
     *
     * @type {string}
     * @memberof JoinToCourseRequest
     */
    'joinCode'?: string;
}
/**
 *
 * @export
 * @interface LogInAndJoinToCourseRequest
 */
export interface LogInAndJoinToCourseRequest {
    /**
     *
     * @type {string}
     * @memberof LogInAndJoinToCourseRequest
     */
    'email'?: string;
    /**
     *
     * @type {string}
     * @memberof LogInAndJoinToCourseRequest
     */
    'password'?: string;
    /**
     *
     * @type {boolean}
     * @memberof LogInAndJoinToCourseRequest
     */
    'rememberMe'?: boolean;
    /**
     *
     * @type {string}
     * @memberof LogInAndJoinToCourseRequest
     */
    'joinCode'?: string;
}
/**
 *
 * @export
 * @interface LogInAndJoinToCourseRequestAllOf
 */
export interface LogInAndJoinToCourseRequestAllOf {
    /**
     *
     * @type {string}
     * @memberof LogInAndJoinToCourseRequestAllOf
     */
    'joinCode'?: string;
}
/**
 *
 * @export
 * @interface LogInOrSignUpViaGoogleRequest
 */
export interface LogInOrSignUpViaGoogleRequest {
    /**
     *
     * @type {string}
     * @memberof LogInOrSignUpViaGoogleRequest
     */
    'email'?: string;
    /**
     *
     * @type {string}
     * @memberof LogInOrSignUpViaGoogleRequest
     */
    'firstName'?: string;
    /**
     *
     * @type {string}
     * @memberof LogInOrSignUpViaGoogleRequest
     */
    'lastName'?: string | null;
}
/**
 *
 * @export
 * @interface LogInOrSignUpViaLinkedInRequest
 */
export interface LogInOrSignUpViaLinkedInRequest {
    /**
     *
     * @type {string}
     * @memberof LogInOrSignUpViaLinkedInRequest
     */
    'email'?: string;
    /**
     *
     * @type {string}
     * @memberof LogInOrSignUpViaLinkedInRequest
     */
    'firstName'?: string;
    /**
     *
     * @type {string}
     * @memberof LogInOrSignUpViaLinkedInRequest
     */
    'lastName'?: string | null;
}
/**
 *
 * @export
 * @interface LogInRequest
 */
export interface LogInRequest {
    /**
     *
     * @type {string}
     * @memberof LogInRequest
     */
    'email'?: string;
    /**
     *
     * @type {string}
     * @memberof LogInRequest
     */
    'password'?: string;
    /**
     *
     * @type {boolean}
     * @memberof LogInRequest
     */
    'rememberMe'?: boolean;
}
/**
 *
 * @export
 * @interface ParticipantModel
 */
export interface ParticipantModel {
    /**
     *
     * @type {string}
     * @memberof ParticipantModel
     */
    'displayName'?: string | null;
    /**
     *
     * @type {string}
     * @memberof ParticipantModel
     */
    'avatar'?: string | null;
    /**
     *
     * @type {string}
     * @memberof ParticipantModel
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof ParticipantModel
     */
    'avatarColor'?: string;
    /**
     *
     * @type {DesktopModel}
     * @memberof ParticipantModel
     */
    'desktop'?: DesktopModel | null;
}
/**
 *
 * @export
 * @interface ParticipantModelAllOf
 */
export interface ParticipantModelAllOf {
    /**
     *
     * @type {string}
     * @memberof ParticipantModelAllOf
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof ParticipantModelAllOf
     */
    'avatarColor'?: string;
    /**
     *
     * @type {DesktopModel}
     * @memberof ParticipantModelAllOf
     */
    'desktop'?: DesktopModel | null;
}
/**
 *
 * @export
 * @interface RegisterAndJoinToCourseRequest
 */
export interface RegisterAndJoinToCourseRequest {
    /**
     *
     * @type {string}
     * @memberof RegisterAndJoinToCourseRequest
     */
    'firstName'?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegisterAndJoinToCourseRequest
     */
    'lastName'?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegisterAndJoinToCourseRequest
     */
    'email'?: string;
    /**
     *
     * @type {string}
     * @memberof RegisterAndJoinToCourseRequest
     */
    'password'?: string;
    /**
     *
     * @type {string}
     * @memberof RegisterAndJoinToCourseRequest
     */
    'confirmPassword'?: string;
    /**
     *
     * @type {string}
     * @memberof RegisterAndJoinToCourseRequest
     */
    'joinCode'?: string;
}
/**
 *
 * @export
 * @interface RegisterRequest
 */
export interface RegisterRequest {
    /**
     *
     * @type {string}
     * @memberof RegisterRequest
     */
    'firstName'?: string;
    /**
     *
     * @type {string}
     * @memberof RegisterRequest
     */
    'lastName'?: string | null;
    /**
     *
     * @type {string}
     * @memberof RegisterRequest
     */
    'email'?: string;
    /**
     *
     * @type {string}
     * @memberof RegisterRequest
     */
    'password'?: string;
    /**
     *
     * @type {string}
     * @memberof RegisterRequest
     */
    'confirmPassword'?: string;
    /**
     *
     * @type {boolean}
     * @memberof RegisterRequest
     */
    'acceptTermsAndPrivacyPolicy'?: boolean;
    /**
     *
     * @type {string}
     * @memberof RegisterRequest
     */
    'origin'?: string;
}
/**
 *
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
    /**
     *
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    'hash'?: string;
    /**
     *
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    'password'?: string;
    /**
     *
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    'confirmPassword'?: string;
}
/**
 *
 * @export
 * @interface SaveAreTipsHiddenRequest
 */
export interface SaveAreTipsHiddenRequest {
    /**
     *
     * @type {boolean}
     * @memberof SaveAreTipsHiddenRequest
     */
    'areTipsHidden'?: boolean;
}
/**
 *
 * @export
 * @interface SaveCurrentUserInfoRequest
 */
export interface SaveCurrentUserInfoRequest {
    /**
     *
     * @type {string}
     * @memberof SaveCurrentUserInfoRequest
     */
    'displayName'?: string;
    /**
     *
     * @type {string}
     * @memberof SaveCurrentUserInfoRequest
     */
    'firstName'?: string;
    /**
     *
     * @type {string}
     * @memberof SaveCurrentUserInfoRequest
     */
    'lastName'?: string;
    /**
     *
     * @type {string}
     * @memberof SaveCurrentUserInfoRequest
     */
    'avatarColor'?: string;
}
/**
 *
 * @export
 * @interface SaveIsFreshStartMachineReadyToCloneRequest
 */
export interface SaveIsFreshStartMachineReadyToCloneRequest {
    /**
     *
     * @type {string}
     * @memberof SaveIsFreshStartMachineReadyToCloneRequest
     */
    'courseId'?: string;
    /**
     *
     * @type {boolean}
     * @memberof SaveIsFreshStartMachineReadyToCloneRequest
     */
    'isFreshStartMachineReadyToClone'?: boolean;
}
/**
 *
 * @export
 * @interface SaveIsMachineBarPinnedRequest
 */
export interface SaveIsMachineBarPinnedRequest {
    /**
     *
     * @type {boolean}
     * @memberof SaveIsMachineBarPinnedRequest
     */
    'isMachineBarPinned'?: boolean;
}
/**
 *
 * @export
 * @interface SaveIsStartBannerHiddenRequest
 */
export interface SaveIsStartBannerHiddenRequest {
    /**
     *
     * @type {boolean}
     * @memberof SaveIsStartBannerHiddenRequest
     */
    'isStartBannerHidden'?: boolean;
}
/**
 *
 * @export
 * @interface SaveLanguageCodeRequest
 */
export interface SaveLanguageCodeRequest {
    /**
     *
     * @type {string}
     * @memberof SaveLanguageCodeRequest
     */
    'languageCode'?: string;
}
/**
 *
 * @export
 * @interface SaveMachineIframeSizeRequest
 */
export interface SaveMachineIframeSizeRequest {
    /**
     *
     * @type {string}
     * @memberof SaveMachineIframeSizeRequest
     */
    'courseId'?: string;
    /**
     *
     * @type {number}
     * @memberof SaveMachineIframeSizeRequest
     */
    'machineIframeSize'?: number;
}
/**
 *
 * @export
 * @interface SaveThemeRequest
 */
export interface SaveThemeRequest {
    /**
     *
     * @type {string}
     * @memberof SaveThemeRequest
     */
    'theme'?: string;
}
/**
 *
 * @export
 * @interface SaveWhetherTrainerAllowedPreviewRequest
 */
export interface SaveWhetherTrainerAllowedPreviewRequest {
    /**
     *
     * @type {string}
     * @memberof SaveWhetherTrainerAllowedPreviewRequest
     */
    'courseId'?: string;
    /**
     *
     * @type {boolean}
     * @memberof SaveWhetherTrainerAllowedPreviewRequest
     */
    'whetherTrainerAllowedPreview'?: boolean;
}
/**
 *
 * @export
 * @interface SentrySettings
 */
export interface SentrySettings {
    /**
     *
     * @type {string}
     * @memberof SentrySettings
     */
    'dsn'?: string | null;
    /**
     *
     * @type {string}
     * @memberof SentrySettings
     */
    'sentryEnvironment'?: string | null;
}
/**
 *
 * @export
 * @interface Standalone
 */
export interface Standalone {
    /**
     *
     * @type {number}
     * @memberof Standalone
     */
    'id'?: number;
    /**
     *
     * @type {string}
     * @memberof Standalone
     */
    'name'?: string;
    /**
     *
     * @type {string}
     * @memberof Standalone
     */
    'serverName'?: string;
    /**
     *
     * @type {string}
     * @memberof Standalone
     */
    'template'?: string;
    /**
     *
     * @type {number}
     * @memberof Standalone
     */
    'cpu'?: number;
    /**
     *
     * @type {number}
     * @memberof Standalone
     */
    'memory'?: number;
    /**
     *
     * @type {number}
     * @memberof Standalone
     */
    'diskSize'?: number;
}
/**
 *
 * @export
 * @interface StartParticipantDesktopRequest
 */
export interface StartParticipantDesktopRequest {
    /**
     *
     * @type {string}
     * @memberof StartParticipantDesktopRequest
     */
    'courseId'?: string;
    /**
     *
     * @type {string}
     * @memberof StartParticipantDesktopRequest
     */
    'participantId'?: string;
}
/**
 *
 * @export
 * @interface StopDesktopRequest
 */
export interface StopDesktopRequest {
    /**
     *
     * @type {string}
     * @memberof StopDesktopRequest
     */
    'courseId'?: string;
    /**
     *
     * @type {number}
     * @memberof StopDesktopRequest
     */
    'desktopInstanceId'?: number;
}
/**
 *
 * @export
 * @interface UserBaseModel
 */
export interface UserBaseModel {
    /**
     *
     * @type {string}
     * @memberof UserBaseModel
     */
    'displayName'?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserBaseModel
     */
    'avatar'?: string | null;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {ChangePasswordRequest} changePasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authChangePasswordPost: async (changePasswordRequest: ChangePasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changePasswordRequest' is not null or undefined
            assertParamExists('authChangePasswordPost', 'changePasswordRequest', changePasswordRequest)
            const localVarPath = `/api/auth/changePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {ConfirmEmailAndActivateAccountRequest} confirmEmailAndActivateAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authConfirmEmailAndActivateAccountPost: async (confirmEmailAndActivateAccountRequest: ConfirmEmailAndActivateAccountRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'confirmEmailAndActivateAccountRequest' is not null or undefined
            assertParamExists('authConfirmEmailAndActivateAccountPost', 'confirmEmailAndActivateAccountRequest', confirmEmailAndActivateAccountRequest)
            const localVarPath = `/api/auth/confirmEmailAndActivateAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmEmailAndActivateAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authCookieGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/cookie`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {ForgotPasswordRequest} forgotPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authForgotPasswordPost: async (forgotPasswordRequest: ForgotPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotPasswordRequest' is not null or undefined
            assertParamExists('authForgotPasswordPost', 'forgotPasswordRequest', forgotPasswordRequest)
            const localVarPath = `/api/auth/forgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {LogInAndJoinToCourseRequest} logInAndJoinToCourseRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogInAndJoinToCoursePost: async (logInAndJoinToCourseRequest: LogInAndJoinToCourseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logInAndJoinToCourseRequest' is not null or undefined
            assertParamExists('authLogInAndJoinToCoursePost', 'logInAndJoinToCourseRequest', logInAndJoinToCourseRequest)
            const localVarPath = `/api/auth/logInAndJoinToCourse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logInAndJoinToCourseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {LogInOrSignUpViaGoogleRequest} logInOrSignUpViaGoogleRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogInOrSignUpViaGooglePost: async (logInOrSignUpViaGoogleRequest: LogInOrSignUpViaGoogleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logInOrSignUpViaGoogleRequest' is not null or undefined
            assertParamExists('authLogInOrSignUpViaGooglePost', 'logInOrSignUpViaGoogleRequest', logInOrSignUpViaGoogleRequest)
            const localVarPath = `/api/auth/logInOrSignUpViaGoogle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logInOrSignUpViaGoogleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {LogInOrSignUpViaLinkedInRequest} logInOrSignUpViaLinkedInRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogInOrSignUpViaLinkedInPost: async (logInOrSignUpViaLinkedInRequest: LogInOrSignUpViaLinkedInRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logInOrSignUpViaLinkedInRequest' is not null or undefined
            assertParamExists('authLogInOrSignUpViaLinkedInPost', 'logInOrSignUpViaLinkedInRequest', logInOrSignUpViaLinkedInRequest)
            const localVarPath = `/api/auth/logInOrSignUpViaLinkedIn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logInOrSignUpViaLinkedInRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {LogInRequest} logInRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogInPost: async (logInRequest: LogInRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logInRequest' is not null or undefined
            assertParamExists('authLogInPost', 'logInRequest', logInRequest)
            const localVarPath = `/api/auth/logIn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logInRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogOutPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/logOut`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {RegisterAndJoinToCourseRequest} registerAndJoinToCourseRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRegisterAndJoinToCoursePost: async (registerAndJoinToCourseRequest: RegisterAndJoinToCourseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerAndJoinToCourseRequest' is not null or undefined
            assertParamExists('authRegisterAndJoinToCoursePost', 'registerAndJoinToCourseRequest', registerAndJoinToCourseRequest)
            const localVarPath = `/api/auth/registerAndJoinToCourse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerAndJoinToCourseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {RegisterRequest} registerRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRegisterPost: async (registerRequest: RegisterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerRequest' is not null or undefined
            assertParamExists('authRegisterPost', 'registerRequest', registerRequest)
            const localVarPath = `/api/auth/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRemoveAccountPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/removeAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {ResetPasswordRequest} resetPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authResetPasswordPost: async (resetPasswordRequest: ResetPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordRequest' is not null or undefined
            assertParamExists('authResetPasswordPost', 'resetPasswordRequest', resetPasswordRequest)
            const localVarPath = `/api/auth/resetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {ChangePasswordRequest} changePasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authChangePasswordPost(changePasswordRequest: ChangePasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authChangePasswordPost(changePasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {ConfirmEmailAndActivateAccountRequest} confirmEmailAndActivateAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authConfirmEmailAndActivateAccountPost(confirmEmailAndActivateAccountRequest: ConfirmEmailAndActivateAccountRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authConfirmEmailAndActivateAccountPost(confirmEmailAndActivateAccountRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authCookieGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authCookieGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {ForgotPasswordRequest} forgotPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authForgotPasswordPost(forgotPasswordRequest: ForgotPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authForgotPasswordPost(forgotPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {LogInAndJoinToCourseRequest} logInAndJoinToCourseRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authLogInAndJoinToCoursePost(logInAndJoinToCourseRequest: LogInAndJoinToCourseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCourseStatusResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authLogInAndJoinToCoursePost(logInAndJoinToCourseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {LogInOrSignUpViaGoogleRequest} logInOrSignUpViaGoogleRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authLogInOrSignUpViaGooglePost(logInOrSignUpViaGoogleRequest: LogInOrSignUpViaGoogleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authLogInOrSignUpViaGooglePost(logInOrSignUpViaGoogleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {LogInOrSignUpViaLinkedInRequest} logInOrSignUpViaLinkedInRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authLogInOrSignUpViaLinkedInPost(logInOrSignUpViaLinkedInRequest: LogInOrSignUpViaLinkedInRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authLogInOrSignUpViaLinkedInPost(logInOrSignUpViaLinkedInRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {LogInRequest} logInRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authLogInPost(logInRequest: LogInRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authLogInPost(logInRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authLogOutPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authLogOutPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {RegisterAndJoinToCourseRequest} registerAndJoinToCourseRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authRegisterAndJoinToCoursePost(registerAndJoinToCourseRequest: RegisterAndJoinToCourseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCourseStatusResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authRegisterAndJoinToCoursePost(registerAndJoinToCourseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {RegisterRequest} registerRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authRegisterPost(registerRequest: RegisterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authRegisterPost(registerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authRemoveAccountPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authRemoveAccountPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {ResetPasswordRequest} resetPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authResetPasswordPost(resetPasswordRequest: ResetPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authResetPasswordPost(resetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         *
         * @param {ChangePasswordRequest} changePasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authChangePasswordPost(changePasswordRequest: ChangePasswordRequest, options?: any): AxiosPromise<any> {
            return localVarFp.authChangePasswordPost(changePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ConfirmEmailAndActivateAccountRequest} confirmEmailAndActivateAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authConfirmEmailAndActivateAccountPost(confirmEmailAndActivateAccountRequest: ConfirmEmailAndActivateAccountRequest, options?: any): AxiosPromise<any> {
            return localVarFp.authConfirmEmailAndActivateAccountPost(confirmEmailAndActivateAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authCookieGet(options?: any): AxiosPromise<any> {
            return localVarFp.authCookieGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ForgotPasswordRequest} forgotPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authForgotPasswordPost(forgotPasswordRequest: ForgotPasswordRequest, options?: any): AxiosPromise<any> {
            return localVarFp.authForgotPasswordPost(forgotPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {LogInAndJoinToCourseRequest} logInAndJoinToCourseRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogInAndJoinToCoursePost(logInAndJoinToCourseRequest: LogInAndJoinToCourseRequest, options?: any): AxiosPromise<GetCourseStatusResult> {
            return localVarFp.authLogInAndJoinToCoursePost(logInAndJoinToCourseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {LogInOrSignUpViaGoogleRequest} logInOrSignUpViaGoogleRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogInOrSignUpViaGooglePost(logInOrSignUpViaGoogleRequest: LogInOrSignUpViaGoogleRequest, options?: any): AxiosPromise<any> {
            return localVarFp.authLogInOrSignUpViaGooglePost(logInOrSignUpViaGoogleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {LogInOrSignUpViaLinkedInRequest} logInOrSignUpViaLinkedInRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogInOrSignUpViaLinkedInPost(logInOrSignUpViaLinkedInRequest: LogInOrSignUpViaLinkedInRequest, options?: any): AxiosPromise<any> {
            return localVarFp.authLogInOrSignUpViaLinkedInPost(logInOrSignUpViaLinkedInRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {LogInRequest} logInRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogInPost(logInRequest: LogInRequest, options?: any): AxiosPromise<any> {
            return localVarFp.authLogInPost(logInRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogOutPost(options?: any): AxiosPromise<any> {
            return localVarFp.authLogOutPost(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {RegisterAndJoinToCourseRequest} registerAndJoinToCourseRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRegisterAndJoinToCoursePost(registerAndJoinToCourseRequest: RegisterAndJoinToCourseRequest, options?: any): AxiosPromise<GetCourseStatusResult> {
            return localVarFp.authRegisterAndJoinToCoursePost(registerAndJoinToCourseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {RegisterRequest} registerRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRegisterPost(registerRequest: RegisterRequest, options?: any): AxiosPromise<any> {
            return localVarFp.authRegisterPost(registerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRemoveAccountPost(options?: any): AxiosPromise<any> {
            return localVarFp.authRemoveAccountPost(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ResetPasswordRequest} resetPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authResetPasswordPost(resetPasswordRequest: ResetPasswordRequest, options?: any): AxiosPromise<any> {
            return localVarFp.authResetPasswordPost(resetPasswordRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     *
     * @param {ChangePasswordRequest} changePasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authChangePasswordPost(changePasswordRequest: ChangePasswordRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authChangePasswordPost(changePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ConfirmEmailAndActivateAccountRequest} confirmEmailAndActivateAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authConfirmEmailAndActivateAccountPost(confirmEmailAndActivateAccountRequest: ConfirmEmailAndActivateAccountRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authConfirmEmailAndActivateAccountPost(confirmEmailAndActivateAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authCookieGet(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authCookieGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ForgotPasswordRequest} forgotPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authForgotPasswordPost(forgotPasswordRequest: ForgotPasswordRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authForgotPasswordPost(forgotPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {LogInAndJoinToCourseRequest} logInAndJoinToCourseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authLogInAndJoinToCoursePost(logInAndJoinToCourseRequest: LogInAndJoinToCourseRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authLogInAndJoinToCoursePost(logInAndJoinToCourseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {LogInOrSignUpViaGoogleRequest} logInOrSignUpViaGoogleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authLogInOrSignUpViaGooglePost(logInOrSignUpViaGoogleRequest: LogInOrSignUpViaGoogleRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authLogInOrSignUpViaGooglePost(logInOrSignUpViaGoogleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {LogInOrSignUpViaLinkedInRequest} logInOrSignUpViaLinkedInRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authLogInOrSignUpViaLinkedInPost(logInOrSignUpViaLinkedInRequest: LogInOrSignUpViaLinkedInRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authLogInOrSignUpViaLinkedInPost(logInOrSignUpViaLinkedInRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {LogInRequest} logInRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authLogInPost(logInRequest: LogInRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authLogInPost(logInRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authLogOutPost(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authLogOutPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {RegisterAndJoinToCourseRequest} registerAndJoinToCourseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authRegisterAndJoinToCoursePost(registerAndJoinToCourseRequest: RegisterAndJoinToCourseRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authRegisterAndJoinToCoursePost(registerAndJoinToCourseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {RegisterRequest} registerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authRegisterPost(registerRequest: RegisterRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authRegisterPost(registerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authRemoveAccountPost(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authRemoveAccountPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ResetPasswordRequest} resetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authResetPasswordPost(resetPasswordRequest: ResetPasswordRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authResetPasswordPost(resetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConfigApi - axios parameter creator
 * @export
 */
export const ConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configGetConfigGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/config/getConfig`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigApi - functional programming interface
 * @export
 */
export const ConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configGetConfigGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConfigResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configGetConfigGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConfigApi - factory interface
 * @export
 */
export const ConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigApiFp(configuration)
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configGetConfigGet(options?: any): AxiosPromise<GetConfigResult> {
            return localVarFp.configGetConfigGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigApi - object-oriented interface
 * @export
 * @class ConfigApi
 * @extends {BaseAPI}
 */
export class ConfigApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public configGetConfigGet(options?: AxiosRequestConfig) {
        return ConfigApiFp(this.configuration).configGetConfigGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CoursesApi - axios parameter creator
 * @export
 */
export const CoursesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesAdditionalDataGet: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesAdditionalDataGet', 'courseId', courseId)
            const localVarPath = `/api/courses/additionalData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['CourseId'] = courseId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {ConnectToFreshStartDesktopRequest} connectToFreshStartDesktopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesConnectToFreshStartDesktopPost: async (connectToFreshStartDesktopRequest: ConnectToFreshStartDesktopRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectToFreshStartDesktopRequest' is not null or undefined
            assertParamExists('coursesConnectToFreshStartDesktopPost', 'connectToFreshStartDesktopRequest', connectToFreshStartDesktopRequest)
            const localVarPath = `/api/courses/connectToFreshStartDesktop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectToFreshStartDesktopRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {ConnectToMyDesktopRequest} connectToMyDesktopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesConnectToMyDesktopPost: async (connectToMyDesktopRequest: ConnectToMyDesktopRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectToMyDesktopRequest' is not null or undefined
            assertParamExists('coursesConnectToMyDesktopPost', 'connectToMyDesktopRequest', connectToMyDesktopRequest)
            const localVarPath = `/api/courses/connectToMyDesktop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectToMyDesktopRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {ConnectToParticipantDesktopRequest} connectToParticipantDesktopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesConnectToParticipantDesktopPost: async (connectToParticipantDesktopRequest: ConnectToParticipantDesktopRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectToParticipantDesktopRequest' is not null or undefined
            assertParamExists('coursesConnectToParticipantDesktopPost', 'connectToParticipantDesktopRequest', connectToParticipantDesktopRequest)
            const localVarPath = `/api/courses/connectToParticipantDesktop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectToParticipantDesktopRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {ConnectToTrainerDesktopRequest} connectToTrainerDesktopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesConnectToTrainerDesktopPost: async (connectToTrainerDesktopRequest: ConnectToTrainerDesktopRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectToTrainerDesktopRequest' is not null or undefined
            assertParamExists('coursesConnectToTrainerDesktopPost', 'connectToTrainerDesktopRequest', connectToTrainerDesktopRequest)
            const localVarPath = `/api/courses/connectToTrainerDesktop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectToTrainerDesktopRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesCourseDesktopsGet: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesCourseDesktopsGet', 'courseId', courseId)
            const localVarPath = `/api/courses/courseDesktops`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['CourseId'] = courseId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {CreateFreshStartFromOsTemplateRequest} createFreshStartFromOsTemplateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesCreateFreshStartFromOsTemplatePost: async (createFreshStartFromOsTemplateRequest: CreateFreshStartFromOsTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFreshStartFromOsTemplateRequest' is not null or undefined
            assertParamExists('coursesCreateFreshStartFromOsTemplatePost', 'createFreshStartFromOsTemplateRequest', createFreshStartFromOsTemplateRequest)
            const localVarPath = `/api/courses/createFreshStartFromOsTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFreshStartFromOsTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {CreateFreshStartFromStandaloneTemplateRequest} createFreshStartFromStandaloneTemplateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesCreateFreshStartFromStandaloneTemplatePost: async (createFreshStartFromStandaloneTemplateRequest: CreateFreshStartFromStandaloneTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFreshStartFromStandaloneTemplateRequest' is not null or undefined
            assertParamExists('coursesCreateFreshStartFromStandaloneTemplatePost', 'createFreshStartFromStandaloneTemplateRequest', createFreshStartFromStandaloneTemplateRequest)
            const localVarPath = `/api/courses/createFreshStartFromStandaloneTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFreshStartFromStandaloneTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {DeleteDesktopRequest} deleteDesktopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesDeleteDesktopPost: async (deleteDesktopRequest: DeleteDesktopRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteDesktopRequest' is not null or undefined
            assertParamExists('coursesDeleteDesktopPost', 'deleteDesktopRequest', deleteDesktopRequest)
            const localVarPath = `/api/courses/deleteDesktop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteDesktopRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {DeleteFreshStartDesktopRequest} deleteFreshStartDesktopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesDeleteFreshStartDesktopPost: async (deleteFreshStartDesktopRequest: DeleteFreshStartDesktopRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteFreshStartDesktopRequest' is not null or undefined
            assertParamExists('coursesDeleteFreshStartDesktopPost', 'deleteFreshStartDesktopRequest', deleteFreshStartDesktopRequest)
            const localVarPath = `/api/courses/deleteFreshStartDesktop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteFreshStartDesktopRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesDetailsGet: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesDetailsGet', 'courseId', courseId)
            const localVarPath = `/api/courses/details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['CourseId'] = courseId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesFreshStartDesktopGet: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesFreshStartDesktopGet', 'courseId', courseId)
            const localVarPath = `/api/courses/freshStartDesktop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['CourseId'] = courseId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/courses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetFreshStartServersForCourseGet: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesGetFreshStartServersForCourseGet', 'courseId', courseId)
            const localVarPath = `/api/courses/getFreshStartServersForCourse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['CourseId'] = courseId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} courseId
         * @param {string} serverName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetOsTemplatesOfServerGet: async (courseId: string, serverName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesGetOsTemplatesOfServerGet', 'courseId', courseId)
            // verify required parameter 'serverName' is not null or undefined
            assertParamExists('coursesGetOsTemplatesOfServerGet', 'serverName', serverName)
            const localVarPath = `/api/courses/getOsTemplatesOfServer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['CourseId'] = courseId;
            }

            if (serverName !== undefined) {
                localVarQueryParameter['ServerName'] = serverName;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetStandalonesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/courses/getStandalones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} joinCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesJoinStatusGet: async (joinCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'joinCode' is not null or undefined
            assertParamExists('coursesJoinStatusGet', 'joinCode', joinCode)
            const localVarPath = `/api/courses/join/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (joinCode !== undefined) {
                localVarQueryParameter['JoinCode'] = joinCode;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {JoinToCourseRequest} joinToCourseRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesJoinToCoursePost: async (joinToCourseRequest: JoinToCourseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'joinToCourseRequest' is not null or undefined
            assertParamExists('coursesJoinToCoursePost', 'joinToCourseRequest', joinToCourseRequest)
            const localVarPath = `/api/courses/joinToCourse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(joinToCourseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesMyDesktopGet: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesMyDesktopGet', 'courseId', courseId)
            const localVarPath = `/api/courses/myDesktop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['CourseId'] = courseId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Array<string>} coursesIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesMyDesktopsGet: async (coursesIds: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'coursesIds' is not null or undefined
            assertParamExists('coursesMyDesktopsGet', 'coursesIds', coursesIds)
            const localVarPath = `/api/courses/myDesktops`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (coursesIds) {
                localVarQueryParameter['CoursesIds'] = coursesIds;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesParticipantsGet: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesParticipantsGet', 'courseId', courseId)
            const localVarPath = `/api/courses/participants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['CourseId'] = courseId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {SaveIsFreshStartMachineReadyToCloneRequest} saveIsFreshStartMachineReadyToCloneRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesSaveIsFreshStartMachineReadyToClonePost: async (saveIsFreshStartMachineReadyToCloneRequest: SaveIsFreshStartMachineReadyToCloneRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveIsFreshStartMachineReadyToCloneRequest' is not null or undefined
            assertParamExists('coursesSaveIsFreshStartMachineReadyToClonePost', 'saveIsFreshStartMachineReadyToCloneRequest', saveIsFreshStartMachineReadyToCloneRequest)
            const localVarPath = `/api/courses/saveIsFreshStartMachineReadyToClone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveIsFreshStartMachineReadyToCloneRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {SaveMachineIframeSizeRequest} saveMachineIframeSizeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesSaveMachineIframeSizePost: async (saveMachineIframeSizeRequest: SaveMachineIframeSizeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveMachineIframeSizeRequest' is not null or undefined
            assertParamExists('coursesSaveMachineIframeSizePost', 'saveMachineIframeSizeRequest', saveMachineIframeSizeRequest)
            const localVarPath = `/api/courses/saveMachineIframeSize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveMachineIframeSizeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {SaveWhetherTrainerAllowedPreviewRequest} saveWhetherTrainerAllowedPreviewRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesSaveWhetherTrainerAllowedPreviewPost: async (saveWhetherTrainerAllowedPreviewRequest: SaveWhetherTrainerAllowedPreviewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveWhetherTrainerAllowedPreviewRequest' is not null or undefined
            assertParamExists('coursesSaveWhetherTrainerAllowedPreviewPost', 'saveWhetherTrainerAllowedPreviewRequest', saveWhetherTrainerAllowedPreviewRequest)
            const localVarPath = `/api/courses/saveWhetherTrainerAllowedPreview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveWhetherTrainerAllowedPreviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {StartParticipantDesktopRequest} startParticipantDesktopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesStartParticipantDesktopPost: async (startParticipantDesktopRequest: StartParticipantDesktopRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startParticipantDesktopRequest' is not null or undefined
            assertParamExists('coursesStartParticipantDesktopPost', 'startParticipantDesktopRequest', startParticipantDesktopRequest)
            const localVarPath = `/api/courses/startParticipantDesktop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startParticipantDesktopRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {StopDesktopRequest} stopDesktopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesStopDesktopPost: async (stopDesktopRequest: StopDesktopRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stopDesktopRequest' is not null or undefined
            assertParamExists('coursesStopDesktopPost', 'stopDesktopRequest', stopDesktopRequest)
            const localVarPath = `/api/courses/stopDesktop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stopDesktopRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesTrainerDesktopGet: async (courseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('coursesTrainerDesktopGet', 'courseId', courseId)
            const localVarPath = `/api/courses/trainerDesktop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['CourseId'] = courseId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Array<string>} coursesIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesTrainerDesktopsGet: async (coursesIds: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'coursesIds' is not null or undefined
            assertParamExists('coursesTrainerDesktopsGet', 'coursesIds', coursesIds)
            const localVarPath = `/api/courses/trainerDesktops`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (coursesIds) {
                localVarQueryParameter['CoursesIds'] = coursesIds;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoursesApi - functional programming interface
 * @export
 */
export const CoursesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CoursesApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesAdditionalDataGet(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCourseAdditionalDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesAdditionalDataGet(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {ConnectToFreshStartDesktopRequest} connectToFreshStartDesktopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesConnectToFreshStartDesktopPost(connectToFreshStartDesktopRequest: ConnectToFreshStartDesktopRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesktopModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesConnectToFreshStartDesktopPost(connectToFreshStartDesktopRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {ConnectToMyDesktopRequest} connectToMyDesktopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesConnectToMyDesktopPost(connectToMyDesktopRequest: ConnectToMyDesktopRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesktopModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesConnectToMyDesktopPost(connectToMyDesktopRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {ConnectToParticipantDesktopRequest} connectToParticipantDesktopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesConnectToParticipantDesktopPost(connectToParticipantDesktopRequest: ConnectToParticipantDesktopRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesktopModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesConnectToParticipantDesktopPost(connectToParticipantDesktopRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {ConnectToTrainerDesktopRequest} connectToTrainerDesktopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesConnectToTrainerDesktopPost(connectToTrainerDesktopRequest: ConnectToTrainerDesktopRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesktopModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesConnectToTrainerDesktopPost(connectToTrainerDesktopRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesCourseDesktopsGet(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCourseDesktopsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesCourseDesktopsGet(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {CreateFreshStartFromOsTemplateRequest} createFreshStartFromOsTemplateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesCreateFreshStartFromOsTemplatePost(createFreshStartFromOsTemplateRequest: CreateFreshStartFromOsTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesCreateFreshStartFromOsTemplatePost(createFreshStartFromOsTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {CreateFreshStartFromStandaloneTemplateRequest} createFreshStartFromStandaloneTemplateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesCreateFreshStartFromStandaloneTemplatePost(createFreshStartFromStandaloneTemplateRequest: CreateFreshStartFromStandaloneTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesCreateFreshStartFromStandaloneTemplatePost(createFreshStartFromStandaloneTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {DeleteDesktopRequest} deleteDesktopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesDeleteDesktopPost(deleteDesktopRequest: DeleteDesktopRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesDeleteDesktopPost(deleteDesktopRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {DeleteFreshStartDesktopRequest} deleteFreshStartDesktopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesDeleteFreshStartDesktopPost(deleteFreshStartDesktopRequest: DeleteFreshStartDesktopRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesDeleteFreshStartDesktopPost(deleteFreshStartDesktopRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesDetailsGet(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCourseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesDetailsGet(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesFreshStartDesktopGet(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesktopModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesFreshStartDesktopGet(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCoursesResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesGetFreshStartServersForCourseGet(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFreshStartServersForCourseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesGetFreshStartServersForCourseGet(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} courseId
         * @param {string} serverName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesGetOsTemplatesOfServerGet(courseId: string, serverName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOsTemplatesOfServerResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesGetOsTemplatesOfServerGet(courseId, serverName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesGetStandalonesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStandalonesResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesGetStandalonesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} joinCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesJoinStatusGet(joinCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCourseStatusResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesJoinStatusGet(joinCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {JoinToCourseRequest} joinToCourseRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesJoinToCoursePost(joinToCourseRequest: JoinToCourseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCourseStatusResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesJoinToCoursePost(joinToCourseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesMyDesktopGet(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesktopModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesMyDesktopGet(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {Array<string>} coursesIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesMyDesktopsGet(coursesIds: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMyDesktopsForCoursesResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesMyDesktopsGet(coursesIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesParticipantsGet(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParticipantModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesParticipantsGet(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {SaveIsFreshStartMachineReadyToCloneRequest} saveIsFreshStartMachineReadyToCloneRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesSaveIsFreshStartMachineReadyToClonePost(saveIsFreshStartMachineReadyToCloneRequest: SaveIsFreshStartMachineReadyToCloneRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesSaveIsFreshStartMachineReadyToClonePost(saveIsFreshStartMachineReadyToCloneRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {SaveMachineIframeSizeRequest} saveMachineIframeSizeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesSaveMachineIframeSizePost(saveMachineIframeSizeRequest: SaveMachineIframeSizeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesSaveMachineIframeSizePost(saveMachineIframeSizeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {SaveWhetherTrainerAllowedPreviewRequest} saveWhetherTrainerAllowedPreviewRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesSaveWhetherTrainerAllowedPreviewPost(saveWhetherTrainerAllowedPreviewRequest: SaveWhetherTrainerAllowedPreviewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesSaveWhetherTrainerAllowedPreviewPost(saveWhetherTrainerAllowedPreviewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {StartParticipantDesktopRequest} startParticipantDesktopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesStartParticipantDesktopPost(startParticipantDesktopRequest: StartParticipantDesktopRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesStartParticipantDesktopPost(startParticipantDesktopRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {StopDesktopRequest} stopDesktopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesStopDesktopPost(stopDesktopRequest: StopDesktopRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesStopDesktopPost(stopDesktopRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesTrainerDesktopGet(courseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesktopModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesTrainerDesktopGet(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {Array<string>} coursesIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesTrainerDesktopsGet(coursesIds: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTrainerDesktopsForCoursesResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesTrainerDesktopsGet(coursesIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CoursesApi - factory interface
 * @export
 */
export const CoursesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CoursesApiFp(configuration)
    return {
        /**
         *
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesAdditionalDataGet(courseId: string, options?: any): AxiosPromise<GetCourseAdditionalDataResult> {
            return localVarFp.coursesAdditionalDataGet(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ConnectToFreshStartDesktopRequest} connectToFreshStartDesktopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesConnectToFreshStartDesktopPost(connectToFreshStartDesktopRequest: ConnectToFreshStartDesktopRequest, options?: any): AxiosPromise<DesktopModel> {
            return localVarFp.coursesConnectToFreshStartDesktopPost(connectToFreshStartDesktopRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ConnectToMyDesktopRequest} connectToMyDesktopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesConnectToMyDesktopPost(connectToMyDesktopRequest: ConnectToMyDesktopRequest, options?: any): AxiosPromise<DesktopModel> {
            return localVarFp.coursesConnectToMyDesktopPost(connectToMyDesktopRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ConnectToParticipantDesktopRequest} connectToParticipantDesktopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesConnectToParticipantDesktopPost(connectToParticipantDesktopRequest: ConnectToParticipantDesktopRequest, options?: any): AxiosPromise<DesktopModel> {
            return localVarFp.coursesConnectToParticipantDesktopPost(connectToParticipantDesktopRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ConnectToTrainerDesktopRequest} connectToTrainerDesktopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesConnectToTrainerDesktopPost(connectToTrainerDesktopRequest: ConnectToTrainerDesktopRequest, options?: any): AxiosPromise<DesktopModel> {
            return localVarFp.coursesConnectToTrainerDesktopPost(connectToTrainerDesktopRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesCourseDesktopsGet(courseId: string, options?: any): AxiosPromise<GetCourseDesktopsResponse> {
            return localVarFp.coursesCourseDesktopsGet(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {CreateFreshStartFromOsTemplateRequest} createFreshStartFromOsTemplateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesCreateFreshStartFromOsTemplatePost(createFreshStartFromOsTemplateRequest: CreateFreshStartFromOsTemplateRequest, options?: any): AxiosPromise<any> {
            return localVarFp.coursesCreateFreshStartFromOsTemplatePost(createFreshStartFromOsTemplateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {CreateFreshStartFromStandaloneTemplateRequest} createFreshStartFromStandaloneTemplateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesCreateFreshStartFromStandaloneTemplatePost(createFreshStartFromStandaloneTemplateRequest: CreateFreshStartFromStandaloneTemplateRequest, options?: any): AxiosPromise<any> {
            return localVarFp.coursesCreateFreshStartFromStandaloneTemplatePost(createFreshStartFromStandaloneTemplateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {DeleteDesktopRequest} deleteDesktopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesDeleteDesktopPost(deleteDesktopRequest: DeleteDesktopRequest, options?: any): AxiosPromise<any> {
            return localVarFp.coursesDeleteDesktopPost(deleteDesktopRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {DeleteFreshStartDesktopRequest} deleteFreshStartDesktopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesDeleteFreshStartDesktopPost(deleteFreshStartDesktopRequest: DeleteFreshStartDesktopRequest, options?: any): AxiosPromise<any> {
            return localVarFp.coursesDeleteFreshStartDesktopPost(deleteFreshStartDesktopRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesDetailsGet(courseId: string, options?: any): AxiosPromise<GetCourseResult> {
            return localVarFp.coursesDetailsGet(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesFreshStartDesktopGet(courseId: string, options?: any): AxiosPromise<DesktopModel> {
            return localVarFp.coursesFreshStartDesktopGet(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGet(options?: any): AxiosPromise<GetCoursesResult> {
            return localVarFp.coursesGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetFreshStartServersForCourseGet(courseId: string, options?: any): AxiosPromise<GetFreshStartServersForCourseResult> {
            return localVarFp.coursesGetFreshStartServersForCourseGet(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} courseId
         * @param {string} serverName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetOsTemplatesOfServerGet(courseId: string, serverName: string, options?: any): AxiosPromise<GetOsTemplatesOfServerResult> {
            return localVarFp.coursesGetOsTemplatesOfServerGet(courseId, serverName, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetStandalonesGet(options?: any): AxiosPromise<GetStandalonesResult> {
            return localVarFp.coursesGetStandalonesGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} joinCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesJoinStatusGet(joinCode: string, options?: any): AxiosPromise<GetCourseStatusResult> {
            return localVarFp.coursesJoinStatusGet(joinCode, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {JoinToCourseRequest} joinToCourseRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesJoinToCoursePost(joinToCourseRequest: JoinToCourseRequest, options?: any): AxiosPromise<GetCourseStatusResult> {
            return localVarFp.coursesJoinToCoursePost(joinToCourseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesMyDesktopGet(courseId: string, options?: any): AxiosPromise<DesktopModel> {
            return localVarFp.coursesMyDesktopGet(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Array<string>} coursesIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesMyDesktopsGet(coursesIds: Array<string>, options?: any): AxiosPromise<GetMyDesktopsForCoursesResult> {
            return localVarFp.coursesMyDesktopsGet(coursesIds, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesParticipantsGet(courseId: string, options?: any): AxiosPromise<Array<ParticipantModel>> {
            return localVarFp.coursesParticipantsGet(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SaveIsFreshStartMachineReadyToCloneRequest} saveIsFreshStartMachineReadyToCloneRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesSaveIsFreshStartMachineReadyToClonePost(saveIsFreshStartMachineReadyToCloneRequest: SaveIsFreshStartMachineReadyToCloneRequest, options?: any): AxiosPromise<any> {
            return localVarFp.coursesSaveIsFreshStartMachineReadyToClonePost(saveIsFreshStartMachineReadyToCloneRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SaveMachineIframeSizeRequest} saveMachineIframeSizeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesSaveMachineIframeSizePost(saveMachineIframeSizeRequest: SaveMachineIframeSizeRequest, options?: any): AxiosPromise<any> {
            return localVarFp.coursesSaveMachineIframeSizePost(saveMachineIframeSizeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SaveWhetherTrainerAllowedPreviewRequest} saveWhetherTrainerAllowedPreviewRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesSaveWhetherTrainerAllowedPreviewPost(saveWhetherTrainerAllowedPreviewRequest: SaveWhetherTrainerAllowedPreviewRequest, options?: any): AxiosPromise<any> {
            return localVarFp.coursesSaveWhetherTrainerAllowedPreviewPost(saveWhetherTrainerAllowedPreviewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {StartParticipantDesktopRequest} startParticipantDesktopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesStartParticipantDesktopPost(startParticipantDesktopRequest: StartParticipantDesktopRequest, options?: any): AxiosPromise<any> {
            return localVarFp.coursesStartParticipantDesktopPost(startParticipantDesktopRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {StopDesktopRequest} stopDesktopRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesStopDesktopPost(stopDesktopRequest: StopDesktopRequest, options?: any): AxiosPromise<any> {
            return localVarFp.coursesStopDesktopPost(stopDesktopRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesTrainerDesktopGet(courseId: string, options?: any): AxiosPromise<DesktopModel> {
            return localVarFp.coursesTrainerDesktopGet(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Array<string>} coursesIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesTrainerDesktopsGet(coursesIds: Array<string>, options?: any): AxiosPromise<GetTrainerDesktopsForCoursesResult> {
            return localVarFp.coursesTrainerDesktopsGet(coursesIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CoursesApi - object-oriented interface
 * @export
 * @class CoursesApi
 * @extends {BaseAPI}
 */
export class CoursesApi extends BaseAPI {
    /**
     *
     * @param {string} courseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesAdditionalDataGet(courseId: string, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesAdditionalDataGet(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ConnectToFreshStartDesktopRequest} connectToFreshStartDesktopRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesConnectToFreshStartDesktopPost(connectToFreshStartDesktopRequest: ConnectToFreshStartDesktopRequest, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesConnectToFreshStartDesktopPost(connectToFreshStartDesktopRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ConnectToMyDesktopRequest} connectToMyDesktopRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesConnectToMyDesktopPost(connectToMyDesktopRequest: ConnectToMyDesktopRequest, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesConnectToMyDesktopPost(connectToMyDesktopRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ConnectToParticipantDesktopRequest} connectToParticipantDesktopRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesConnectToParticipantDesktopPost(connectToParticipantDesktopRequest: ConnectToParticipantDesktopRequest, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesConnectToParticipantDesktopPost(connectToParticipantDesktopRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ConnectToTrainerDesktopRequest} connectToTrainerDesktopRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesConnectToTrainerDesktopPost(connectToTrainerDesktopRequest: ConnectToTrainerDesktopRequest, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesConnectToTrainerDesktopPost(connectToTrainerDesktopRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} courseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesCourseDesktopsGet(courseId: string, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesCourseDesktopsGet(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {CreateFreshStartFromOsTemplateRequest} createFreshStartFromOsTemplateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesCreateFreshStartFromOsTemplatePost(createFreshStartFromOsTemplateRequest: CreateFreshStartFromOsTemplateRequest, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesCreateFreshStartFromOsTemplatePost(createFreshStartFromOsTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {CreateFreshStartFromStandaloneTemplateRequest} createFreshStartFromStandaloneTemplateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesCreateFreshStartFromStandaloneTemplatePost(createFreshStartFromStandaloneTemplateRequest: CreateFreshStartFromStandaloneTemplateRequest, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesCreateFreshStartFromStandaloneTemplatePost(createFreshStartFromStandaloneTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DeleteDesktopRequest} deleteDesktopRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesDeleteDesktopPost(deleteDesktopRequest: DeleteDesktopRequest, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesDeleteDesktopPost(deleteDesktopRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DeleteFreshStartDesktopRequest} deleteFreshStartDesktopRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesDeleteFreshStartDesktopPost(deleteFreshStartDesktopRequest: DeleteFreshStartDesktopRequest, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesDeleteFreshStartDesktopPost(deleteFreshStartDesktopRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} courseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesDetailsGet(courseId: string, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesDetailsGet(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} courseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesFreshStartDesktopGet(courseId: string, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesFreshStartDesktopGet(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesGet(options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} courseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesGetFreshStartServersForCourseGet(courseId: string, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesGetFreshStartServersForCourseGet(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} courseId
     * @param {string} serverName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesGetOsTemplatesOfServerGet(courseId: string, serverName: string, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesGetOsTemplatesOfServerGet(courseId, serverName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesGetStandalonesGet(options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesGetStandalonesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} joinCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesJoinStatusGet(joinCode: string, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesJoinStatusGet(joinCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {JoinToCourseRequest} joinToCourseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesJoinToCoursePost(joinToCourseRequest: JoinToCourseRequest, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesJoinToCoursePost(joinToCourseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} courseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesMyDesktopGet(courseId: string, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesMyDesktopGet(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {Array<string>} coursesIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesMyDesktopsGet(coursesIds: Array<string>, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesMyDesktopsGet(coursesIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} courseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesParticipantsGet(courseId: string, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesParticipantsGet(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SaveIsFreshStartMachineReadyToCloneRequest} saveIsFreshStartMachineReadyToCloneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesSaveIsFreshStartMachineReadyToClonePost(saveIsFreshStartMachineReadyToCloneRequest: SaveIsFreshStartMachineReadyToCloneRequest, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesSaveIsFreshStartMachineReadyToClonePost(saveIsFreshStartMachineReadyToCloneRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SaveMachineIframeSizeRequest} saveMachineIframeSizeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesSaveMachineIframeSizePost(saveMachineIframeSizeRequest: SaveMachineIframeSizeRequest, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesSaveMachineIframeSizePost(saveMachineIframeSizeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SaveWhetherTrainerAllowedPreviewRequest} saveWhetherTrainerAllowedPreviewRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesSaveWhetherTrainerAllowedPreviewPost(saveWhetherTrainerAllowedPreviewRequest: SaveWhetherTrainerAllowedPreviewRequest, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesSaveWhetherTrainerAllowedPreviewPost(saveWhetherTrainerAllowedPreviewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {StartParticipantDesktopRequest} startParticipantDesktopRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesStartParticipantDesktopPost(startParticipantDesktopRequest: StartParticipantDesktopRequest, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesStartParticipantDesktopPost(startParticipantDesktopRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {StopDesktopRequest} stopDesktopRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesStopDesktopPost(stopDesktopRequest: StopDesktopRequest, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesStopDesktopPost(stopDesktopRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} courseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesTrainerDesktopGet(courseId: string, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesTrainerDesktopGet(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {Array<string>} coursesIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesTrainerDesktopsGet(coursesIds: Array<string>, options?: AxiosRequestConfig) {
        return CoursesApiFp(this.configuration).coursesTrainerDesktopsGet(coursesIds, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCurrentInfoPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/current/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {SaveAreTipsHiddenRequest} saveAreTipsHiddenRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCurrentSaveAreTipsHiddenPost: async (saveAreTipsHiddenRequest: SaveAreTipsHiddenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveAreTipsHiddenRequest' is not null or undefined
            assertParamExists('usersCurrentSaveAreTipsHiddenPost', 'saveAreTipsHiddenRequest', saveAreTipsHiddenRequest)
            const localVarPath = `/api/users/current/saveAreTipsHidden`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveAreTipsHiddenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {SaveIsMachineBarPinnedRequest} saveIsMachineBarPinnedRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCurrentSaveIsMachineBarPinnedPost: async (saveIsMachineBarPinnedRequest: SaveIsMachineBarPinnedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveIsMachineBarPinnedRequest' is not null or undefined
            assertParamExists('usersCurrentSaveIsMachineBarPinnedPost', 'saveIsMachineBarPinnedRequest', saveIsMachineBarPinnedRequest)
            const localVarPath = `/api/users/current/saveIsMachineBarPinned`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveIsMachineBarPinnedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {SaveIsStartBannerHiddenRequest} saveIsStartBannerHiddenRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCurrentSaveIsStartBannerHiddenPost: async (saveIsStartBannerHiddenRequest: SaveIsStartBannerHiddenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveIsStartBannerHiddenRequest' is not null or undefined
            assertParamExists('usersCurrentSaveIsStartBannerHiddenPost', 'saveIsStartBannerHiddenRequest', saveIsStartBannerHiddenRequest)
            const localVarPath = `/api/users/current/saveIsStartBannerHidden`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveIsStartBannerHiddenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {SaveLanguageCodeRequest} saveLanguageCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCurrentSaveLanguageCodePost: async (saveLanguageCodeRequest: SaveLanguageCodeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveLanguageCodeRequest' is not null or undefined
            assertParamExists('usersCurrentSaveLanguageCodePost', 'saveLanguageCodeRequest', saveLanguageCodeRequest)
            const localVarPath = `/api/users/current/save/languageCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveLanguageCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {SaveCurrentUserInfoRequest} saveCurrentUserInfoRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCurrentSavePost: async (saveCurrentUserInfoRequest: SaveCurrentUserInfoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveCurrentUserInfoRequest' is not null or undefined
            assertParamExists('usersCurrentSavePost', 'saveCurrentUserInfoRequest', saveCurrentUserInfoRequest)
            const localVarPath = `/api/users/current/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveCurrentUserInfoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {SaveThemeRequest} saveThemeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCurrentSaveThemePost: async (saveThemeRequest: SaveThemeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveThemeRequest' is not null or undefined
            assertParamExists('usersCurrentSaveThemePost', 'saveThemeRequest', saveThemeRequest)
            const localVarPath = `/api/users/current/save/theme`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveThemeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersCurrentInfoPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentUserInfoResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersCurrentInfoPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {SaveAreTipsHiddenRequest} saveAreTipsHiddenRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersCurrentSaveAreTipsHiddenPost(saveAreTipsHiddenRequest: SaveAreTipsHiddenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersCurrentSaveAreTipsHiddenPost(saveAreTipsHiddenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {SaveIsMachineBarPinnedRequest} saveIsMachineBarPinnedRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersCurrentSaveIsMachineBarPinnedPost(saveIsMachineBarPinnedRequest: SaveIsMachineBarPinnedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersCurrentSaveIsMachineBarPinnedPost(saveIsMachineBarPinnedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {SaveIsStartBannerHiddenRequest} saveIsStartBannerHiddenRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersCurrentSaveIsStartBannerHiddenPost(saveIsStartBannerHiddenRequest: SaveIsStartBannerHiddenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersCurrentSaveIsStartBannerHiddenPost(saveIsStartBannerHiddenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {SaveLanguageCodeRequest} saveLanguageCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersCurrentSaveLanguageCodePost(saveLanguageCodeRequest: SaveLanguageCodeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersCurrentSaveLanguageCodePost(saveLanguageCodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {SaveCurrentUserInfoRequest} saveCurrentUserInfoRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersCurrentSavePost(saveCurrentUserInfoRequest: SaveCurrentUserInfoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersCurrentSavePost(saveCurrentUserInfoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {SaveThemeRequest} saveThemeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersCurrentSaveThemePost(saveThemeRequest: SaveThemeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersCurrentSaveThemePost(saveThemeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCurrentInfoPost(options?: any): AxiosPromise<CurrentUserInfoResult> {
            return localVarFp.usersCurrentInfoPost(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SaveAreTipsHiddenRequest} saveAreTipsHiddenRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCurrentSaveAreTipsHiddenPost(saveAreTipsHiddenRequest: SaveAreTipsHiddenRequest, options?: any): AxiosPromise<any> {
            return localVarFp.usersCurrentSaveAreTipsHiddenPost(saveAreTipsHiddenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SaveIsMachineBarPinnedRequest} saveIsMachineBarPinnedRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCurrentSaveIsMachineBarPinnedPost(saveIsMachineBarPinnedRequest: SaveIsMachineBarPinnedRequest, options?: any): AxiosPromise<any> {
            return localVarFp.usersCurrentSaveIsMachineBarPinnedPost(saveIsMachineBarPinnedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SaveIsStartBannerHiddenRequest} saveIsStartBannerHiddenRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCurrentSaveIsStartBannerHiddenPost(saveIsStartBannerHiddenRequest: SaveIsStartBannerHiddenRequest, options?: any): AxiosPromise<any> {
            return localVarFp.usersCurrentSaveIsStartBannerHiddenPost(saveIsStartBannerHiddenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SaveLanguageCodeRequest} saveLanguageCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCurrentSaveLanguageCodePost(saveLanguageCodeRequest: SaveLanguageCodeRequest, options?: any): AxiosPromise<any> {
            return localVarFp.usersCurrentSaveLanguageCodePost(saveLanguageCodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SaveCurrentUserInfoRequest} saveCurrentUserInfoRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCurrentSavePost(saveCurrentUserInfoRequest: SaveCurrentUserInfoRequest, options?: any): AxiosPromise<any> {
            return localVarFp.usersCurrentSavePost(saveCurrentUserInfoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SaveThemeRequest} saveThemeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCurrentSaveThemePost(saveThemeRequest: SaveThemeRequest, options?: any): AxiosPromise<any> {
            return localVarFp.usersCurrentSaveThemePost(saveThemeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersCurrentInfoPost(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersCurrentInfoPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SaveAreTipsHiddenRequest} saveAreTipsHiddenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersCurrentSaveAreTipsHiddenPost(saveAreTipsHiddenRequest: SaveAreTipsHiddenRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersCurrentSaveAreTipsHiddenPost(saveAreTipsHiddenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SaveIsMachineBarPinnedRequest} saveIsMachineBarPinnedRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersCurrentSaveIsMachineBarPinnedPost(saveIsMachineBarPinnedRequest: SaveIsMachineBarPinnedRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersCurrentSaveIsMachineBarPinnedPost(saveIsMachineBarPinnedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SaveIsStartBannerHiddenRequest} saveIsStartBannerHiddenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersCurrentSaveIsStartBannerHiddenPost(saveIsStartBannerHiddenRequest: SaveIsStartBannerHiddenRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersCurrentSaveIsStartBannerHiddenPost(saveIsStartBannerHiddenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SaveLanguageCodeRequest} saveLanguageCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersCurrentSaveLanguageCodePost(saveLanguageCodeRequest: SaveLanguageCodeRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersCurrentSaveLanguageCodePost(saveLanguageCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SaveCurrentUserInfoRequest} saveCurrentUserInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersCurrentSavePost(saveCurrentUserInfoRequest: SaveCurrentUserInfoRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersCurrentSavePost(saveCurrentUserInfoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SaveThemeRequest} saveThemeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersCurrentSaveThemePost(saveThemeRequest: SaveThemeRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersCurrentSaveThemePost(saveThemeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



import { createTheme } from '@mui/material/styles';
import shadows, { Shadows } from '@mui/material/styles/shadows';
import type {} from '@mui/lab/themeAugmentation';
import { Colors } from './colors';
import { Typo } from './typo';

// TODO Norbert: Extract this variable to a separate, dedicated file
const defaultMuiBoxShadow = '10px 10px 20px -14px rgba(0, 0, 0, .5)';

export interface CurrentTheme {
  currentTheme: string;
}

export const rootTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1440,
      xl: 1536
    }
  },
  palette: {},
  shadows: shadows.map(() => defaultMuiBoxShadow) as Shadows,
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained'
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          lineHeight: '140%',
          borderRadius: '7px',
          fontSize: '13px',
          fontWeight: 700,
          letterSpacing: '0.65px',
          height: '50px',
          padding: '13px 39px',
          boxShadow: 'none',
          backgroundColor: Colors.red,
          color: Colors.white,
          ':hover': {
            backgroundColor: Colors.blue,
            borderColor: Colors.blue,
            boxShadow: 'none'
          },
          '&.Mui-disabled': {
            boxShadow: 'none',
            backgroundColor: Colors.disabledButton,
            color: Colors.white
          }
        },
        startIcon: {
          'MuiSvgIcon-root': {
            color: Colors.white
          }
        }
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            backgroundColor: '#FFF',
            border: '1px solid',
            borderColor: Colors.darkGrey,
            borderRadius: '7px',
            color: Colors.textColor,
            fontWeight: 400,
            ':hover': {
              color: Colors.white,
              boxShadow: 'none'
            },
            'MuiSvgIcon-root': {
              color: Colors.darkGrey
            }
          }
        }
      ]
    },
    MuiSlider: {
      styleOverrides: {
        thumb: {
          color: Colors.white,
          border: `2px solid ${Colors.textColor}`,
          ':hover': {
            color: Colors.blue
          },
          ':active': {
            color: Colors.white
          }
        },
        track: {
          color: Colors.darkGrey,
          border: `1px solid ${Colors.textColor}`,
          background: Colors.darkGrey
        },
        rail: {
          color: Colors.lineGrey,
          border: `1px solid ${Colors.textColor}`,
          opacity: 1
        },
        valueLabel: {
          backgroundColor: Colors.green,
          color: Colors.white
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontSize: '13px',
          lineHeight: '140%',
          fontFamily: 'Roboto',
          fontWeight: 400,
          color: Colors.textColor
        },
        body: {
          minHeight: '100%',
          minWidth: '320px',
          overflowX: 'hidden',
          backgroundColor: Colors.lightGrey,
          scrollbarWidth: 'thin',
          scrollbarColor: `${Colors.textColor} ${Colors.lineGrey}`
        },
        a: {
          textDecoration: 'none'
        },
        '*::-webkit-scrollbar': {
          width: '10px'
        },
        '*::-webkit-scrollbar-track': {
          background: Colors.lineGrey,
          borderLeft: `1px solid ${Colors.darkGrey}`,
          '&:active': {
            borderLeft: `1px solid ${Colors.textColor}`,
            background: Colors.darkGrey
          }
        },
        '*::-webkit-scrollbar-thumb': {
          '&:hover:not(:active)': {
            background: Colors.blue
          },
          background: Colors.white,
          border: `1px solid ${Colors.textColor}`
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        variant: Typo.regular
      },
      variants: [
        {
          props: { variant: Typo.bigHeader },
          style: {
            fontSize: '30px',
            lineHeight: '140%',
            fontFamily: 'Open Sans',
            fontWeight: 700,
            color: Colors.black
          }
        },
        {
          props: { variant: Typo.header },
          style: {
            fontSize: '20px',
            lineHeight: '140%',
            fontFamily: 'Open Sans',
            fontWeight: 400,
            color: Colors.textColor
          }
        },
        {
          props: { variant: Typo.title },
          style: {
            fontSize: '17px',
            lineHeight: '140%',
            fontFamily: 'Roboto',
            fontWeight: 600,
            letterSpacing: '0.34px',
            color: Colors.black
          }
        },
        {
          props: { variant: Typo.regular },
          style: {
            fontSize: '13px',
            lineHeight: '140%',
            fontFamily: 'Roboto',
            fontWeight: 400,
            letterSpacing: '0.65px',
            color: Colors.textColor
          }
        }
      ]
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.white
        }
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            border: `1px solid ${Colors.lineGrey}`,
            borderRadius: '4px'
          }
        }
      ]
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: '0'
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          ':hover': {
            '--_thumbColor': Colors.blue
          }
        },
        thumb: ({ ownerState }) => ({
          backgroundColor: `var(--_thumbColor, ${
            ownerState?.checked ? Colors.switchChecked : Colors.white
          })`,
          border: `1px solid ${Colors.textColor}`
        }),
        track: ({ ownerState }) => ({
          backgroundColor: `${ownerState?.checked ? Colors.darkGrey : Colors.lineGrey} !important`,
          border: `1px solid ${ownerState?.checked ? Colors.textColor : Colors.darkGrey} `,
          opacity: '1 !important'
        })
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          ':hover': {
            color: Colors.blue
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.23)',
          '&:hover': {
            color: 'rgba(0, 0, 0, 0.87)'
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '7px',
          border: `1px solid ${Colors.darkGrey}`,
          boxShadow: '10px 10px 6px 0px rgba(0, 0, 0, 0.25)',
          maxWidth: '600px'
        },
        // @ts-ignore
        backdrop: {
          opacity: '0 !important'
        },
        scrollPaper: {
          alignItems: 'flex-start',
          marginTop: '70px'
        },
        scrollBody: {
          verticalAlign: 'top'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: Colors.darkGrey,
          path: {
            width: '15px',
            height: '10px'
          }
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          border: `2px ${Colors.textColor} solid`,
          borderRadius: '4px'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: Colors.blue,
            color: Colors.white
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          'input:-webkit-autofill': {
            WebkitBackgroundClip: 'text'
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-input': {
              WebkitTextFillColor: Colors.darkGrey
            }
          },
          '&:hover:not(.Mui-disabled)': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: Colors.blue,
              borderWidth: '2px'
            }
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: Colors.textColor
            }
          },
          '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: Colors.red,
              borderWidth: '2px'
            }
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: Colors.black
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: Colors.textColor
          }
        },
        asterisk: {
          '&.Mui-error': {
            color: Colors.textColor
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: Colors.textColor
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '14px',
          lineHeight: '140%',
          fontFamily: 'Open Sans',
          fontWeight: 400,
          color: Colors.white,
          backgroundColor: Colors.green
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          borderRadius: '7px',
          backgroundColor: Colors.lightGrey,
          color: Colors.textColor,
          fontSize: '14px'
        },
        standardError: {
          borderRadius: '7px',
          // TODO: czeka na akceptację issue #103
          // backgroundColor: Colors.green,
          // color: Colors.white,
          fontSize: '14px'
        }
      }
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        ol: {
          flexWrap: 'nowrap'
        }
      }
    }
  }
});

export default rootTheme;

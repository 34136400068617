import { Pipe, boolean, email, minLength, nonOptional, string } from 'valibot'; // 0.63 kB

export const fieldIsRequiredTranslationKey = 'FieldIsRequired';
export const notValidEmailTranslationKey = 'PleaseEnterValidEmailAddress';
export const passwordMustContainAtLeastOneUppercaseLetterTranslationKey =
  'PasswordMustContainAtLeastOneUppercaseLetter';
export const passwordMustContainAtLeastOneNumberTranslationKey =
  'PasswordMustContainAtLeastOneNumber';
export const passwordMustContainAtLeastOneSpecialCharacterTranslationKey =
  'PasswordMustContainAtLeastOneSpecialCharacter';
export const passwordMustHave8CharactersOrMoreTranslationKey = 'PasswordMustHave8CharactersOrMore';

export const stringRequiredWrapper = (pipe?: Pipe<string> | undefined) =>
  string(fieldIsRequiredTranslationKey, pipe);

export const stringRequired = string(fieldIsRequiredTranslationKey, [
  minLength(1, 'FieldIsRequired')
]);

export const emailWrapper = email(notValidEmailTranslationKey);
export const booleanRequired = nonOptional(boolean(), fieldIsRequiredTranslationKey);

import { useCallback, useEffect } from 'react';
import { RegisterAndJoinToCourseRequest, RegisterRequest } from 'Api';
import { useAppDispatch, useAppSelector } from 'Store';
import {
  clearIsRegistrationSuccess,
  clearRegistrationError,
  register,
  registerAndJoinToCourse,
  selectIsRegistrationSuccess,
  selectRegistrationError
} from '../authSlice';
import { RegistrationForm } from './RegistrationForm';

interface RegistrationFormContainerProps {
  joinCode?: string;
}

export function RegistrationFormContainer({ joinCode }: RegistrationFormContainerProps) {
  const dispatch = useAppDispatch();
  const registrationError = useAppSelector(selectRegistrationError);
  const isRegistrationSuccess = useAppSelector(selectIsRegistrationSuccess);

  const handleSubmit = useCallback(
    (values: RegisterRequest) => {
      if (!joinCode) {
        dispatch(register(values as RegisterRequest));
      } else {
        dispatch(
          registerAndJoinToCourse({ ...values, joinCode } as RegisterAndJoinToCourseRequest)
        );
      }
    },
    [dispatch, joinCode]
  );

  useEffect(() => {
    dispatch(clearRegistrationError());
    dispatch(clearIsRegistrationSuccess());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RegistrationForm
      onSubmit={handleSubmit}
      error={registrationError}
      isSubmitSuccess={isRegistrationSuccess}
    />
  );
}

export default RegistrationFormContainer;

import { CheckboxElement } from 'react-hook-form-mui';
import { translateErrorMessage } from 'Common/Form/FormConsts';
import { ReactNode } from 'react';

interface CheckboxFieldProps {
  name: string;
  label: string | ReactNode;
}

export function CheckboxField({ name, label }: CheckboxFieldProps) {
  return <CheckboxElement name={name} label={label} parseError={translateErrorMessage} />;
}

export default CheckboxField;

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import GoogleIcon from 'Assets/images/Google_icon.png';
import { LogInOrSignUpViaGoogleRequest } from 'Api';
import { useAppDispatch } from 'Store';
import { logInOrSignUpViaGoogle } from 'Auth/authSlice';
import { StyledButton } from 'Components/Styled';

interface IDecodedResponse {
  email: string;
  email_verified: boolean;
  name: string;
  picture: string;
  given_name: string;
  family_name: string;
  locale: string;
  sub: string;
}

function GoogleSimpleButton() {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const handleOnSuccess = useCallback(
    async (credentialResponse: TokenResponse) => {
      try {
        const response = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            Authorization: `Bearer ${credentialResponse.access_token}`
          }
        });

        const userData = response.data as IDecodedResponse;

        dispatch(
          logInOrSignUpViaGoogle({
            email: userData.email,
            firstName: userData.given_name,
            lastName: userData.family_name
          } as LogInOrSignUpViaGoogleRequest)
        );
      } catch (error) {
        console.error('Login via Google Failed');
      }
    },
    [dispatch]
  );

  const handleOnError = error => console.error('Login via Google Failed', error);

  const googleLogin = useGoogleLogin({
    onSuccess: handleOnSuccess,
    onError: handleOnError
    // TODO: flow: 'auth-code',
  });

  const handleLogin = () => googleLogin();

  return (
    <StyledButton
      variant="outlined"
      startIcon={<img alt="Google Icon" src={GoogleIcon} />}
      onClick={handleLogin}
    >
      {t('auth.loginViaGoogle')}
    </StyledButton>
  );
}

export default GoogleSimpleButton;

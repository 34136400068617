import React from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes
} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { captureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { persistor, store } from 'Store';
import { getAppConfig } from 'Config/configSlice';
import { isProductionEnvironment } from 'Utils/environments';
import App from './App';

import './i18n';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
store.dispatch(getAppConfig()).then((_result: any) => {
  if (isProductionEnvironment()) {
    const state = store.getState();
    const sentryConfig = state.config.sentrySettings;

    if (!sentryConfig?.dsn || !sentryConfig?.sentryEnvironment) {
      return;
    }

    Sentry.init({
      dsn: sentryConfig.dsn,
      environment: sentryConfig.sentryEnvironment,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        }),
        Sentry.replayIntegration({
          maskAllText: true,
          blockAllMedia: true
        }),
        captureConsoleIntegration()
      ],
      tracesSampleRate: 1.0
    });
  }
});

const clientId = '652484331723-bl8saoe96oodq62apcmlin0ieb5hlqqt.apps.googleusercontent.com';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={null}>
      <GoogleOAuthProvider clientId={clientId}>
        <App />
      </GoogleOAuthProvider>
    </PersistGate>
  </Provider>
);

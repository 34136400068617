import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DialogProps } from '@mui/material';
import { HeadDialog } from 'Components';

interface ConfirmationDialogProps {
  open: DialogProps['open'];
  content: React.ReactNode;
  onNo: () => void;
  onYes: () => void;
}

const ConfirmationDialog = ({ open, content, onNo, onYes }: ConfirmationDialogProps) => {
  const { t } = useTranslation();

  return (
    <HeadDialog
      content={content}
      buttons={
        <>
          <Button onClick={onYes} variant="outlined">
            {t('Yes')}
          </Button>
          <Button onClick={onNo} variant="outlined">
            {t('No')}
          </Button>
        </>
      }
      onClose={onNo}
      open={open}
    />
  );
};

export default ConfirmationDialog;

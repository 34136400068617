import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import {
  ConnectIconButton,
  SecondaryButtonText,
  SecondaryButtonWrapper,
  StyledPlayArrowIcon
} from './ConnectToDesktopButton.styles';
import { ChangeStateLoader } from './ChangeStateLoader';

interface ConnectToDesktopButtonProps {
  buttonText: string;
  onConnectToDesktopClick: () => void;
  disabled?: boolean;
  isDesktopStartPending: boolean;
  isDesktopStopPending: boolean;
  isDesktopDeletePending: boolean;
  isFreshStart?: boolean;
  isHighlighted?: boolean;
}

const ConnectToDesktopButton = ({
  buttonText,
  onConnectToDesktopClick,
  disabled,
  isDesktopStartPending,
  isDesktopStopPending,
  isDesktopDeletePending,
  isFreshStart = false,
  isHighlighted = false
}: ConnectToDesktopButtonProps) => {
  const { t } = useTranslation();

  return isDesktopStartPending ? (
    <ChangeStateLoader text={t('Connecting')} />
  ) : isDesktopStopPending ? (
    <ChangeStateLoader text={t('Stopping')} />
  ) : isDesktopDeletePending ? (
    <ChangeStateLoader text={t('Deleting')} />
  ) : (
    <Stack direction="row" alignItems="center" spacing={2}>
      <ConnectIconButton
        aria-label="connect"
        isFreshStart={isFreshStart}
        isHighlighted={isHighlighted}
        disabled={disabled}
        onClick={onConnectToDesktopClick}
      >
        <StyledPlayArrowIcon isFreshStart={isFreshStart} isHighlighted={isHighlighted} />
      </ConnectIconButton>
      <SecondaryButtonWrapper disabled={disabled} imitateButton>
        <SecondaryButtonText disabled={disabled} onClick={onConnectToDesktopClick}>
          {buttonText}
        </SecondaryButtonText>
      </SecondaryButtonWrapper>
    </Stack>
  );
};

export default memo(ConnectToDesktopButton);

const copyToClipboard = (textToCopy: string) => {
  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

  if (!isFirefox) {
    navigator.permissions.query({ name: 'clipboard-write' as PermissionName }).then(result => {
      if (result.state == 'granted' || result.state == 'prompt') {
        copyTextToClipboard(textToCopy);
      } else {
        alert('Write access to clipboard not granted!');
      }
    });
  } else {
    copyTextToClipboard(textToCopy);
  }
};

const copyTextToClipboard = (textToCopy: string): void => {
  const textArea = createTemporaryTextArea();
  textArea.value = textToCopy;
  document.body.appendChild(textArea);
  textArea.select();
  textArea.setSelectionRange(0, 99999); // note: for mobile devices

  if (!navigator.clipboard) {
    document.execCommand('copy');
  } else {
    navigator.clipboard.writeText(textToCopy);
  }

  textArea.remove();
  document.body.removeChild(textArea);
};

const createTemporaryTextArea = (): HTMLTextAreaElement => {
  const textArea = document.createElement('textarea');
  textArea.style.position = 'absolute';
  textArea.style.left = '-9969px';
  textArea.setAttribute('readonly', 'readonly');
  return textArea;
};

export default copyToClipboard;

import { ReactNode } from 'react';
import styled from 'styled-components';
import WebAssetOffIcon from '@mui/icons-material/WebAssetOff';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import trainerIcon from 'Assets/images/trainerIcon.svg';
import type { ConnectToParticipantDesktopRequest, ParticipantModel } from 'Api';
import { Colors } from 'Themes/colors';
import { useAppDispatch } from 'Store';
import { openExternalUrl } from 'Utils/redirects';
import {
  CourseModel,
  connectToMyDesktop,
  connectToParticipantDesktop,
  getMyDesktop
} from 'Courses/coursesSlice';
import UserInfo from '../../LivePreviewCard/UserInfo';

interface ParticipantInfoCardProps {
  course: CourseModel;
  participant: ParticipantModel;
  isTrainerDesktop?: boolean;
}

const StyledCard = styled.div`
  display: flex;
  border: 1px solid ${Colors.lineGrey};
  border-radius: 22px;
  height: 48px;
  background: ${Colors.white};
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;
`;

const StyledPlayCircleOutlineOutlinedIcon = styled(PlayCircleOutlineOutlinedIcon)`
  cursor: pointer;
`;

const trainerAvatar = <img src={trainerIcon} alt="trainer icon" />;

const ParticipantInfoCard = ({
  course,
  participant,
  isTrainerDesktop
}: ParticipantInfoCardProps) => {
  const dispatch = useAppDispatch();

  const redirectToDesktop = (desktopLink: string) => openExternalUrl(desktopLink);

  const shouldNotRedirectToNewTab = true;

  const connectToDesktop = () => {
    if (!isTrainerDesktop) {
      dispatch(
        connectToParticipantDesktop({
          courseId: course.id,
          participantId: participant.id
        } as ConnectToParticipantDesktopRequest)
      ).then(() => {
        !shouldNotRedirectToNewTab &&
          participant.desktop?.viewLink &&
          redirectToDesktop(participant.desktop?.viewLink);
      });
    } else {
      dispatch(connectToMyDesktop(course.id as string)).then(() => {
        // TODO: zmienić nazwę pola z "link" na np. "desktopLink",
        // wtedy sprawdzenie typu nie będzie potrzebne,
        // zrobić analogicznie w innych miejscach w aplikacji, np.ConnectToFreshStartButton itp.
        const isDesktopLinkString = typeof participant.desktop?.link === 'string';

        if (!participant.desktop?.link || !isDesktopLinkString) {
          dispatch(getMyDesktop(course.id as string)).then(response => {
            const newLink = (response?.payload as any)?.myDesktop?.link; // TODO: any

            !shouldNotRedirectToNewTab && newLink && redirectToDesktop(newLink as string);
          });
        } else {
          !shouldNotRedirectToNewTab &&
            participant.desktop?.link &&
            isDesktopLinkString &&
            redirectToDesktop(participant.desktop?.link as string);
        }
      });
    }
  };

  let componentToRender: ReactNode;

  // TODO: try simplify this condition
  if (
    (participant.desktop?.isRunning && participant.desktop?.instanceId) ||
    (!participant.desktop?.isRunning &&
      ((course.hasFreshStart && course.isFreshStartMachineReadyToClone) ||
        participant.desktop?.instanceId))
  ) {
    componentToRender = (
      <StyledPlayCircleOutlineOutlinedIcon fontSize="large" onClick={connectToDesktop} />
    );
  } else {
    componentToRender = <WebAssetOffIcon fontSize="large" />;
  }

  return (
    <StyledCard>
      <UserInfo
        name={participant.displayName}
        avatarColor={participant.avatarColor!}
        avatar={participant.avatar}
        customAvatar={isTrainerDesktop ? trainerAvatar : null}
      />
      {componentToRender}
    </StyledCard>
  );
};

export default ParticipantInfoCard;

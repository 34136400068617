import ParticipantInfoList from './ParticipantInfoList';
import ParticipantLivePreviewList from './ParticipantLivePreviewList';

// TODO Vorbert: Create a custom type for extracting type of object values
export type ListMode = keyof typeof ParticipantListConsts.listMode;

export type ParticipantListComponent =
  | typeof ParticipantInfoList
  | typeof ParticipantLivePreviewList;

export namespace ParticipantListConsts {
  export const listMode = {
    basicInfo: 'basicInfo',
    livePreview: 'livePreview'
  } as const;

  export const defaultListMode = listMode.livePreview;

  export const listComponentMap: Record<ListMode, ParticipantListComponent> = {
    [listMode.basicInfo]: ParticipantInfoList,
    [listMode.livePreview]: ParticipantLivePreviewList
  };
}

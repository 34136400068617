import { HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { authApi } from 'Api';

let listeners: (() => void)[] = [];

export const signalRConnection = new HubConnectionBuilder()
  .withUrl(
    (import.meta.env.DEV ? 'http://localhost:5000' : `${location.origin}`) + '/api/coursesHub'
  )
  .configureLogging(LogLevel.Debug)
  .build();

export function connectSignalR() {
  if (signalRConnection.state == HubConnectionState.Disconnected) {
    signalRConnection
      .start()
      .then(() => {
        emitStatusChange();
        signalRConnection.on('session_expired', () => {
          signalRConnection.stop().then(() => {
            emitStatusChange();
            signalRConnection.off('session_expired');
            authApi.authCookieGet().then(() => {
              connectSignalR();
            });
          });
        });
      })
      .catch(console.error);
  }
}

export function disconnectSignalR() {
  signalRConnection.stop().then(emitStatusChange).catch(console.error);
}

export function subscribeStateChange(listener: () => void) {
  listeners.push(listener);
  return () => {
    listeners = listeners.filter(l => l !== listener);
  };
}

export function getStateSnapshot() {
  return signalRConnection.state;
}

function emitStatusChange() {
  for (const listener of listeners) {
    listener();
  }
}

import { TextFieldElement, TextFieldElementProps } from 'react-hook-form-mui';
import { translateErrorMessage } from 'Common/Form/FormConsts';
import { useAppSelector } from 'Store/hooks';
import { selectCurrentTheme } from 'User/userSlice';

interface TextFieldProps extends TextFieldElementProps {
  type?: string;
  name: string;
  label?: string;
  isRequired?: boolean;
}

export function TextField({ type, name, label, isRequired, ...props }: TextFieldProps) {
  const currentTheme = useAppSelector(selectCurrentTheme);

  return (
    <TextFieldElement
      {...props}
      type={type || 'text'}
      name={name}
      label={label}
      autoComplete={label}
      required={isRequired}
      parseError={translateErrorMessage}
      inputProps={{ currentTheme: currentTheme }}
    />
  );
}

export default TextField;

import { FunctionComponent } from 'react';
import logoWhite from 'Assets/images/logo-white.svg';
import logoBlack from 'Assets/images/logo-black.svg';
import { selectCurrentTheme } from 'User/userSlice';
import { useAppSelector } from 'Store';
import { Themes } from 'Consts/themes';
import { StyledApplicationLogo } from './ApplicationLogo.styles';

interface ApplicationLogoProps {
  className?: string;
}

export const ApplicationLogo: FunctionComponent<ApplicationLogoProps> = ({ className }) => {
  const currentTheme = useAppSelector(selectCurrentTheme);

  return (
    <StyledApplicationLogo
      src={currentTheme === Themes.dark ? logoWhite : logoBlack}
      className={className}
    />
  );
};

import { Button } from '@mui/material';
import { FormContainer } from 'react-hook-form-mui';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Spacing } from 'Themes/spacing';
import { SaveCurrentUserInfoRequest } from 'Api';
import { AlertSuccess } from 'Common/AlertSuccess';
import { EmailField, TextField } from 'Common/Form';
import { SaveCurrentUserInfoSchema } from 'Common/Form/ValidationSchemas';
import { Avatar } from 'Common/UserAvatar/Avatar';
import { AvatarConsts } from 'Common/UserAvatar/Avatar/Avatar.consts';
import { useAppForm, useBoolean } from 'Utils/customHooks';
import { formFieldNames } from './formFieldNames';
import ColorPicker from './ColorPicker';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.medium};
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${Spacing.medium};
`;

interface MyAccountFormProps {
  onSubmit: (formData: SaveCurrentUserInfoRequest) => void;
  onCancel: () => void;
  email: string;
  firstName: string;
  lastName: string;
  displayName: string;
  avatarColor: string;
  isSubmitSuccess: boolean;
}

export const MyAccountForm = ({
  onSubmit,
  onCancel,
  email,
  firstName,
  lastName,
  displayName,
  avatarColor,
  isSubmitSuccess
}: MyAccountFormProps) => {
  const { t } = useTranslation();

  const formFieldDefaultValues = {
    [formFieldNames.email]: email,
    [formFieldNames.firstName]: firstName,
    [formFieldNames.lastName]: lastName,
    [formFieldNames.displayName]: displayName,
    [formFieldNames.avatarColor]: avatarColor
  };

  const formContext = useAppForm<SaveCurrentUserInfoRequest>(
    SaveCurrentUserInfoSchema,
    formFieldDefaultValues
  );

  const [colorPickerIsDisplayed, displayColorPicker, hideColorPicker] = useBoolean();

  const firstNameValue = formContext.watch(formFieldNames.firstName);
  const displayNameValue = formContext.watch(formFieldNames.displayName);
  const colorValue = formContext.watch(formFieldNames.avatarColor);

  const userDisplayName = firstNameValue || displayNameValue || AvatarConsts.fallbackUserName;

  const handleColorClick = (value: string) => {
    formContext.setValue(formFieldNames.avatarColor, value);
    hideColorPicker();
  };

  const handleOnSubmit = (formData: SaveCurrentUserInfoRequest) => {
    formData.avatarColor = colorValue;
    onSubmit(formData);
  };

  return (
    <FormContainer onSuccess={handleOnSubmit} formContext={formContext}>
      <Container>
        {isSubmitSuccess && <AlertSuccess text={t('myAccount.changesHaveBeenSaved')} />}

        {!colorPickerIsDisplayed ? (
          <>
            <EmailField name={formFieldNames.email} disabled label={t('myAccount.loginEmail')} />

            <TextField
              name={formFieldNames.firstName}
              label={t('myAccount.firstName')}
              isRequired
            />

            <TextField name={formFieldNames.lastName} label={t('myAccount.lastName')} isRequired />

            <TextField
              name={formFieldNames.displayName}
              label={t('myAccount.displayName')}
              isRequired
            />

            <AvatarContainer onClick={displayColorPicker}>
              <Avatar color={colorValue!} displayName={userDisplayName} shouldBiggerOnHover />
              <span>{t('myAccount.avatarColor')}</span>
            </AvatarContainer>
          </>
        ) : (
          <ColorPicker displayName={userDisplayName} onColorClick={handleColorClick} />
        )}

        <Buttons>
          <Button type="submit">{t('Save')}</Button>
          <Button variant="outlined" onClick={onCancel}>
            {t('Cancel')}
          </Button>
        </Buttons>
      </Container>
    </FormContainer>
  );
};

export default MyAccountForm;

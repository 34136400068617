import styled from 'styled-components';
import { Spacing } from 'Themes/spacing';

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;

  .MuiSlider-root {
    margin-right: calc(${Spacing.medium} - 2px);
    width: 82px;
  }
`;

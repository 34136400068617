import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { motion } from 'framer-motion';
import treneo2 from 'Assets/images/treneo-2.svg';
import mapBg1 from 'Assets/images/map-bg-1.png';
import { useAppDispatch, useAppSelector } from 'Store';
import { urls } from 'Router';
import { Colors } from 'Themes/colors';
import { Spacing } from 'Themes/spacing';
import {
  saveIsStartBannerHidden,
  selectCurrentUserInfo,
  selectUserDisplayName
} from 'User/userSlice';
import { UserDisplayModel } from 'User/userDisplayModel';
import { Card as BaseCard, Link, Logo } from 'Components';
import { selectLoginState, LoginState } from 'Auth/authSlice';
import { AvatarConsts } from 'Common/UserAvatar/Avatar/Avatar.consts';
import { useBoolean } from 'Utils/customHooks';
import { connectToTrainerDesktop, selectDemoCourse } from './coursesSlice';
import CourseCard from './CourseCard';

const Close = styled(CloseIcon)`
  position: absolute;
  cursor: pointer;
  top: 15px;
  right: 15px;
  color: ${Colors.darkGrey};
`;

const Container = styled.div`
  position: relative;
`;

const Card = styled(BaseCard)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

const SubContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const LeftColumn = styled(Column)`
  align-self: stretch;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const WelcomeMessage = styled(Typography)`
  font-size: 30px !important;
  font-weight: 700 !important;
  color: ${Colors.black} !important;
  max-width: 370px;
`;

const ActionPanel = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  min-height: 50px;
`;

const Img = styled.img`
  background: url(${mapBg1}) no-repeat;
  max-height: 300px;
`;

const DemoCourseWrapper = styled(motion.div)`
  position: relative;
  width: 100%;
  margin-top: ${Spacing.medium};
`;

interface StartBannerProps {
  isHighlighted: boolean;
}

function StartBanner({ isHighlighted }: StartBannerProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const currentUserInfo = useAppSelector(selectCurrentUserInfo);
  const currentUserInfoDisplayName =
    useAppSelector(selectUserDisplayName) || AvatarConsts.fallbackUserName;

  const currentUserModel = {
    id: currentUserInfo.id,
    displayName: currentUserInfoDisplayName,
    avatarColor: currentUserInfo.avatarColor,
    avatar: currentUserInfo.avatar
  } as UserDisplayModel;

  const demoCourse = useAppSelector(selectDemoCourse);
  const loginState = useAppSelector(selectLoginState);
  const isLogged = loginState === LoginState.LOGGED;

  const [shouldDemoCourseDisplay, displayDemoCourse, hideDemoCourse] = useBoolean();

  const handleTestBannerClick = () => {
    displayDemoCourse();

    dispatch(connectToTrainerDesktop(demoCourse!.id!));
  };

  const onClose = () => {
    dispatch(saveIsStartBannerHidden({ isStartBannerHidden: true, isLogged }));
  };

  return (
    <Container>
      <Card>
        <SubContainer>
          <LeftColumn>
            <TitleContainer>
              <Typography>{t('startBanner.welcomeIn')}</Typography>
              <Logo variant="login" />
            </TitleContainer>

            <WelcomeMessage>{t('startBanner.welcomeMessage')}</WelcomeMessage>

            <ActionPanel>
              {!shouldDemoCourseDisplay ? (
                <>
                  <Button
                    variant={isHighlighted ? 'contained' : 'outlined'}
                    onClick={handleTestBannerClick}
                  >
                    {t('startBanner.test')}
                  </Button>
                  <Typography>{t('startBanner.orSee')}</Typography>
                </>
              ) : (
                ''
              )}
              <Link to={urls.treneoSite} label={t('startBanner.whatIsTreneo')} />
            </ActionPanel>
          </LeftColumn>

          <Column>
            <Img width="100%" src={treneo2} />
          </Column>
        </SubContainer>

        {shouldDemoCourseDisplay && demoCourse ? (
          <DemoCourseWrapper
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0, opacity: 0 }}
            transition={{ duration: 0.6 }}
          >
            <CourseCard key={demoCourse.id} course={demoCourse} user={currentUserModel} />
            <Close onClick={hideDemoCourse} />
          </DemoCourseWrapper>
        ) : (
          ''
        )}
      </Card>
      <Close onClick={onClose} />
    </Container>
  );
}

export default memo(StartBanner);

import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material';
import { useAppSelector } from 'Store';
import { BreadcrumbItem, selectBreadcrumbs } from './breadcrumbSlice';
import styled from 'styled-components';

const LastTypo = styled(Typography)`
  font-weight: 700 !important;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

function Breadcrumbs() {
  const { t } = useTranslation();
  const breadcrumbs = useAppSelector(selectBreadcrumbs) as BreadcrumbItem[];

  return (
    <MuiBreadcrumbs>
      {breadcrumbs.map((breadcrumb, index) => {
        if (breadcrumbs.length - 1 === index) {
          return (
            <LastTypo key={index}>
              {breadcrumb.label ? breadcrumb.label : t(breadcrumb.translateKey!)}
            </LastTypo>
          );
        }
        return (
          <Link key={index} to={breadcrumb.to}>
            <Typography>
              {breadcrumb.label ? breadcrumb.label : t(breadcrumb.translateKey!)}
            </Typography>
          </Link>
        );
      })}
    </MuiBreadcrumbs>
  );
}

export default memo(Breadcrumbs);

import { useTranslation } from 'react-i18next';
import { Link as MuiLink, Typography } from '@mui/material';
import styled from 'styled-components';
import { Colors } from 'Themes/colors';
import { Spacing } from 'Themes/spacing';
import banner_404 from 'Assets/images/banner_404.svg';
import { Link, linkStyle, SingleLine } from 'Components';
import { CloseIconButton } from '../CloseIconButton';
import { StyledContainer } from './NotFoundPage.styles';

export const Wrapper = styled.div`
  position: relative;
  background: ${Colors.white};
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: ${Spacing.large};
  flex-wrap: wrap;
  gap: ${Spacing.large};
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.small};
`;

export const ButtonLink = styled(MuiLink)`
  vertical-align: baseline;

  ${linkStyle}
`;

export const StyledMuiLink = styled(MuiLink)`
  ${linkStyle}
`;

export const StyledCloseIconButton = styled(CloseIconButton)`
  position: absolute !important;
  top: ${Spacing.small};
  right: ${Spacing.small};
`;

const StyledImg = styled.img`
  max-height: 450px;
`;

const NotFoundPage = () => {
  const { t } = useTranslation();

  const handleBackClick = () => window.history.back();

  return (
    <StyledContainer>
      <Wrapper>
        <LeftColumn>
          <Typography>{t('notFoundPage.title')}</Typography>
          <Typography fontSize="164px" fontWeight={700} color={Colors.black}>
            404
          </Typography>
          <Typography>{t('notFoundPage.firstLine')}</Typography>
          <SingleLine>
            <ButtonLink onClick={handleBackClick}>{t('notFoundPage.backButton')}</ButtonLink>
            <Typography>{t('notFoundPage.secondLine')}</Typography>
            <Link to="/" label={t('notFoundPage.mainPage')} />
          </SingleLine>
          <SingleLine>
            <Typography>{t('notFoundPage.thirdLine')}</Typography>
            <StyledMuiLink href="mailto:kontakt@treneo.pl">kontakt@treneo.pl</StyledMuiLink>
          </SingleLine>
        </LeftColumn>
        <StyledImg src={banner_404} alt="banner 404"></StyledImg>
        <StyledCloseIconButton onClose={handleBackClick} />
      </Wrapper>
    </StyledContainer>
  );
};

export default NotFoundPage;

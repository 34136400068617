import { AnyAction, Reducer, combineReducers, configureStore } from '@reduxjs/toolkit';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as Sentry from '@sentry/react';
import { axiosMiddleware } from 'Config/axios';
import { configSlice } from 'Config';
import { authSlice } from 'Auth';
import { userSlice } from 'User';
import { coursesSlice } from 'Courses';
import { isNotProductionEnvironment } from 'Utils/environments';
import { breadcrumbReducer } from 'Breadcrumb';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
};

// TODO: spróbować trzymać theme w localStorage w taki sposób, zamiast ręcznie ustawiać tak jak to jest teraz robione
// const userPersistConfig = {
//   key: 'user',
//   storage,
//   whitelist: ['currentUserInfo.theme']
// };

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory()
});

const rootReducer = combineReducers({
  router: routerReducer,
  config: configSlice,
  auth: authSlice,
  user: userSlice,
  courses: coursesSlice,
  breadcrumb: breadcrumbReducer
});

const resettableRootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'auth/logout/pending') {
    storage.removeItem('persist:root');
    Object.keys(state).forEach(key => key !== 'router' && delete state[key]);
    return rootReducer(state, action);
  }

  return rootReducer(state, action);
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export const store = configureStore({
  reducer: persistReducer(persistConfig, resettableRootReducer),
  devTools: isNotProductionEnvironment(),
  // enhancers: getDefaultEnhancers => getDefaultEnhancers().concat(sentryReduxEnhancer),
  enhancers: [sentryReduxEnhancer],
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ['_persist'],
        ignoredActions: ['persist/PERSIST']
      }
    })
      .concat(axiosMiddleware)
      .concat(routerMiddleware)
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const history = createReduxHistory(store);

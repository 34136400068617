import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues, useForm } from 'react-hook-form';
import { valibotResolver } from '@hookform/resolvers/valibot';
import { supportedLanguages, ILanguage } from 'Consts/language';

// TODO: add parameter schema type
export const useAppForm = <T extends FieldValues>(schema, defaultValues?) =>
  useForm<T>({
    mode: 'all',
    resolver: valibotResolver(schema),
    defaultValues
  });

export const useCurrentLanguage = (): ILanguage => {
  const { i18n: translator } = useTranslation();

  const currentLanguage = useMemo(
    () =>
      supportedLanguages.find(
        supportedLanguage => supportedLanguage.code === translator.language
      ) || ({} as ILanguage),
    [translator.language]
  );

  return currentLanguage;
};

type ImmediateIntervalCallback = () => void;
export function useImmediateInterval(
  callback: ImmediateIntervalCallback,
  delay: number,
  clearingStatement = false
) {
  const savedCallback = useRef<ImmediateIntervalCallback>();

  useEffect(() => {
    callback();
  }, []);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      clearingStatement && clearInterval(id);
      return () => clearInterval(id);
    }

    return undefined;
  }, [delay, clearingStatement]);
}

type UseBoolean = [boolean, () => void, () => void];
export function useBoolean(defaultValue = false): UseBoolean {
  const [value, setValue] = useState(defaultValue);

  const setValueToTrue = useCallback(() => {
    setValue(true);
  }, []);

  const setValueToFalse = useCallback(() => {
    setValue(false);
  }, []);

  return [value, setValueToTrue, setValueToFalse];
}

type UseToggle = [boolean, () => void];
export function useToggle(defaultValue = false): UseToggle {
  const [value, setValue] = useState(defaultValue);

  const toggleValue = () => {
    setValue(currentValue => !currentValue);
  };

  return [value, toggleValue];
}

export const useElementInFullscreen = (refElement: MutableRefObject<HTMLElement | null>) => {
  const [isFullscreenEnabled, _setFullscreenMode, _setInitialMode] = useBoolean();

  const enterFullscreenMode = () => {
    refElement?.current?.requestFullscreen();
  };

  const exitFullscreenMode = () => {
    document.exitFullscreen();
  };

  const toggleFullscreenMode = () => {
    isFullscreenEnabled ? exitFullscreenMode() : enterFullscreenMode();
  };

  const handleFullscreenChange = () => {
    if (document.fullscreenElement) {
      _setFullscreenMode();
    } else {
      _setInitialMode();
    }
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    () => document.removeEventListener('fullscreenchange', handleFullscreenChange);
  }, []);

  return {
    isFullscreenEnabled,
    enterFullscreenMode,
    exitFullscreenMode,
    toggleFullscreenMode
  };
};

import styled from 'styled-components';
import { Typography } from '@mui/material';
import { Colors } from 'Themes/colors';
import { Typo } from 'Themes/typo';

const ChangeStateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Colors.blue};
  width: 100%;
`;
const ChangeStateTypography = styled(Typography)`
  font-size: 23px !important;
  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: '\\2026';
    width: 0;
    margin-right: 0;
  }

  @keyframes ellipsis {
    to {
      width: 40px;
      margin-right: -40px;
    }
  }
`;

export function ChangeStateLoader({ text }: { text: string }) {
  return (
    <ChangeStateWrapper>
      <ChangeStateTypography variant={Typo.bigHeader} color={Colors.white}>
        {text}
      </ChangeStateTypography>
    </ChangeStateWrapper>
  );
}

import { Configuration, AuthApi, ConfigApi, CoursesApi, UsersApi } from './Generated';

class FixedFormData {
  constructor() {
    // TODO:
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return new FormData().fd; // this should work without .fb, but I don't have idea why I need do that
  }
}

const configuration = new Configuration({
  basePath: import.meta.env.DEV ? 'http://localhost:5000' : `${location.origin}`,
  baseOptions: { withCredentials: true },
  formDataCtor: import.meta.env.DEV ? FixedFormData : undefined
});

export * from './Generated';
export const configApi = new ConfigApi(configuration);
export const authApi = new AuthApi(configuration);
export const usersApi = new UsersApi(configuration);
export const coursesApi = new CoursesApi(configuration);

import { memo } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { Colors } from 'Themes/colors';
import { Spacing } from 'Themes/spacing';
import { actionPanelHeight } from './ActionPanelHeight';

const OptionsContainer = styled.div`
  background: ${Colors.white};
  height: calc(100% - ${actionPanelHeight});
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  gap: ${Spacing.small};
  padding-bottom: ${Spacing.small};
`;

const StyledIconButton = styled(IconButton)`
  position: relative;

  // left border line
  &:before {
    position: absolute;
    height: calc(100% + 16px);
    content: '';
    left: -5px;
    border-left: 1px solid ${Colors.darkGrey};
  }

  // hide top border line
  &:after {
    position: absolute;
    height: 5px;
    content: '';
    top: -10px;
    width: 200%;
    left: -20px;
    transform: translateX(calc(25% + 1px));
    background: ${Colors.white};
  }
`;

interface Props {
  disabled: boolean;
  children?: React.ReactNode;
  open: boolean;
  toggle: () => void;
}

function SettingsMenu({ disabled, children, open, toggle }: Props) {
  if (open) {
    return (
      <>
        <OptionsContainer>{children}</OptionsContainer>
        <StyledIconButton size="small" onClick={toggle} disabled={disabled}>
          <CloseIcon style={{ zIndex: 2 }} />
        </StyledIconButton>
      </>
    );
  }

  return (
    <IconButton sx={{ color: Colors.darkGrey }} size="small" onClick={toggle} disabled={disabled}>
      <SettingsIcon />
    </IconButton>
  );
}

export default memo(SettingsMenu);

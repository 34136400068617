import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Typography, styled } from '@mui/material';
import { Typo } from 'Themes/typo';
import { Colors } from 'Themes/colors';

const Title = styled('div')`
  display: flex;
  align-items: center;
  gap: 19px;
  margin-bottom: 24px;
`;

const CoursesWrapper = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  gap: 25px;

  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 1px;
    left: 0;
    background-color: ${Colors.darkGrey};
  }
`;

const StyledPlayCircleIcon = styled(PlayCircleIcon)<StyledPlayCircleIconProps>`
  color: ${props => (!props.isSearchResultsGroup ? Colors.darkGrey : Colors.red)};
`;

interface StyledPlayCircleIconProps {
  isSearchResultsGroup: boolean;
}

interface Props {
  label: string;
  isSearchResultsGroup?: boolean;
  children: React.ReactNode;
}

export function CoursesGroup({ label, isSearchResultsGroup = false, children }: Props) {
  return (
    <div>
      <Title>
        <StyledPlayCircleIcon isSearchResultsGroup={isSearchResultsGroup} />
        <Typography variant={Typo.header}>{label}</Typography>
      </Title>

      <CoursesWrapper>{children}</CoursesWrapper>
    </div>
  );
}

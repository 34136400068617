import styled from 'styled-components';
import type { DesktopModel, ParticipantModel } from 'Api';
import { UserDisplayModel } from 'User/userDisplayModel';
import { CourseModel } from 'Courses/coursesSlice';
import { getUserDisplayName } from 'Utils/user';
import LivePreviewCard from '../../LivePreviewCard';

interface ParticipantLivePreviewListProps {
  course: CourseModel;
  participantList: ParticipantModel[];
  trainer: ParticipantModel;
  columns: number;
}

const Grid = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 1fr);
  gap: 20px;
`;

const ParticipantLivePreviewList = ({
  course,
  participantList,
  trainer,
  columns
}: ParticipantLivePreviewListProps) => {
  const livePreviewCards = participantList.map((participant, participantIndex) => (
    <LivePreviewCard
      key={participantIndex}
      course={course}
      user={
        {
          id: participant.id,
          displayName: getUserDisplayName(participant.displayName),
          avatarColor: participant.avatarColor,
          avatar: participant.avatar
        } as UserDisplayModel
      }
      desktop={participant.desktop as DesktopModel}
      isParticipantDesktop
    />
  ));

  return (
    <Grid columns={columns}>
      <>
        {livePreviewCards}

        <LivePreviewCard
          course={course}
          user={
            {
              id: trainer.id,
              displayName: getUserDisplayName(trainer.displayName),
              avatarColor: trainer.avatarColor,
              avatar: trainer.avatar
            } as UserDisplayModel
          }
          desktop={trainer.desktop as DesktopModel}
          isTrainerDesktop
        />
      </>
    </Grid>
  );
};

export default ParticipantLivePreviewList;

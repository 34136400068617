import { Alert } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

interface AlertErrorProps {
  text: string;
}

const AlertError = ({ text }: AlertErrorProps) => (
  <Alert severity="error" icon={<CancelIcon />}>
    {text}
  </Alert>
);

export default AlertError;

import styled from 'styled-components';
import { Colors } from 'Themes/colors';

export const Divider = styled.div`
  align-self: stretch;
  width: 2px;
  background: ${Colors.lineGrey};
  margin: 0;
  flex: 0 1 1px;
`;

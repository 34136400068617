import { t } from 'i18next';
import { FieldError } from 'react-hook-form';

export const translateErrorMessage = (error: FieldError) => {
  if (error.type === 'required') {
    return t('FieldIsRequired');
  }

  return t(error.message as string);
};

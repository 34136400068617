import { useCallback, useContext, useEffect } from 'react';
import { HubConnectionState } from '@microsoft/signalr';
import { SignalRConnectionContext, useOnSignalRMethod } from 'SignalR';
import { useAppDispatch } from 'Store';
import { ParticipantModel } from 'Api';
import { addParticipant, newDesktopConnected, NewDesktopConnected } from 'Courses/coursesSlice';

export function useJoinCourseGroup(courseId: string) {
  const { connection, state } = useContext(SignalRConnectionContext);
  const dispatch = useAppDispatch();

  useOnSignalRMethod('NewParticipantConnected', (participant: ParticipantModel) => {
    dispatch(addParticipant({ participant, courseId }));
  });

  useOnSignalRMethod(
    'NewDesktopConnected',
    useCallback(
      (model: NewDesktopConnected) => {
        dispatch(newDesktopConnected({ model, courseId }));
      },
      [dispatch, courseId]
    )
  );

  useEffect(() => {
    if (state !== HubConnectionState.Connected) {
      return;
    }

    const joinCourseGroup = async () => {
      await connection.invoke('JoinCourseGroup', courseId);
    };

    joinCourseGroup().catch(console.error);

    return () => {
      connection.invoke('LeaveCourseGroup', courseId).catch(console.error);
    };
  }, [connection, courseId, dispatch, state]);
}

import i18next from 'i18next';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'Store';
import {
  CurrentUserInfoResult,
  SaveAreTipsHiddenRequest,
  SaveCurrentUserInfoRequest,
  SaveIsMachineBarPinnedRequest,
  SaveIsStartBannerHiddenRequest,
  SaveLanguageCodeRequest,
  SaveThemeRequest,
  usersApi
} from 'Api';
import { LocalStorageKeys } from 'Consts/localStorageKeys';
import { connectSignalR } from 'SignalR';

export interface UsersState {
  currentUserInfo: CurrentUserInfoResult;
  isSaveCurrentUserInfoSuccess?: boolean;
}

const initialState: UsersState = {
  currentUserInfo: {}
};

export const getCurrentUserInfo = createAsyncThunk('users/getCurrentUserInfo', async () => {
  const result = await usersApi.usersCurrentInfoPost();
  await i18next.changeLanguage(result.data.languageCode as string);
  saveThemeToLocalStorage(result.data.theme as string);
  connectSignalR();
  return result.data;
});

export const saveCurrentUserInfo = createAsyncThunk(
  'users/saveCurrentUserInfo',
  async (request: SaveCurrentUserInfoRequest) => {
    await usersApi.usersCurrentSavePost(request);
    return request;
  }
);

export const saveLanguage = createAsyncThunk(
  'users/saveLanguage',
  async ({ languageCode, isLogged }: { languageCode: string; isLogged: boolean }) => {
    if (isLogged) {
      await usersApi.usersCurrentSaveLanguageCodePost({ languageCode } as SaveLanguageCodeRequest);
    }

    return languageCode;
  }
);

export const saveTheme = createAsyncThunk(
  'users/saveTheme',
  async ({ theme, isLogged }: { theme: string; isLogged: boolean }) => {
    if (isLogged) {
      await usersApi.usersCurrentSaveThemePost({ theme } as SaveThemeRequest);
    }

    return theme;
  }
);

export const saveIsStartBannerHidden = createAsyncThunk(
  'users/saveIsStartBannerHidden',
  async ({
    isStartBannerHidden,
    isLogged
  }: {
    isStartBannerHidden: boolean;
    isLogged: boolean;
  }) => {
    if (isLogged) {
      await usersApi.usersCurrentSaveIsStartBannerHiddenPost({
        isStartBannerHidden
      } as SaveIsStartBannerHiddenRequest);
    }

    return isStartBannerHidden;
  }
);

export const saveIsMachineBarPinned = createAsyncThunk(
  'users/saveIsMachineBarPinned',
  async ({ isMachineBarPinned, isLogged }: { isMachineBarPinned: boolean; isLogged: boolean }) => {
    if (isLogged) {
      await usersApi.usersCurrentSaveIsMachineBarPinnedPost({
        isMachineBarPinned
      } as SaveIsMachineBarPinnedRequest);
    }

    return isMachineBarPinned;
  }
);

export const saveAreTipsHidden = createAsyncThunk(
  'users/saveAreTipsHidden',
  async ({ areTipsHidden, isLogged }: { areTipsHidden: boolean; isLogged: boolean }) => {
    if (isLogged) {
      await usersApi.usersCurrentSaveAreTipsHiddenPost({
        areTipsHidden
      } as SaveAreTipsHiddenRequest);
    }

    return areTipsHidden;
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearCurrentUserInfo(state) {
      state.currentUserInfo = {};
    },
    clearIsSaveCurrentUserInfoSuccess(state) {
      state.isSaveCurrentUserInfoSuccess = false;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getCurrentUserInfo.fulfilled, (state, action) => {
        state.currentUserInfo = action.payload;
      })
      .addCase(saveCurrentUserInfo.pending, state => {
        state.isSaveCurrentUserInfoSuccess = false;
      })
      .addCase(saveCurrentUserInfo.fulfilled, (state, action) => {
        state.currentUserInfo.displayName = action.payload.displayName;
        state.currentUserInfo.firstName = action.payload.firstName;
        state.currentUserInfo.lastName = action.payload.lastName;
        state.currentUserInfo.avatarColor = action.payload.avatarColor;
        state.isSaveCurrentUserInfoSuccess = true;
      })
      .addCase(saveCurrentUserInfo.rejected, state => {
        state.isSaveCurrentUserInfoSuccess = false;
      })
      .addCase(saveLanguage.fulfilled, (state, action) => {
        state.currentUserInfo.languageCode = action.payload;
        i18next.changeLanguage(action.payload);
      })
      .addCase(saveTheme.fulfilled, (state, action) => {
        state.currentUserInfo.theme = action.payload;
        saveThemeToLocalStorage(action.payload);
      })
      .addCase(saveIsStartBannerHidden.fulfilled, (state, action) => {
        state.currentUserInfo.isStartBannerHidden = action.payload;
      })
      .addCase(saveIsMachineBarPinned.fulfilled, (state, action) => {
        state.currentUserInfo.isMachineBarPinned = action.payload;
      })
      .addCase(saveAreTipsHidden.fulfilled, (state, action) => {
        state.currentUserInfo.areTipsHidden = action.payload;
      });
  }
});

export const { clearCurrentUserInfo, clearIsSaveCurrentUserInfoSuccess } = userSlice.actions;

export default userSlice.reducer;

const selectSelf = (state: RootState) => state.user;

export const selectCurrentUserInfo = createSelector(selectSelf, state => state.currentUserInfo);
export const selectUserDisplayName = createSelector(
  selectSelf,
  state =>
    state.currentUserInfo.displayName ||
    state.currentUserInfo.firstName ||
    state.currentUserInfo.lastName
);
export const selectIsStartBannerHidden = createSelector(
  selectSelf,
  state => state.currentUserInfo.isStartBannerHidden
);
export const selectIsMachineBarPinned = createSelector(
  selectSelf,
  state => state.currentUserInfo.isMachineBarPinned
);
export const selectCurrentTheme = createSelector(selectSelf, state => getThemeFromStorage(state));

export const selectIsSaveCurrentUserInfoSuccess = createSelector(
  selectSelf,
  state => state.isSaveCurrentUserInfoSuccess
);

const getThemeFromStorage = (state: RootState) => {
  const themeFromStorage = getThemeFromLocalStorage();
  return themeFromStorage || state.currentUserInfo.theme;
};

const saveThemeToLocalStorage = (theme: string): void =>
  localStorage.setItem(LocalStorageKeys.theme, theme);

const getThemeFromLocalStorage = (): string | null => localStorage.getItem(LocalStorageKeys.theme);

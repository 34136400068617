import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { isNotProductionEnvironment } from 'Utils/environments';
import { appDefaultLanguageCode } from 'Consts/language';

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: appDefaultLanguageCode,
    debug: isNotProductionEnvironment(),
    interpolation: {
      escapeValue: false
    }
  });

export default i18next;

import { Alert } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface AlertSuccessProps {
  text: string;
}

const AlertSuccess = ({ text }: AlertSuccessProps) => (
  <Alert severity="success" icon={<CheckCircleIcon />}>
    {text}
  </Alert>
);

export default AlertSuccess;

import { Suspense, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { CssBaseline, PaletteMode } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Router from 'Router/Router';
import { useAppDispatch, useAppSelector } from 'Store';
import { Themes } from 'Consts/themes';
import { LoginState, selectLoginState } from 'Auth/authSlice';
import { getCurrentUserInfo, selectCurrentTheme, selectCurrentUserInfo } from 'User/userSlice';
import { themeDark } from 'Themes/themeDark';
import { themeLight } from 'Themes/themeLight';
import { SignalRConnectionContextProvider } from 'SignalR';
import { Colors } from './Themes/colors';

function App() {
  const dispatch = useAppDispatch();
  const loginState = useAppSelector(selectLoginState);
  const currentUserInfo = useAppSelector(selectCurrentUserInfo);
  const applicationTheme = useAppSelector(selectCurrentTheme);

  const isObjectEmpty = (object: any) => Object.keys(object).length === 0;

  const getTheme = (applicationTheme: PaletteMode) => {
    if (applicationTheme && applicationTheme === Themes.dark) {
      return themeDark;
    }

    return themeLight;
  };

  const currentTheme = getTheme(applicationTheme);

  useEffect(() => {
    if (loginState === LoginState.LOGGED && isObjectEmpty(currentUserInfo)) {
      dispatch(getCurrentUserInfo());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline />
      <Suspense fallback="Loading">
        <SignalRConnectionContextProvider>
          <Router />
          <Toaster
            position="top-right"
            toastOptions={{
              style: {
                background: Colors.green,
                color: Colors.white
              }
            }}
          />
        </SignalRConnectionContextProvider>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;

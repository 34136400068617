import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

export class DateFormat {
  static date = 'YYYY-MM-DD';
  static time = 'HH:mm';
  static dateAndTime = `${DateFormat.date} ${DateFormat.time}`;
}

dayjs.extend(duration);

export const getDifferenceDurationTime = (firstDate: Date, secondDate: Date): duration.Duration =>
  dayjs.duration(getDifferenceTime(firstDate, secondDate));

export const getDifferenceTime = (firstDate: Date, secondDate: Date): number =>
  dayjs(secondDate).diff(firstDate);

export const toDateTimeFormat = (date?: Date) =>
  (date && dayjs(date).format(DateFormat.dateAndTime)) || '';
export const toDateFormat = (date?: Date) => (date && dayjs(date).format(DateFormat.date)) || '';
export const toTimeFormat = (date?: Date) => (date && dayjs(date).format(DateFormat.time)) || '';

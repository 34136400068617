import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'Store';

export type BreadcrumbItem = { to: string; translateKey?: string; label?: string };

interface BreadcrumbState {
  breadcrumbs: BreadcrumbItem[];
}

const initialState: BreadcrumbState = {
  breadcrumbs: []
};

const breadcrumbSlice = createSlice({
  name: 'breadcrumb',
  initialState: initialState,
  reducers: {
    setBreadcrumbs: (state, action) => {
      state.breadcrumbs = action.payload;
    },
    clearBreadcrumb: state => {
      state.breadcrumbs = [];
    }
  }
});

export const { setBreadcrumbs, clearBreadcrumb } = breadcrumbSlice.actions;
export const breadcrumbReducer = breadcrumbSlice.reducer;

const selectSelf = (state: RootState) => state.breadcrumb;

export const selectBreadcrumbs = createSelector(selectSelf, state => state.breadcrumbs);

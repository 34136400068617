import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'Store';
import {
  FormsDefaultValuesSettings,
  FormsDefaultValuesSettingsFreshStartForm,
  SentrySettings,
  configApi
} from 'Api';

export interface AppConfigState {
  sentrySettings: SentrySettings;
  formsDefaultValuesSettings: FormsDefaultValuesSettings;
}

const initialState: AppConfigState = {
  sentrySettings: {} as SentrySettings,
  formsDefaultValuesSettings: {
    freshStartForm: {} as FormsDefaultValuesSettingsFreshStartForm
  } as FormsDefaultValuesSettings
};

export const getAppConfig = createAsyncThunk('config/getAppConfig', async () => {
  const result = await configApi.configGetConfigGet();
  return result.data;
});

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAppConfig.fulfilled, (state, action) => {
      state.sentrySettings = action.payload.sentrySettings!;
      state.formsDefaultValuesSettings = action.payload.formsDefaultValuesSettings!;
    });
  }
});

export default configSlice.reducer;

const selectSelf = (state: RootState) => state.config;

export const selectSentrySettings = createSelector(selectSelf, state => state.sentrySettings);

export const selectFreshStartFormDefaultValues = createSelector(
  selectSelf,
  state => state.formsDefaultValuesSettings.freshStartForm
);

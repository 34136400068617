import { useTranslation } from 'react-i18next';
import { FormContainer } from 'react-hook-form-mui';
import { RegisterRequest } from 'Api';
import { StyledAuthFormContainer, StyledButton } from 'Components/Styled';
import { AcceptTermsAndPrivacyPolicyMessage } from 'Components';
import { AlertError } from 'Common/AlertError';
import {
  TextField,
  EmailField,
  CheckboxField,
  PasswordAndConfirmPasswordFields
} from 'Common/Form';
import { RegistrationSchema } from 'Common/Form/ValidationSchemas';
import { useAppForm } from 'Utils/customHooks';

const formFieldNames = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  password: 'password',
  confirmPassword: 'confirmPassword',
  acceptTermsAndPrivacyPolicy: 'acceptTermsAndPrivacyPolicy'
} as const;

interface RegistrationFormProps {
  onSubmit: (formData: RegisterRequest) => void;
  error: string;
  isSubmitSuccess: boolean;
}

export function RegistrationForm({ onSubmit, error, isSubmitSuccess }: RegistrationFormProps) {
  const { t } = useTranslation();
  const formContext = useAppForm<RegisterRequest>(RegistrationSchema);

  return !isSubmitSuccess ? (
    <FormContainer onSuccess={onSubmit} formContext={formContext}>
      <StyledAuthFormContainer>
        {error && <AlertError text={t(error)} />}

        <TextField name={formFieldNames.firstName} label={t('auth.firstName')} isRequired />

        <TextField name={formFieldNames.lastName} label={t('auth.lastName')} />

        <EmailField name={formFieldNames.email} />

        <PasswordAndConfirmPasswordFields formContext={formContext} />

        <CheckboxField
          name={formFieldNames.acceptTermsAndPrivacyPolicy}
          label={<AcceptTermsAndPrivacyPolicyMessage />}
        />

        <StyledButton type="submit" size="large">
          {t('auth.createAccount')}
        </StyledButton>
      </StyledAuthFormContainer>
    </FormContainer>
  ) : (
    <div>
      {t('auth.registrationMessagePart1')} {formContext.control._formValues.email}
      {t('auth.registrationMessagePart2')}
    </div>
  );
}

export default RegistrationForm;

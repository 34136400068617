import { useContext, useEffect } from 'react';
import { HubConnectionState } from '@microsoft/signalr';
import { SignalRConnectionContext } from './SignalRConnectionContext';

export function useOnSignalRMethod(methodName: string, callback: (...args: any[]) => void) {
  const { connection, state } = useContext(SignalRConnectionContext);

  useEffect(() => {
    if (state !== HubConnectionState.Connected) {
      return;
    }

    connection.on(methodName, callback);

    return () => {
      connection.off(methodName);
    };
  }, [connection, methodName, callback, state]);
}

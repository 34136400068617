import { Container } from '@mui/material';
import styled from 'styled-components';

namespace ApplicationWrapperConsts {
  export const horizontalPadding = '48px';
}

export const ApplicationWrapper = styled(Container)`
  flex: 1;
  min-height: 100svh;
  display: flex !important;
  flex-direction: column;

  && {
    padding-left: ${ApplicationWrapperConsts.horizontalPadding};
    padding-right: ${ApplicationWrapperConsts.horizontalPadding};
  }
`;

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  padding: 16px 0 16px;
  flex-direction: column;
`;

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import LockIcon from '@mui/icons-material/Lock';
import styled from 'styled-components';
import { Spacing } from 'Themes/spacing';
import { Colors } from 'Themes/colors';
import { useAppDispatch, useAppSelector } from 'Store';
import { useBoolean } from 'Utils/customHooks';
import UserAvatar from 'Common/UserAvatar';
import { AvatarConsts } from 'Common/UserAvatar/Avatar/Avatar.consts';
import ChangePasswordFormContainer from 'Auth/ChangePasswordPage/ChangePasswordFormContainer';
import { logout } from 'Auth/authSlice';
import { selectCurrentUserInfo, selectUserDisplayName } from 'User/userSlice';
import { ActionPosition, OptionsModal } from 'Components';
import MyAccountFormContainer from './MyAccountFormContainer';
import { StyledWrapper } from './UserProfileDropdown.styles';

const Options = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${Spacing.small};
`;

const Container = styled.div`
  position: relative;
`;

const TypographyDisplayName = styled(Typography)`
  text-wrap: nowrap;
`;

export const UserProfileDropdown = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [menuIsDisplayed, showMenu, hideMenu] = useBoolean();
  const [myAccountDisplayed, displayMyAccount, hideMyAccount] = useBoolean();
  const [changePasswordDisplayed, displayChangePassword, hideChangePassword] = useBoolean();

  const currentUserInfo = useAppSelector(selectCurrentUserInfo);
  const currentUserInfoDisplayName =
    useAppSelector(selectUserDisplayName) || AvatarConsts.fallbackUserName;

  const handleLogoutClick = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const handleDisplayMyAccount = useCallback(() => {
    hideMenu();
    displayMyAccount();
  }, [hideMenu, displayMyAccount]);

  const handleDisplayChangePassword = useCallback(() => {
    hideMenu();
    displayChangePassword();
  }, [hideMenu, displayChangePassword]);

  return (
    <Container>
      <StyledWrapper onClick={showMenu}>
        <TypographyDisplayName>{currentUserInfoDisplayName}</TypographyDisplayName>
        <UserAvatar
          displayName={currentUserInfoDisplayName}
          color={currentUserInfo.avatarColor}
          avatarUrl={currentUserInfo.avatar}
        />
      </StyledWrapper>

      {menuIsDisplayed && (
        <>
          <OptionsModal onClose={hideMenu}>
            <Options>
              <ActionPosition
                icon={<PersonIcon style={{ color: Colors.green }} />}
                text={t('userMenu.myAccount')}
                onClick={handleDisplayMyAccount}
              />
              <ActionPosition
                icon={<LockIcon style={{ color: Colors.green }} />}
                text={t('userMenu.changePassword')}
                onClick={handleDisplayChangePassword}
              />
              <ActionPosition
                icon={<LogoutIcon style={{ color: Colors.green }} />}
                text={t('userMenu.logOut')}
                onClick={handleLogoutClick}
              />
            </Options>
          </OptionsModal>
        </>
      )}

      {myAccountDisplayed && (
        <OptionsModal onClose={hideMyAccount}>
          <MyAccountFormContainer onClose={hideMyAccount} />
        </OptionsModal>
      )}

      {changePasswordDisplayed && (
        <OptionsModal onClose={hideChangePassword}>
          <ChangePasswordFormContainer onClose={hideChangePassword} />
        </OptionsModal>
      )}
    </Container>
  );
};

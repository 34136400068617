import { styled, css } from '@mui/system';
import { LivePreviewConsts } from './LivePreview.consts';

interface FullScreenComponentProps {
  fullscreenModeIsEnabled?: boolean;
}

export const StyledWrapper = styled('div')<FullScreenComponentProps>`
  width: ${LivePreviewConsts.fullSize};
  height: calc(100% - 45px);
  display: flex;
  // padding: 8px 8px 0; // TODO: https://mattermost.aprosystem.pl/infox/pl/y4a7rp3hqpnn8mi33zwh75kbsw
  flex-grow: 1;
  min-height: 5rem;
  justify-content: center;

  ${({ fullscreenModeIsEnabled }) =>
    fullscreenModeIsEnabled &&
    css`
      height: 100%;
      width: 100%;
      padding: 0;
    `}
`;

interface FullScreenIFrameComponentProps extends FullScreenComponentProps {
  settingsOpened?: boolean;
}

export const StyledIFrame = styled('iframe')<FullScreenIFrameComponentProps>`
  width: 100%;
  height: 100%;
  flex: 1;
  border-radius: 12px 12px 0 0;

  ${({ settingsOpened }) =>
    !settingsOpened &&
    css`
      border: none;
    `}

  ${({ fullscreenModeIsEnabled }) =>
    fullscreenModeIsEnabled &&
    css`
      border-radius: 0;
    `}
`;

import React, { memo, useMemo } from 'react';
import { toTimeFormat } from 'Utils/dateFormat';

interface TimeInfoProps {
  date: Date;
}

const TimeInfo = ({ date }: TimeInfoProps) => {
  const timeTomeDisplay = useMemo(() => toTimeFormat(date), [date]);

  return date && <span>{timeTomeDisplay}</span>;
};

export default memo(TimeInfo);

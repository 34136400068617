import { Dispatch } from 'react';
import axios, { AxiosError, AxiosInstance, InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import i18n from 'i18n';
import { Middleware, AnyAction } from '@reduxjs/toolkit';
import { toast } from 'react-hot-toast';
import { urls } from 'Router';
import { isNotProductionEnvironment } from 'Utils/environments';
import { logout } from 'Auth/authSlice';

export const axiosMiddleware: Middleware =
  (store: any) => (next: Dispatch<AnyAction>) => (action: any) => {
    setupInterceptors(store);
    return next(action);
  };

let axiosInterceptor: number | null = null;

const setupInterceptors = (store: any) => {
  if (!store) {
    return;
  }

  if (!!axiosInterceptor || axiosInterceptor === 0) {
    axios.interceptors.response.eject(axiosInterceptor);
  }

  axiosInterceptor = axios.interceptors.response.use(onResponse, onResponseError(store));
};

const setupInterceptors2 = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);

  return axiosInstance;
};

const doNothing = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => config;

const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  // console.info(`[request] [${JSON.stringify(config)}]`);
  return doNothing(config);
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  // console.error(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  // console.info(`[response] [${JSON.stringify(response)}]`);
  return response;
};

// const onResponseError = (error: AxiosError): Promise<AxiosError> => {
const onResponseError =
  (store: any): ((error: AxiosError) => Promise<AxiosError>) =>
  (error: AxiosError) => {
    const response = (error.response as AxiosResponse) || error; // TODO: why 'as AxiosResponse' is needed?

    if (
      error.config?.url?.toLocaleLowerCase().includes('auth/') ||
      error.config?.url?.toLocaleLowerCase().includes('logout')
    ) {
      return Promise.reject(error);
    }

    if (response?.status === 401) {
      store.dispatch(logout());
    } else if (response?.status === 403) {
      toast.error(i18n.t(response?.data?.message));
      window.location.href = `${window.location.protocol}//${window.location.host}${urls.forbiddenPage}`;
    } else {
      handleException(response);
    }

    if (isNotProductionEnvironment()) {
      console.error(response);
    }

    return Promise.reject(error);
  };

const handleException = (response: AxiosResponse): void => {
  let errorMessage = response?.data?.message;

  if (!errorMessage && response?.data?.errors) {
    errorMessage = JSON.stringify(response.data.errors); // TODO: może jakiś ładniejszy error message lub submission error z redux forma ?
  }

  toast.error(i18n.t(errorMessage));
};

import React, { memo } from 'react';

import { DateInfo } from '../DateInfo';
import { TimeInfo } from '../TimeInfo';

interface DateTimeInfoProps {
  date: Date;
}

const DateTimeInfo = ({ date }: DateTimeInfoProps) => (
  <span>
    <DateInfo date={date} /> <TimeInfo date={date} />
  </span>
);

export default memo(DateTimeInfo);

import { createContext, ReactNode, useEffect, useState, useSyncExternalStore } from 'react';
import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { getStateSnapshot, signalRConnection, subscribeStateChange } from './signalRConnection';

export const SignalRConnectionContext = createContext<{
  connection: HubConnection;
  state: HubConnectionState;
  // @ts-ignore
}>(null);

export function SignalRConnectionContextProvider({ children }: { children: ReactNode }) {
  const state = useSyncExternalStore(subscribeStateChange, getStateSnapshot);
  const [value, setValue] = useState({ connection: signalRConnection, state: state });

  useEffect(() => {
    setValue({ connection: signalRConnection, state: state });
  }, [state]);

  return (
    <SignalRConnectionContext.Provider value={value}>{children}</SignalRConnectionContext.Provider>
  );
}

import { object } from 'valibot'; // 0.63 kB
import { EmailSchema } from './EmailSchema';
import { PasswordSchema } from './PasswordSchema';
import { booleanRequired, stringRequired } from './schema.utils';

export const RegistrationSchema = object(
  {
    firstName: stringRequired,
    lastName: stringRequired,
    email: EmailSchema,
    password: PasswordSchema,
    confirmPassword: PasswordSchema,
    acceptTermsAndPrivacyPolicy: booleanRequired
  }
  // TODO: after Valibot implements new features
  // [
  //   custom(data => {
  //     console.log('data', data);

  //     const arePasswordsEqual = data.password === data.confirmPassword;

  //     if (arePasswordsEqual) {
  //       return true;
  //     }

  //     return false;
  //   }, 'The passwords do not match.')
  // ]
);

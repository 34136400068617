import type { ParticipantModel } from 'Api';
import { CircleImage } from './CircleImage';
import { Avatar } from './Avatar';
import { AvatarConsts } from './Avatar/Avatar.consts';

interface UserAvatarProps {
  displayName: string;
  color: string;
  avatarUrl?: ParticipantModel['avatar'];
  avatarSize?: string;
}

const UserAvatar = ({
  displayName,
  color,
  avatarUrl,
  avatarSize = AvatarConsts.defaultAvatarSize
}: UserAvatarProps) =>
  avatarUrl ? (
    <CircleImage
      src={avatarUrl}
      // TODO: Remove alt or add translation for it
      alt="User Avatar"
      size={avatarSize}
    />
  ) : (
    <Avatar displayName={displayName} color={color} size={avatarSize} />
  );

export default UserAvatar;

import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, MenuItem, Stack, Tooltip } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EnterFullscreenIcon from '@mui/icons-material/Fullscreen';
import ExitFullscreenIcon from '@mui/icons-material/FullscreenExit';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import PushPinIcon from '@mui/icons-material/PushPin';
import styled, { css } from 'styled-components';
import { useToggle } from 'usehooks-ts';
import trainerIcon from 'Assets/images/trainerIcon.svg';
import { Colors } from 'Themes/colors';
import { DeleteDesktopRequest, DeleteFreshStartDesktopRequest, DesktopModel } from 'Api';
import { useAppDispatch, useAppSelector } from 'Store';
import { selectLoginState, LoginState } from 'Auth/authSlice';
import { UserDisplayModel } from 'User/userDisplayModel';
import { openExternalUrl } from 'Utils/redirects';
import { useBoolean, useElementInFullscreen } from 'Utils/customHooks';
import ConfirmationDialog from 'Common/ConfirmationDialog/ConfirmationDialog';
import {
  ConnectToFreshStartDesktopButton,
  ConnectToMyDesktopButton,
  ConnectToParticipantDesktopButton
} from 'Common/ConnectToDesktopButtons';
import {
  CourseModel,
  deleteFreshStartDesktop,
  deleteMyDesktop,
  deleteParticipantDesktop,
  stopFreshStartDesktop,
  stopMyDesktop,
  stopParticipantDesktop
} from 'Courses/coursesSlice';
import { saveIsMachineBarPinned, selectIsMachineBarPinned } from 'User/userSlice';
import LivePreview from './LivePreview';
import UserInfo from './UserInfo';
import { ActionWrapper, PushPinButton, StyledCard } from './LivePreviewCard.styles';
import SettingsMenu from './SettingsMenu';

const StyledEnterFullscreenIcon = styled(EnterFullscreenIcon)`
  color: ${Colors.red} !important;
`;

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  gap: 10px;
  > svg {
    color: ${Colors.green};
  }
`;

const ActionButtons = styled(Stack)<{ settingsOpened: boolean }>`
  display: flex;
  flex-direction: row !important;
  flex-wrap: nowrap;

  ${({ settingsOpened }) =>
    settingsOpened &&
    css`
      & > *:last-child {
        margin-left: 16px;
      }
    `}
`;

const trainerAvatar = <img src={trainerIcon} alt="trainer icon" />;

interface LivePreviewCardProps {
  course: CourseModel;
  user: UserDisplayModel;
  desktop: DesktopModel;
  isFreshStartDesktop?: boolean;
  isParticipantDesktop?: boolean;
  isTrainerDesktop?: boolean;
  isTrainerDesktopPreview?: boolean;
  staticSize?: boolean;
  isHighlighted?: boolean;
}

const LivePreviewCard = ({
  course,
  user,
  desktop,
  isFreshStartDesktop = false,
  isParticipantDesktop = false,
  isTrainerDesktop = false,
  isTrainerDesktopPreview = false,
  staticSize,
  isHighlighted = false
}: LivePreviewCardProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [dialogIsDisplayed, displayDialog, hideDialog] = useBoolean();

  const cardRef = useRef<HTMLDivElement | null>(null);
  const { isFullscreenEnabled, toggleFullscreenMode } = useElementInFullscreen(cardRef);

  const isMachineBarPinned = useAppSelector(selectIsMachineBarPinned);
  const loginState = useAppSelector(selectLoginState);
  const isLogged = loginState === LoginState.LOGGED;

  const handleOpenPreviewOnNewTab = () => {
    const link = isTrainerDesktopPreview ? (desktop.viewLink as string) : (desktop.link as string);

    if (link) {
      openExternalUrl(link);
    }
  };

  const handleStopMachine = () => {
    if (isFreshStartDesktop) {
      dispatch(
        stopFreshStartDesktop({
          courseId: course.id as string,
          desktopInstanceId: desktop.instanceId as number
        })
      );
    } else if (isParticipantDesktop) {
      dispatch(
        stopParticipantDesktop({
          courseId: course.id as string,
          desktopInstanceId: desktop.instanceId as number,
          participantId: user.id as string
        })
      );
    } else {
      dispatch(
        stopMyDesktop({
          courseId: course.id as string,
          desktopInstanceId: desktop.instanceId as number
        })
      );
    }

    toggleSettings();
  };

  const handleRemoveMachine = () => {
    if (isFreshStartDesktop) {
      dispatch(
        deleteFreshStartDesktop({
          courseId: course.id
        } as DeleteFreshStartDesktopRequest)
      );
    } else if (isParticipantDesktop) {
      dispatch(
        deleteParticipantDesktop({
          courseId: course.id as string,
          desktopInstanceId: desktop.instanceId as number,
          participantId: user.id as string
        })
      );
    } else {
      dispatch(
        deleteMyDesktop({
          courseId: course.id,
          desktopInstanceId: desktop.instanceId
        } as DeleteDesktopRequest)
      );
    }

    hideDialog();
    toggleSettings();
  };

  const handleSaveIsMachineBarPinned = () => {
    dispatch(saveIsMachineBarPinned({ isMachineBarPinned: !isMachineBarPinned, isLogged }));
  };

  const FullscreenIcon = isFullscreenEnabled ? ExitFullscreenIcon : StyledEnterFullscreenIcon;

  const [settingsOpened, toggleSettings] = useToggle();

  return (
    <>
      <StyledCard
        ref={cardRef}
        fullscreenModeIsEnabled={isFullscreenEnabled}
        staticSize={staticSize}
        settingsOpened={settingsOpened}
      >
        <LivePreview
          fullscreenModeIsEnabled={isFullscreenEnabled}
          settingsOpened={settingsOpened}
          course={course}
          desktop={desktop}
          isParticipantDesktop={isParticipantDesktop}
          isTrainerDesktopPreview={isTrainerDesktopPreview}
          connectToDesktopButton={
            isFreshStartDesktop ? (
              <ConnectToFreshStartDesktopButton
                courseId={course.id as string}
                isFreshStartMachineReadyToClone={course.isFreshStartMachineReadyToClone}
              />
            ) : isParticipantDesktop ? (
              <ConnectToParticipantDesktopButton
                courseId={course.id as string}
                participantId={user.id as string}
                isHighlighted={isHighlighted}
              />
            ) : (
              !isTrainerDesktopPreview && (
                <ConnectToMyDesktopButton
                  courseId={course.id as string}
                  isHighlighted={isHighlighted}
                />
              )
            )
          }
        />

        <ActionWrapper fullScreen={isFullscreenEnabled} pinEnabled={isMachineBarPinned}>
          {isFullscreenEnabled ? (
            <PushPinButton pinEnabled={isMachineBarPinned} onClick={handleSaveIsMachineBarPinned}>
              <PushPinIcon />
            </PushPinButton>
          ) : (
            ''
          )}

          <UserInfo
            name={user.displayName}
            avatarColor={user.avatarColor}
            avatar={user.avatar}
            customAvatar={
              isTrainerDesktopPreview || isTrainerDesktop ? trainerAvatar : user.customAvatar
            }
          />

          <ActionButtons settingsOpened={settingsOpened}>
            <Tooltip title={t('FullScreen')}>
              <IconButton size="small" color="primary" onClick={toggleFullscreenMode}>
                <FullscreenIcon />
              </IconButton>
            </Tooltip>

            {!isFullscreenEnabled ? (
              <Tooltip title={t('OpenOnNewTab')}>
                <IconButton
                  size="small"
                  onClick={handleOpenPreviewOnNewTab}
                  disabled={!desktop || !desktop.instanceId}
                  sx={{ color: Colors.darkGrey }}
                >
                  <OpenInNewIcon />
                </IconButton>
              </Tooltip>
            ) : (
              ''
            )}

            {!isTrainerDesktopPreview && !isFullscreenEnabled ? (
              <SettingsMenu
                disabled={!desktop || !desktop.instanceId}
                open={settingsOpened}
                toggle={toggleSettings}
              >
                {desktop?.isRunning && (
                  <StyledMenuItem key={0} onClick={handleStopMachine}>
                    <RemoveCircleIcon />
                    {t('StopMachine')}
                  </StyledMenuItem>
                )}

                {desktop?.instanceId && (
                  <StyledMenuItem key={1} onClick={displayDialog}>
                    <DeleteIcon />
                    {t('RemoveMachine', { instanceName: desktop?.instanceName })}
                  </StyledMenuItem>
                )}
              </SettingsMenu>
            ) : (
              ''
            )}
          </ActionButtons>
        </ActionWrapper>
      </StyledCard>

      <ConfirmationDialog
        open={dialogIsDisplayed}
        content={t('RemoveMachineQuestion', { instanceName: desktop?.instanceName })}
        onNo={hideDialog}
        onYes={handleRemoveMachine}
      />
    </>
  );
};

export default LivePreviewCard;

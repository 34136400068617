import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Colors } from 'Themes/colors';
import banner_403 from 'Assets/images/banner_403.svg';
import { Link, SingleLine } from 'Components';
import {
  Wrapper,
  LeftColumn,
  ButtonLink,
  StyledMuiLink,
  StyledCloseIconButton
} from '../NotFoundPage/NotFoundPage';
import { StyledContainer } from '../NotFoundPage/NotFoundPage.styles';
import styled from 'styled-components';

const StyledImg = styled.img`
  max-height: 450px;
`;

const ForbiddenPage = () => {
  const { t } = useTranslation();

  const handleBackClick = () => window.history.back();

  return (
    <StyledContainer>
      <Wrapper>
        <LeftColumn>
          <Typography>{t('forbiddenPage.title')}</Typography>
          <Typography fontSize="164px" fontWeight={700} color={Colors.black}>
            403
          </Typography>
          <Typography>{t('forbiddenPage.firstLine')}</Typography>
          <SingleLine>
            <ButtonLink onClick={handleBackClick}>{t('forbiddenPage.backButton')}</ButtonLink>
            <Typography>{t('forbiddenPage.secondLine')}</Typography>
            <Link to="/" label={t('forbiddenPage.mainPage')} />
          </SingleLine>
          <SingleLine>
            <Typography>{t('forbiddenPage.thirdLine')}</Typography>
            <StyledMuiLink href="mailto:kontakt@treneo.pl">kontakt@treneo.pl</StyledMuiLink>
          </SingleLine>
        </LeftColumn>
        <StyledImg src={banner_403} alt="banner 403"></StyledImg>
        <StyledCloseIconButton onClose={handleBackClick} />
      </Wrapper>
    </StyledContainer>
  );
};

export default ForbiddenPage;

import { memo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, Card, Button, Skeleton } from '@mui/material';
import MonitorIcon from '@mui/icons-material/Monitor';
import styled, { css } from 'styled-components';
import { DesktopModel } from 'Api';
import { urls } from 'Router';
import { useAppSelector } from 'Store';
import { UserDisplayModel } from 'User/userDisplayModel';
import LivePreviewCard from 'Course/LivePreviewCard';
import { getUserDisplayName } from 'Utils/user';
import { Colors } from 'Themes/colors';
import { Typo } from 'Themes/typo';
import { Spacing } from 'Themes/spacing';
import { CourseModel, selectIsTrainerDesktopLoading } from './coursesSlice';
import Description from './Description';
import Dates from './Dates';
import MeetingLink from './MeetingLink';

const Column = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Details = styled(Column)<{ shouldWrapText?: boolean }>`
  align-items: flex-start;
  align-self: flex-start;
  flex-direction: column;
  padding-left: 53px;
  gap: ${Spacing.small};

  ${({ shouldWrapText }) =>
    shouldWrapText &&
    css`
      max-width: 400px;
    `}
`;

const TitleTypography = styled(Typography)`
  margin-bottom: 10px !important;
`;

const StyledCard = styled(Card)`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  border: 1px solid ${Colors.lineGrey};
  border-radius: 0;
  padding: 25px 0;

  & > * {
    flex: 1 !important;
  }
`;

const Divider = styled.div`
  max-width: 2px;
  min-height: 100px;
  background: ${Colors.lineGrey};
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface OngoingCourseCardProps {
  course: CourseModel;
  user: UserDisplayModel;
  isOngoing?: boolean;
  shouldSkipHighlighted?: boolean;
}

export function CourseCard({
  course,
  user,
  isOngoing = false,
  shouldSkipHighlighted = false
}: OngoingCourseCardProps) {
  const { t } = useTranslation();

  const isTrainerDesktopLoading = useAppSelector(state =>
    selectIsTrainerDesktopLoading(state, course.id as string)
  );

  const shouldDisplayMyDesktop = !!(user && (!course.isCompleted || course.myDesktop));

  const shouldDisplayCoursePanel = !!course.isTrainer;

  const shouldDisplayTrainerPreviewDesktop = !!(
    !isTrainerDesktopLoading &&
    !course.isTrainer &&
    course.whetherTrainerAllowedPreview &&
    course.trainerDesktop &&
    !course.isCompleted
  );

  const shouldDisplayTrainerDesktopMessage = !!(
    !course.isTrainer &&
    (!course.whetherTrainerAllowedPreview || !course.trainerDesktop) &&
    !course.isCompleted
  );

  const shouldDisplayDivider = !!(
    shouldDisplayMyDesktop &&
    (shouldDisplayCoursePanel ||
      shouldDisplayTrainerPreviewDesktop ||
      shouldDisplayTrainerDesktopMessage)
  );

  const shouldDisplayColumns = !!(
    shouldDisplayMyDesktop ||
    shouldDisplayCoursePanel ||
    shouldDisplayTrainerPreviewDesktop ||
    shouldDisplayTrainerDesktopMessage
  );

  return (
    <StyledCard>
      <Details shouldWrapText={shouldDisplayMyDesktop}>
        <TitleTypography variant={Typo.title}>{course.title}</TitleTypography>

        {!course.isDemoCourse ? (
          <>
            <Dates dates={course.dates} />

            <MeetingLink
              meetingLink={course.meetingLink}
              meetingPlatform={course.meetingPlatform}
            />
          </>
        ) : (
          ''
        )}

        <Description value={course.description} shouldShowAllValue={course.isDemoCourse} />
      </Details>

      {shouldDisplayColumns ? (
        <Column>
          {shouldDisplayMyDesktop ? (
            <LivePreviewCard
              staticSize
              course={course}
              user={user}
              desktop={course.myDesktop as DesktopModel}
              isTrainerDesktop={course.isTrainer}
              isHighlighted={(isOngoing && !course.isTrainer) || course.isDemoCourse}
            />
          ) : (
            ''
          )}
        </Column>
      ) : (
        ''
      )}

      {shouldDisplayDivider ? (
        <>
          <Divider />
        </>
      ) : (
        ''
      )}

      {/* TODO: może jakoś ładniej zrobić te if'y? teraz jest trochę nie czytelny kod */}

      {shouldDisplayColumns ? (
        <Column>
          {isTrainerDesktopLoading && course.whetherTrainerAllowedPreview ? (
            // TODO: loader styles
            <Skeleton
              variant="rounded"
              animation="wave"
              width={300}
              height={220}
              sx={{ ml: '10px', mr: '10px' }}
            />
          ) : shouldDisplayCoursePanel ? (
            <StyledLink
              to={
                course.hasFreshStart || course.isCompleted
                  ? urls.coursePage(course.id as string)
                  : urls.courseSettingsPage(course.id as string)
              }
            >
              <Button
                variant={
                  isOngoing ||
                  course.isDemoCourse ||
                  (!shouldSkipHighlighted && !course.hasFreshStart && !course.isCompleted)
                    ? 'contained'
                    : 'outlined'
                }
                startIcon={<MonitorIcon />}
                sx={{ mr: '10px' }}
              >
                {t('coursesPage.coursePanel')}
              </Button>
            </StyledLink>
          ) : shouldDisplayTrainerPreviewDesktop ? (
            <LivePreviewCard
              staticSize
              course={course}
              user={
                {
                  displayName: getUserDisplayName(course.trainer?.displayName),
                  avatar: course.trainer?.avatar
                } as UserDisplayModel
              }
              desktop={course.trainerDesktop as DesktopModel}
              isTrainerDesktop
              isTrainerDesktopPreview
            />
          ) : shouldDisplayTrainerDesktopMessage ? (
            <Typography textAlign="center" fontStyle="italic" style={{ maxWidth: '200px' }}>
              {t('coursesPage.trainerDidNotMakeHisVirtualComputerAvailable')}
            </Typography>
          ) : (
            ''
          )}
        </Column>
      ) : (
        ''
      )}
    </StyledCard>
  );
}

export default memo(CourseCard);

import type { ParticipantModel } from 'Api';
import { CourseModel } from 'Courses/coursesSlice';
import styled from 'styled-components';
import { Spacing } from 'Themes/spacing';
import ParticipantInfoCard from './ParticipantInfoCard';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 25px;
  margin-top: ${Spacing.medium};
`;

interface ParticipantInfoListProps {
  course: CourseModel;
  participantList: ParticipantModel[];
  trainer: ParticipantModel;
}

const ParticipantInfoList = ({ course, participantList, trainer }: ParticipantInfoListProps) => {
  const participantInfoCards = participantList.map((participant, participantIndex) => (
    <ParticipantInfoCard key={participantIndex} course={course} participant={participant} />
  ));

  return (
    <Grid>
      {participantInfoCards}

      <ParticipantInfoCard
        course={course}
        participant={trainer} // TODO: maybe better name because "participant={trainer}" sounds quite good
        isTrainerDesktop
      />
    </Grid>
  );
};

export default ParticipantInfoList;

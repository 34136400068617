import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'Store';
import {
  coursesApi,
  SaveWhetherTrainerAllowedPreviewRequest,
  CreateFreshStartFromOsTemplateRequest,
  CreateFreshStartFromStandaloneTemplateRequest,
  SaveIsFreshStartMachineReadyToCloneRequest,
  GetCourseResult,
  DeleteFreshStartDesktopRequest,
  DeleteDesktopRequest,
  SaveMachineIframeSizeRequest,
  CourseResult,
  CourseMyDesktop,
  CourseTrainerDesktop,
  DesktopModel,
  ParticipantModel,
  StopDesktopRequest,
  ConnectToMyDesktopRequest,
  ConnectToParticipantDesktopRequest,
  ConnectToFreshStartDesktopRequest,
  ConnectToTrainerDesktopRequest,
  StartParticipantDesktopRequest,
  CourseDates
} from 'Api';

interface KeyValuePair {
  key: string;
  value: any;
}

export interface ParticipantExtendedModel extends ParticipantModel {
  isDesktopLoading?: boolean;

  isDesktopStartPending?: boolean;
  isDesktopStopPending?: boolean;
  isDesktopDeletePending?: boolean;
}

export interface CourseModel extends CourseResult, GetCourseResult {
  myDesktop?: DesktopModel;
  trainerDesktop?: DesktopModel;
  freshStartDesktop?: DesktopModel;
  participants?: ParticipantExtendedModel[];

  isTrainerDesktopLoading?: boolean;

  isMyDesktopLoading?: boolean;

  isTrainerDesktopStartPending?: boolean;

  isMyDesktopStartPending?: boolean;
  isMyDesktopStopPending?: boolean;
  isMyDesktopDeletePending?: boolean;

  isFreshStartDesktopLoading?: boolean;

  isFreshStartDesktopStartPending?: boolean;
  isFreshStartDesktopStopPending?: boolean;
  isFreshStartDesktopDeletePending?: boolean;

  areParticipantsLoading?: boolean;

  isCompleted?: boolean;
}

export interface CoursesState {
  courses: Array<CourseModel>;
  areCoursesLoading: boolean;
  areMyDesktopsLoading: boolean;
  areTrainerDesktopsLoading: boolean;
}

export interface NewDesktopConnected {
  desktop: DesktopModel;
  userId: string;
}

const initialState: CoursesState = {
  courses: [],
  areCoursesLoading: false,
  areMyDesktopsLoading: false,
  areTrainerDesktopsLoading: false
};

export const getCourses = createAsyncThunk('courses/getCourses', async () => {
  const result = await coursesApi.coursesGet();
  return result.data;
});

export const getCourse = createAsyncThunk('courses/getCourse', async (courseId: string) => {
  const result = await coursesApi.coursesDetailsGet(courseId);
  return {
    courseId,
    courseDetails: result.data
  };
});

export const getCourseAdditionalData = createAsyncThunk(
  'courses/getCourseAdditionalData',
  async (courseId: string) => {
    const result = await coursesApi.coursesAdditionalDataGet(courseId);
    return {
      courseId,
      courseAdditionalData: result.data
    };
  }
);

export const getMyDesktop = createAsyncThunk('courses/getMyDesktop', async (courseId: string) => {
  const result = await coursesApi.coursesMyDesktopGet(courseId);
  return {
    courseId,
    myDesktop: result.data
  };
});

export const getTrainerDesktop = createAsyncThunk(
  'courses/getTrainerDesktop',
  async (courseId: string) => {
    const result = await coursesApi.coursesTrainerDesktopGet(courseId);
    return {
      courseId,
      trainerDesktop: result.data
    };
  }
);

export const getFreshStartDesktop = createAsyncThunk(
  'courses/getFreshStartDesktop',
  async (courseId: string) => {
    const result = await coursesApi.coursesFreshStartDesktopGet(courseId);
    return {
      courseId,
      freshStartDesktop: result.data
    };
  }
);

export const getCourseDesktops = createAsyncThunk(
  'courses/getCourseDesktops',
  async (courseId: string) => {
    const result = await coursesApi.coursesCourseDesktopsGet(courseId);
    return {
      courseId,
      myDesktop: result.data.myDesktop,
      trainerDesktop: result.data.trainerDesktop
    };
  }
);

export const getCoursesMyDesktops = createAsyncThunk(
  'courses/getCoursesMyDesktops',
  async (courseIds: string[]) => {
    const result = await coursesApi.coursesMyDesktopsGet(courseIds);
    return result.data;
  }
);

export const getCoursesTrainerDesktops = createAsyncThunk(
  'courses/getCoursesTrainerDesktops',
  async (courseIds: string[]) => {
    const result = await coursesApi.coursesTrainerDesktopsGet(courseIds);
    return result.data;
  }
);

export const getParticipants = createAsyncThunk(
  'courses/getParticipants',
  async (courseId: string) => {
    const result = await coursesApi.coursesParticipantsGet(courseId);
    return {
      courseId,
      participants: result.data
    };
  }
);

export const saveIsFreshStartMachineReadyToClone = createAsyncThunk(
  'courses/saveIsFreshStartMachineReadyToClone',
  async (request: SaveIsFreshStartMachineReadyToCloneRequest) => {
    await coursesApi.coursesSaveIsFreshStartMachineReadyToClonePost(request);
    return request;
  }
);

export const saveWhetherTrainerAllowedPreview = createAsyncThunk(
  'courses/saveWhetherTrainerAllowedPreview',
  async (request: SaveWhetherTrainerAllowedPreviewRequest) => {
    await coursesApi.coursesSaveWhetherTrainerAllowedPreviewPost(request);
    return request;
  }
);

export const saveMachineIframeSize = createAsyncThunk(
  'courses/saveMachineIframeSize',
  async (request: SaveMachineIframeSizeRequest) => {
    await coursesApi.coursesSaveMachineIframeSizePost(request);
    return request;
  }
);

export const createFreshStartFromOsTemplate = createAsyncThunk(
  'courses/createFreshStartFromOsTemplate',
  async (request: CreateFreshStartFromOsTemplateRequest) => {
    await coursesApi.coursesCreateFreshStartFromOsTemplatePost(request);
    return request;
  }
);

export const createFreshStartFromStandaloneTemplate = createAsyncThunk(
  'courses/createFreshStartFromStandaloneTemplate',
  async (request: CreateFreshStartFromStandaloneTemplateRequest) => {
    await coursesApi.coursesCreateFreshStartFromStandaloneTemplatePost(request);
    return request;
  }
);

export const deleteFreshStartDesktop = createAsyncThunk(
  'courses/deleteFreshStartDesktop',
  async (request: DeleteFreshStartDesktopRequest, thunkApi) => {
    await coursesApi.coursesDeleteFreshStartDesktopPost(request);
    await thunkApi.dispatch(getFreshStartDesktop(request.courseId as string));
    return request;
  }
);

export const deleteMyDesktop = createAsyncThunk(
  'courses/deleteMyDesktop',
  async (request: DeleteDesktopRequest, thunkApi) => {
    await coursesApi.coursesDeleteDesktopPost(request);
    await thunkApi.dispatch(getMyDesktop(request.courseId as string));
    return {
      courseId: request.courseId
    };
  }
);

export const deleteParticipantDesktop = createAsyncThunk(
  'courses/deleteParticipantDesktop',
  async ({
    courseId,
    desktopInstanceId,
    participantId
  }: {
    courseId: string;
    desktopInstanceId: number;
    participantId: string;
  }) => {
    await coursesApi.coursesDeleteDesktopPost({
      courseId,
      desktopInstanceId
    } as DeleteDesktopRequest);

    return {
      courseId,
      participantId
    };
  }
);

export const startParticipantDesktop = createAsyncThunk(
  'courses/startParticipantDesktop',
  async ({
    courseId,
    desktopInstanceId,
    participantId
  }: {
    courseId: string;
    desktopInstanceId: number;
    participantId: string;
  }) => {
    const result = await coursesApi.coursesStartParticipantDesktopPost({
      courseId,
      desktopInstanceId
    } as StartParticipantDesktopRequest);

    return {
      courseId,
      participantId,
      participantDesktop: result.data
    };
  }
);

export const stopParticipantDesktop = createAsyncThunk(
  'courses/stopParticipantDesktop',
  async ({
    courseId,
    desktopInstanceId,
    participantId
  }: {
    courseId: string;
    desktopInstanceId: number;
    participantId: string;
  }) => {
    await coursesApi.coursesStopDesktopPost({ courseId, desktopInstanceId } as StopDesktopRequest);

    return {
      courseId,
      participantId
    };
  }
);

export const stopMyDesktop = createAsyncThunk(
  'courses/stopMyDesktop',
  async ({ courseId, desktopInstanceId }: { courseId: string; desktopInstanceId: number }) => {
    await coursesApi.coursesStopDesktopPost({ courseId, desktopInstanceId } as StopDesktopRequest);
    return { courseId };
  }
);

export const stopFreshStartDesktop = createAsyncThunk(
  'courses/stopFreshStartDesktop',
  async ({ courseId, desktopInstanceId }: { courseId: string; desktopInstanceId: number }) => {
    await coursesApi.coursesStopDesktopPost({ courseId, desktopInstanceId } as StopDesktopRequest);
    return { courseId };
  }
);

export const connectToMyDesktop = createAsyncThunk(
  'courses/connectToMyDesktop',
  async (courseId: string) => {
    const result = await coursesApi.coursesConnectToMyDesktopPost({
      courseId
    } as ConnectToMyDesktopRequest);

    return {
      courseId,
      myDesktop: result.data
    };
  }
);

export const connectToFreshStartDesktop = createAsyncThunk(
  'courses/connectToFreshStartDesktop',
  async (courseId: string) => {
    const result = await coursesApi.coursesConnectToFreshStartDesktopPost({
      courseId
    } as ConnectToFreshStartDesktopRequest);
    return {
      courseId,
      freshStartDesktop: result.data
    };
  }
);

export const connectToTrainerDesktop = createAsyncThunk(
  'courses/connectToTrainerDesktop',
  async (courseId: string) => {
    const result = await coursesApi.coursesConnectToTrainerDesktopPost({
      courseId
    } as ConnectToTrainerDesktopRequest);
    return {
      courseId,
      trainerDesktop: result.data
    };
  }
);

export const connectToParticipantDesktop = createAsyncThunk(
  'courses/connectToParticipantDesktop',
  async (request: ConnectToParticipantDesktopRequest) => {
    const result = await coursesApi.coursesConnectToParticipantDesktopPost(request);
    return {
      courseId: request.courseId,
      participantId: request.participantId,
      participantDesktop: result.data
    };
  }
);

const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    clearCourses(state) {
      state.courses = [];
    },
    addParticipant(
      state,
      action: PayloadAction<{ participant: ParticipantModel; courseId: string }>
    ) {
      const course = getExistingCourse(state.courses, action.payload.courseId);

      if (course) {
        course.participants!.push({
          ...action.payload.participant,
          isDesktopStartPending: false,
          isDesktopStopPending: false,
          isDesktopDeletePending: false
        });
      }
    },
    newDesktopConnected(
      state,
      action: PayloadAction<{ model: NewDesktopConnected; courseId: string }>
    ) {
      const course = getExistingCourse(state.courses, action.payload.courseId);

      if (course) {
        const participant = course.participants?.find(x => x.id == action.payload.model.userId);
        if (participant) {
          participant.desktop = action.payload.model.desktop;
        }
      }
    },
    courseTrainerAllowedToPreview(
      state,
      action: PayloadAction<{ courseId: string; value: boolean }>
    ) {
      const course = getExistingCourse(state.courses, action.payload.courseId);

      if (course) {
        course.whetherTrainerAllowedPreview = action.payload.value;
      }
    },
    isFreshStartMachineReadyToClone(
      state,
      action: PayloadAction<{ courseId: string; value: boolean }>
    ) {
      const course = getExistingCourse(state.courses, action.payload.courseId);

      if (course) {
        course.isFreshStartMachineReadyToClone = action.payload.value;
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getCourses.pending, state => {
        state.areCoursesLoading = true;
      })
      .addCase(getCourses.fulfilled, (state, action) => {
        const courses = action.payload.courses as Array<CourseModel>;

        for (const course of courses) {
          course.isCompleted = isCourseCompleted(course);
        }

        state.courses = courses;
        state.areCoursesLoading = false;
      })
      .addCase(getCourses.rejected, state => {
        state.areCoursesLoading = false;
      })
      .addCase(getCourse.fulfilled, (state, action) => {
        const existingCourse = getExistingCourse(state.courses, action.payload.courseId);

        if (existingCourse) {
          const existingCourseIndex = state.courses.indexOf(existingCourse as CourseModel);
          state.courses[existingCourseIndex] = {
            ...action.payload.courseDetails
          };
        } else {
          state.courses.push(action.payload.courseDetails as CourseModel);
        }
      })
      .addCase(getCourseAdditionalData.fulfilled, (state, action) => {
        const existingCourse = getExistingCourse(state.courses, action.payload.courseId);

        if (existingCourse) {
          const existingCourseIndex = state.courses.indexOf(existingCourse as CourseModel);
          state.courses[existingCourseIndex] = {
            ...existingCourse,
            ...action.payload.courseAdditionalData
          };
        }
      })
      .addCase(getMyDesktop.pending, (state, action) => {
        changeCourseValues(state, action.meta.arg, [{ key: 'isMyDesktopLoading', value: true }]);
      })
      .addCase(getMyDesktop.fulfilled, (state, action) => {
        changeCourseValues(state, action.payload.courseId, [
          { key: 'myDesktop', value: action.payload.myDesktop },
          { key: 'isMyDesktopLoading', value: false }
        ]);
      })
      .addCase(getMyDesktop.rejected, (state, action) => {
        changeCourseValues(state, action.meta.arg, [{ key: 'isMyDesktopLoading', value: false }]);
      })
      .addCase(getTrainerDesktop.pending, (state, action) => {
        changeCourseValues(state, action.meta.arg, [
          { key: 'isTrainerDesktopLoading', value: true }
        ]);
      })
      .addCase(getTrainerDesktop.fulfilled, (state, action) => {
        changeCourseValues(state, action.payload.courseId, [
          { key: 'trainerDesktop', value: action.payload.trainerDesktop },
          { key: 'isTrainerDesktopLoading', value: false }
        ]);
      })
      .addCase(getTrainerDesktop.rejected, (state, action) => {
        changeCourseValues(state, action.meta.arg, [
          { key: 'isTrainerDesktopLoading', value: false }
        ]);
      })
      .addCase(getFreshStartDesktop.pending, (state, action) => {
        changeCourseValues(state, action.meta.arg, [
          { key: 'isFreshStartDesktopLoading', value: true }
        ]);
      })
      .addCase(getFreshStartDesktop.fulfilled, (state, action) => {
        changeCourseValues(state, action.payload.courseId, [
          { key: 'freshStartDesktop', value: action.payload.freshStartDesktop },
          { key: 'isFreshStartDesktopLoading', value: false }
        ]);
      })
      .addCase(getFreshStartDesktop.rejected, (state, action) => {
        changeCourseValues(state, action.meta.arg, [
          { key: 'isFreshStartDesktopLoading', value: false }
        ]);
      })
      .addCase(getCourseDesktops.pending, (state, action) => {
        changeCourseValues(state, action.meta.arg, [
          { key: 'isMyDesktopLoading', value: true },
          { key: 'isTrainerDesktopLoading', value: true }
        ]);
      })
      .addCase(getCourseDesktops.fulfilled, (state, action) => {
        changeCourseValues(state, action.payload.courseId, [
          { key: 'myDesktop', value: action.payload.myDesktop },
          { key: 'trainerDesktop', value: action.payload.trainerDesktop },
          { key: 'isMyDesktopLoading', value: false },
          { key: 'isTrainerDesktopLoading', value: false }
        ]);
      })
      .addCase(getCourseDesktops.rejected, (state, action) => {
        changeCourseValues(state, action.meta.arg, [
          { key: 'isMyDesktopLoading', value: false },
          { key: 'isTrainerDesktopLoading', value: false }
        ]);
      })
      .addCase(getCoursesMyDesktops.fulfilled, (state, action) => {
        for (const courseDesktop of action.payload.myDesktops as CourseMyDesktop[]) {
          changeCourseValues(state, courseDesktop.courseId, [
            { key: 'myDesktop', value: courseDesktop.myDesktop }
          ]);
        }
      })
      .addCase(getCoursesTrainerDesktops.fulfilled, (state, action) => {
        for (const courseDesktop of action.payload.trainerDesktops as CourseTrainerDesktop[]) {
          changeCourseValues(state, courseDesktop.courseId, [
            { key: 'trainerDesktop', value: courseDesktop.trainerDesktop }
          ]);
        }
      })
      .addCase(getParticipants.pending, (state, action) => {
        changeCourseValues(state, action.meta.arg, [
          { key: 'areParticipantsLoading', value: true }
        ]);
      })
      .addCase(getParticipants.fulfilled, (state, action) => {
        changeCourseValues(state, action.payload.courseId, [
          { key: 'participants', value: action.payload.participants },
          { key: 'areParticipantsLoading', value: false }
        ]);
      })
      .addCase(getParticipants.rejected, (state, action) => {
        changeCourseValues(state, action.meta.arg, [
          { key: 'areParticipantsLoading', value: false }
        ]);
      })
      .addCase(saveIsFreshStartMachineReadyToClone.fulfilled, (state, action) => {
        changeCourseValues(state, action.payload.courseId, [
          {
            key: 'isFreshStartMachineReadyToClone',
            value: action.payload.isFreshStartMachineReadyToClone
          }
        ]);
      })
      .addCase(saveWhetherTrainerAllowedPreview.fulfilled, (state, action) => {
        changeCourseValues(state, action.payload.courseId, [
          {
            key: 'whetherTrainerAllowedPreview',
            value: action.payload.whetherTrainerAllowedPreview
          }
        ]);
      })
      .addCase(saveMachineIframeSize.fulfilled, (state, action) => {
        changeCourseValues(state, action.payload.courseId, [
          {
            key: 'machineIframeSize',
            value: action.payload.machineIframeSize
          }
        ]);
      })
      .addCase(createFreshStartFromOsTemplate.fulfilled, (state, action) => {
        setHasFreshStart(state, action.payload.courseId as string, true);
      })
      .addCase(createFreshStartFromStandaloneTemplate.fulfilled, (state, action) => {
        setHasFreshStart(state, action.payload.courseId as string, true);
      })
      .addCase(deleteParticipantDesktop.pending, (state, action) => {
        changeParticipantValues(
          state,
          action.meta.arg.courseId as string,
          action.meta.arg.participantId as string,
          [{ key: 'isDesktopDeletePending', value: true }]
        );
      })
      .addCase(deleteParticipantDesktop.fulfilled, (state, action) => {
        changeParticipantValues(
          state,
          action.payload.courseId as string,
          action.payload.participantId as string,
          [
            { key: 'isDesktopDeletePending', value: false },
            { key: 'desktop', value: null }
          ]
        );
      })
      .addCase(deleteParticipantDesktop.rejected, (state, action) => {
        changeParticipantValues(
          state,
          action.meta.arg.courseId as string,
          action.meta.arg.participantId as string,
          [{ key: 'isDesktopDeletePending', value: false }]
        );
      })
      .addCase(deleteMyDesktop.pending, (state, action) => {
        changeCourseValues(state, action.meta.arg.courseId as string, [
          { key: 'isMyDesktopDeletePending', value: true }
        ]);
      })
      .addCase(deleteMyDesktop.fulfilled, (state, action) => {
        changeCourseValues(state, action.payload.courseId, [
          { key: 'isMyDesktopDeletePending', value: false },
          { key: 'myDesktop', value: null }
        ]);
      })
      .addCase(deleteMyDesktop.rejected, (state, action) => {
        changeCourseValues(state, action.meta.arg.courseId as string, [
          { key: 'isMyDesktopDeletePending', value: false }
        ]);
      })
      .addCase(deleteFreshStartDesktop.pending, (state, action) => {
        changeCourseValues(state, action.meta.arg.courseId as string, [
          { key: 'isFreshStartDesktopDeletePending', value: true }
        ]);

        const existingCourse = getExistingCourse(state.courses, action.meta.arg.courseId as string);

        if (existingCourse?.freshStartDesktop) {
          existingCourse.freshStartDesktop.isRunning = false;
        }
      })
      .addCase(deleteFreshStartDesktop.fulfilled, (state, action) => {
        changeCourseValues(state, action.payload.courseId, [
          { key: 'isFreshStartDesktopDeletePending', value: false },
          {
            key: 'hasFreshStart',
            value: false
          },
          {
            key: 'isFreshStartMachineReadyToClone',
            value: false
          }
        ]);
      })
      .addCase(deleteFreshStartDesktop.rejected, (state, action) => {
        changeCourseValues(state, action.meta.arg.courseId as string, [
          { key: 'isFreshStartDesktopDeletePending', value: false }
        ]);
      })
      .addCase(stopParticipantDesktop.pending, (state, action) => {
        changeParticipantValues(
          state,
          action.meta.arg.courseId as string,
          action.meta.arg.participantId as string,
          [{ key: 'isDesktopStopPending', value: true }]
        );

        const existingCourse = getExistingCourse(state.courses, action.meta.arg.courseId as string);

        if (existingCourse) {
          const existingParticipant = getExistingParticipant(
            state.courses,
            action.meta.arg.courseId as string,
            action.meta.arg.participantId as string
          );

          if (existingParticipant?.desktop) {
            existingParticipant.desktop.isRunning = false;
          }
        }
      })
      .addCase(stopParticipantDesktop.fulfilled, (state, action) => {
        changeParticipantValues(
          state,
          action.payload.courseId as string,
          action.payload.participantId as string,
          [{ key: 'isDesktopStopPending', value: false }]
        );
      })
      .addCase(stopParticipantDesktop.rejected, (state, action) => {
        changeParticipantValues(
          state,
          action.meta.arg.courseId as string,
          action.meta.arg.participantId as string,
          [{ key: 'isDesktopStopPending', value: false }]
        );
      })
      .addCase(stopMyDesktop.pending, (state, action) => {
        changeCourseValues(state, action.meta.arg.courseId as string, [
          { key: 'isMyDesktopStopPending', value: true }
        ]);

        const existingCourse = getExistingCourse(state.courses, action.meta.arg.courseId as string);

        if (existingCourse?.myDesktop) {
          existingCourse.myDesktop.isRunning = false;
        }
      })
      .addCase(stopMyDesktop.fulfilled, (state, action) => {
        changeCourseValues(state, action.payload.courseId, [
          { key: 'isMyDesktopStopPending', value: false }
        ]);
      })
      .addCase(stopMyDesktop.rejected, (state, action) => {
        changeCourseValues(state, action.meta.arg.courseId as string, [
          { key: 'isMyDesktopStopPending', value: false }
        ]);
      })
      .addCase(stopFreshStartDesktop.pending, (state, action) => {
        changeCourseValues(state, action.meta.arg.courseId as string, [
          { key: 'isFreshStartDesktopStopPending', value: true }
        ]);

        const existingCourse = getExistingCourse(state.courses, action.meta.arg.courseId as string);

        if (existingCourse?.freshStartDesktop) {
          existingCourse.freshStartDesktop.isRunning = false;
        }
      })
      .addCase(stopFreshStartDesktop.fulfilled, (state, action) => {
        changeCourseValues(state, action.payload.courseId, [
          { key: 'isFreshStartDesktopStopPending', value: false }
        ]);
      })
      .addCase(stopFreshStartDesktop.rejected, (state, action) => {
        changeCourseValues(state, action.meta.arg.courseId as string, [
          { key: 'isFreshStartDesktopStopPending', value: false }
        ]);
      })
      .addCase(connectToMyDesktop.pending, (state, action) => {
        changeCourseValues(state, action.meta.arg, [
          { key: 'isMyDesktopStartPending', value: true }
        ]);
      })
      .addCase(connectToMyDesktop.fulfilled, (state, action) => {
        changeCourseValues(state, action.payload.courseId, [
          { key: 'myDesktop', value: action.payload.myDesktop },
          { key: 'isMyDesktopStartPending', value: false }
        ]);
      })
      .addCase(connectToMyDesktop.rejected, (state, action) => {
        changeCourseValues(state, action.meta.arg, [
          { key: 'isMyDesktopStartPending', value: false }
        ]);
      })
      .addCase(connectToFreshStartDesktop.pending, (state, action) => {
        changeCourseValues(state, action.meta.arg, [
          { key: 'isFreshStartDesktopStartPending', value: true }
        ]);
      })
      .addCase(connectToFreshStartDesktop.fulfilled, (state, action) => {
        changeCourseValues(state, action.payload.courseId, [
          { key: 'freshStartDesktop', value: action.payload.freshStartDesktop },
          { key: 'isFreshStartDesktopStartPending', value: false }
        ]);
      })
      .addCase(connectToFreshStartDesktop.rejected, (state, action) => {
        changeCourseValues(state, action.meta.arg, [
          { key: 'isFreshStartDesktopStartPending', value: false }
        ]);
      })
      .addCase(connectToTrainerDesktop.pending, (state, action) => {
        changeCourseValues(state, action.meta.arg, [
          { key: 'isTrainerDesktopStartPending', value: true }
        ]);
      })
      .addCase(connectToTrainerDesktop.fulfilled, (state, action) => {
        changeCourseValues(state, action.payload.courseId, [
          { key: 'trainerDesktop', value: action.payload.trainerDesktop },
          { key: 'isTrainerDesktopStartPending', value: false }
        ]);
      })
      .addCase(connectToTrainerDesktop.rejected, (state, action) => {
        changeCourseValues(state, action.meta.arg, [
          { key: 'isTrainerDesktopStartPending', value: false }
        ]);
      })
      .addCase(connectToParticipantDesktop.pending, (state, action) => {
        changeParticipantValues(state, action.meta.arg.courseId, action.meta.arg.participantId, [
          { key: 'isDesktopStartPending', value: true }
        ]);
      })
      .addCase(connectToParticipantDesktop.fulfilled, (state, action) => {
        // TODO: czy w akcjach fulfilled trzeba sprawdzać czy coś istnieje? kurs, uczestnik itp.? czy jak już dojdzie kod do tego momentu to jest pewne że istnieje?

        changeParticipantValues(state, action.payload.courseId, action.payload.participantId, [
          { key: 'desktop', value: action.payload.participantDesktop },
          { key: 'isDesktopStartPending', value: false }
        ]);
      })
      .addCase(connectToParticipantDesktop.rejected, (state, action) => {
        changeParticipantValues(state, action.meta.arg.courseId, action.meta.arg.participantId, [
          { key: 'isDesktopStartPending', value: false }
        ]);
      })
      .addCase(startParticipantDesktop.pending, (state, action) => {
        changeParticipantValues(state, action.meta.arg.courseId, action.meta.arg.participantId, [
          { key: 'isDesktopStartPending', value: true }
        ]);
      })
      .addCase(startParticipantDesktop.fulfilled, (state, action) => {
        // TODO: czy w akcjach fulfilled trzeba sprawdzać czy coś istnieje? kurs, uczestnik itp.? czy jak już dojdzie kod do tego momentu to jest pewne że istnieje?

        changeParticipantValues(state, action.payload.courseId, action.payload.participantId, [
          { key: 'desktop', value: action.payload.participantDesktop },
          { key: 'isDesktopStartPending', value: false }
        ]);
      })
      .addCase(startParticipantDesktop.rejected, (state, action) => {
        changeParticipantValues(state, action.meta.arg.courseId, action.meta.arg.participantId, [
          { key: 'isDesktopStartPending', value: false }
        ]);
      });
  }
});

export const {
  clearCourses,
  addParticipant,
  newDesktopConnected,
  courseTrainerAllowedToPreview,
  isFreshStartMachineReadyToClone
} = coursesSlice.actions;

export default coursesSlice.reducer;

const selectSelf = (state: RootState): CoursesState => state.courses;
const getId = (_state: RootState, id: string) => id;
const getParticipantId = (_state: RootState, _id: string, participantId: string) => participantId;

export const selectAreCoursesLoading = createSelector(
  selectSelf,
  (state: CoursesState): boolean => state.areCoursesLoading
);

const selectCourses = createSelector(
  selectSelf,
  (state: CoursesState): Array<CourseModel> => state.courses
);

export const selectCoursesIds = createSelector(
  selectSelf,
  selectCourses,
  (state: CoursesState, courses: Array<CourseModel>): Array<string> => {
    return courses.map((course: CourseModel) => course.id as string);
  }
);

export const selectScheduledCourses = createSelector(
  selectSelf,
  (state: CoursesState): Array<CourseModel> =>
    state.courses
      ?.filter((course: CourseModel) => !course.isDemoCourse)
      .filter((course: CourseModel) => {
        if (!course.dates) {
          return true;
        }

        const hasAnyOngoingDate = hasCourseAnyOngoingDate(course.dates);
        const hasAnyScheduleDate = hasCourseScheduleDate(course.dates);

        return !hasAnyOngoingDate && hasAnyScheduleDate;
      })
      .reverse()
);

export const selectOngoingCourses = createSelector(
  selectSelf,
  (state: CoursesState): Array<CourseModel> =>
    state.courses
      ?.filter((course: CourseModel) => !course.isDemoCourse)
      .filter((course: CourseModel) => {
        if (!course.dates) {
          return false;
        }

        const hasAnyOngoingDate = hasCourseAnyOngoingDate(course.dates);

        return hasAnyOngoingDate;
      })
      .reverse()
);

export const selectCompletedCourses = createSelector(
  selectSelf,
  (state: CoursesState): Array<CourseModel> =>
    state.courses
      ?.filter((course: CourseModel) => !course.isDemoCourse)
      .filter((course: CourseModel) => isCourseCompleted(course))
      .reverse()
);

export const selectDemoCourse = createSelector(
  selectSelf,
  (state: CoursesState): CourseModel | undefined | null =>
    state.courses.find((course: CourseModel) => course.isDemoCourse)
);

export const selectCourse = createSelector(
  selectSelf,
  getId,
  (state: CoursesState, id: string): CourseModel | undefined | null =>
    state.courses.find((course: CourseModel) => course.id === id)
);

export const selectMyDesktopLink = createSelector(
  selectSelf,
  getId,
  (state: CoursesState, id: string): string | undefined | null =>
    state.courses.find((course: CourseModel) => course.id === id)?.myDesktop?.link
);

export const selectFreshStartDesktopLink = createSelector(
  selectSelf,
  getId,
  (state: CoursesState, id: string): string | undefined | null =>
    state.courses.find((course: CourseModel) => course.id === id)?.freshStartDesktop?.link
);

export const selectParticipantDesktopViewLink = createSelector(
  [selectSelf, getId, getParticipantId],
  (state: CoursesState, courseId: string, participantId: string): string | undefined | null => {
    const existingParticipant = getExistingParticipant(
      state.courses,
      courseId as string,
      participantId as string
    );

    if (existingParticipant) {
      return existingParticipant.desktop?.viewLink;
    }

    return null;
  }
);

export const selectIsMyDesktopLoading = createSelector(
  selectSelf,
  getId,
  (state: CoursesState, id: string): boolean =>
    !!state.courses.find((course: CourseModel) => course.id === id)?.isMyDesktopLoading
);

export const selectIsTrainerDesktopLoading = createSelector(
  selectSelf,
  getId,
  (state: CoursesState, id: string): boolean =>
    !!state.courses.find((course: CourseModel) => course.id === id)?.isTrainerDesktopLoading
);

export const selectIsMyDesktopStartPending = createSelector(
  selectSelf,
  getId,
  (state: CoursesState, id: string): boolean =>
    !!state.courses.find((course: CourseModel) => course.id === id)?.isMyDesktopStartPending
);

export const selectIsMyDesktopStopPending = createSelector(
  selectSelf,
  getId,
  (state: CoursesState, id: string): boolean =>
    !!state.courses.find((course: CourseModel) => course.id === id)?.isMyDesktopStopPending
);

export const selectIsMyDesktopDeletePending = createSelector(
  selectSelf,
  getId,
  (state: CoursesState, id: string): boolean =>
    !!state.courses.find((course: CourseModel) => course.id === id)?.isMyDesktopDeletePending
);

export const selectIsFreshStartDesktopStartPending = createSelector(
  selectSelf,
  getId,
  (state: CoursesState, id: string): boolean =>
    !!state.courses.find((course: CourseModel) => course.id === id)?.isFreshStartDesktopStartPending
);

export const selectIsFreshStartDesktopStopPending = createSelector(
  selectSelf,
  getId,
  (state: CoursesState, id: string): boolean =>
    !!state.courses.find((course: CourseModel) => course.id === id)?.isFreshStartDesktopStopPending
);

export const selectIsFreshStartDesktopDeletePending = createSelector(
  selectSelf,
  getId,
  (state: CoursesState, id: string): boolean =>
    !!state.courses.find((course: CourseModel) => course.id === id)
      ?.isFreshStartDesktopDeletePending
);

export const selectIsFreshStartDesktopLoading = createSelector(
  selectSelf,
  getId,
  (state: CoursesState, id: string): boolean =>
    !!state.courses.find((course: CourseModel) => course.id === id)?.isFreshStartDesktopLoading
);

export const selectIsTrainerDesktopStartPending = createSelector(
  selectSelf,
  getId,
  (state: CoursesState, id: string): boolean =>
    !!state.courses.find((course: CourseModel) => course.id === id)?.isTrainerDesktopStartPending
);

export const selectIsParticipantDesktopStartPending = createSelector(
  selectSelf,
  getId,
  getParticipantId,
  (state: CoursesState, courseId: string, participantId: string): boolean => {
    const existingParticipant = getExistingParticipant(
      state.courses,
      courseId as string,
      participantId as string
    );

    if (existingParticipant) {
      return !!existingParticipant.isDesktopStartPending;
    }

    return false;
  }
);

export const selectIsParticipantDesktopStopPending = createSelector(
  selectSelf,
  getId,
  getParticipantId,
  (state: CoursesState, courseId: string, participantId: string): boolean => {
    const existingParticipant = getExistingParticipant(
      state.courses,
      courseId as string,
      participantId as string
    );

    if (existingParticipant) {
      return !!existingParticipant.isDesktopStopPending;
    }

    return false;
  }
);

export const selectIsParticipantDesktopDeletePending = createSelector(
  selectSelf,
  getId,
  getParticipantId,
  (state: CoursesState, courseId: string, participantId: string): boolean => {
    const existingParticipant = getExistingParticipant(
      state.courses,
      courseId as string,
      participantId as string
    );

    if (existingParticipant) {
      return !!existingParticipant.isDesktopDeletePending;
    }

    return false;
  }
);

export const selectAreParticipantsLoading = createSelector(
  selectSelf,
  getId,
  (state: CoursesState, id: string): boolean =>
    !!state.courses.find((course: CourseModel) => course.id === id)?.areParticipantsLoading
);

function getExistingCourse(
  courses: CourseModel[],
  courseId: string
): CourseModel | undefined | null {
  return courses.find(course => course.id === courseId);
}

function getExistingParticipant(
  courses: CourseModel[],
  courseId: string,
  participantId: string
): ParticipantExtendedModel | undefined | null {
  const existingCourse = getExistingCourse(courses, courseId as string);

  if (existingCourse) {
    if (existingCourse?.participants?.length) {
      return existingCourse.participants.find(participant => participant.id === participantId);
    }
  }

  return null;
}

function setHasFreshStart(
  state: RootState,
  courseId: string | undefined | null,
  value: boolean
): void {
  const existingCourse = getExistingCourse(state.courses, courseId as string);

  if (existingCourse) {
    existingCourse.hasFreshStart = value;
  }
}

function changeCourseValues(
  state: RootState,
  courseId: string | undefined | null,
  keyValues: KeyValuePair[]
): void {
  const existingCourse = getExistingCourse(state.courses, courseId as string);

  if (existingCourse) {
    for (const keyValue of keyValues) {
      existingCourse[keyValue.key as keyof typeof existingCourse] = keyValue.value;
    }
  }
}

function changeParticipantValues(
  state: RootState,
  courseId: string | undefined | null,
  participantId: string | undefined | null,
  keyValues: KeyValuePair[]
): void {
  const existingParticipant = getExistingParticipant(
    state.courses,
    courseId as string,
    participantId as string
  );

  if (existingParticipant) {
    for (const keyValue of keyValues) {
      existingParticipant[keyValue.key as keyof typeof existingParticipant] = keyValue.value;
    }
  }
}

function isCourseCompleted(course: CourseModel): boolean {
  if (!course.dates) {
    return false;
  }

  const hasAnyOngoingDate = hasCourseAnyOngoingDate(course.dates);
  const hasAnyScheduleDate = hasCourseScheduleDate(course.dates);
  const hasAnyCompletedDate = hasCourseAnyCompletedDate(course.dates);

  return !hasAnyOngoingDate && !hasAnyScheduleDate && hasAnyCompletedDate;
}

function hasCourseAnyOngoingDate(dates: CourseDates[]): boolean {
  const todayEndOfDay = getEndOfToday();

  return !!dates.find(
    x =>
      new Date(x.startDateTime as string) <= todayEndOfDay &&
      new Date(x.endDateTime as string) >= todayEndOfDay
  );
}

function hasCourseScheduleDate(dates: CourseDates[]): boolean {
  const todayEndOfDay = getEndOfToday();

  return !!dates.find(x => new Date(x.startDateTime as string) > todayEndOfDay);
}

function hasCourseAnyCompletedDate(dates: CourseDates[]): boolean {
  const todayStartOfDay = getStartOfToday();

  return !!dates.find(x => new Date(x.endDateTime as string) < todayStartOfDay);
}

function getStartOfToday(): Date {
  const nowStartOfDay = new Date();
  nowStartOfDay.setUTCHours(0, 0, 0, 0);

  return nowStartOfDay;
}

function getEndOfToday(): Date {
  const todayEndOfDay = new Date();
  todayEndOfDay.setUTCHours(24, 59, 59, 99);

  return todayEndOfDay;
}

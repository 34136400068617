import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Skeleton, Typography } from '@mui/material';
import styled from 'styled-components';
import { Spacing } from 'Themes/spacing';
import { Typo } from 'Themes/typo';
import { ParticipantModel, SaveMachineIframeSizeRequest } from 'Api';
import { urls } from 'Router';
import { useAppDispatch, useAppSelector } from 'Store';
import { AvatarConsts } from 'Common/UserAvatar/Avatar/Avatar.consts';
import { selectCurrentUserInfo, selectUserDisplayName } from 'User/userSlice';
import { UserDisplayModel } from 'User/userDisplayModel';
import {
  deleteParticipantDesktop,
  getCourse,
  getCourseAdditionalData,
  getFreshStartDesktop,
  getMyDesktop,
  getParticipants,
  saveMachineIframeSize,
  selectAreParticipantsLoading,
  selectCourse,
  startParticipantDesktop,
  stopParticipantDesktop
} from 'Courses/coursesSlice';
import { breadcrumbs as coursesBreadcrumbs } from 'Courses/CoursesPage';
import { useBreadcrumbs } from 'Breadcrumb';
import ListModeActions from './ParticipantList/ListModeActions';
import { useListMode } from './ParticipantList/ParticipantList.hooks';
import { useJoinCourseGroup } from './useJoinCourseGroup';
import { SettingsButtonModal } from './SettingsButtonModal';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${Spacing.medium};
`;

const LeftButtonsContainer = styled.div`
  display: flex;
  gap: ${Spacing.small};
`;

export function CoursePage() {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [listMode, ParticipantList, setListMode] = useListMode();

  const [columns, setColumns] = useState<number>(6);

  const handleMachineIframeSize = (columns: number) => setColumns(columns);

  useJoinCourseGroup(id as string);

  const course = useAppSelector(state => selectCourse(state, id as string));

  const items = useMemo(
    () => [
      ...coursesBreadcrumbs,
      {
        label: course?.title,
        to: urls.coursePage(id as string)
      }
    ],
    [id, course?.title]
  );

  useBreadcrumbs(items);

  const areParticipantsLoading = useAppSelector(state =>
    selectAreParticipantsLoading(state, id as string)
  );

  const currentUserInfo = useAppSelector(selectCurrentUserInfo);
  const currentUserInfoDisplayName =
    useAppSelector(selectUserDisplayName) || AvatarConsts.fallbackUserName;

  const currentUserModel = {
    id: currentUserInfo.id,
    displayName: currentUserInfoDisplayName,
    avatarColor: currentUserInfo.avatarColor,
    avatar: currentUserInfo.avatar
  } as UserDisplayModel;

  const joinLink = `${location.protocol}//${location.host}${urls.joinToCoursePage(
    course?.joinCode as string
  )}`;

  const handleStartParticipantsMachines = useCallback(() => {
    for (const participant of course?.participants || []) {
      if (participant.desktop?.instanceId && !participant.desktop?.isRunning) {
        dispatch(
          startParticipantDesktop({
            courseId: id!,
            desktopInstanceId: participant.desktop?.instanceId,
            participantId: participant.id!
          })
        );
      }
    }
  }, [course?.participants, dispatch, id]);

  const handleStopParticipantsMachines = useCallback(() => {
    for (const participant of course?.participants || []) {
      if (participant.desktop?.instanceId && !participant.desktop?.isRunning) {
        dispatch(
          stopParticipantDesktop({
            courseId: id!,
            desktopInstanceId: participant.desktop?.instanceId,
            participantId: participant.id!
          })
        );
      }
    }
  }, [course?.participants, dispatch, id]);

  const handleRemoveParticipantsMachines = useCallback(() => {
    for (const participant of course?.participants || []) {
      if (participant.desktop?.instanceId && !participant.desktop?.isRunning) {
        dispatch(
          deleteParticipantDesktop({
            courseId: id!,
            desktopInstanceId: participant.desktop?.instanceId,
            participantId: participant.id!
          })
        );
      }
    }
  }, [course?.participants, dispatch, id]);

  const handleSaveMachineIframeSize = useCallback(
    (machineIframeSize: number) => {
      dispatch(
        saveMachineIframeSize({
          courseId: id,
          machineIframeSize
        } as SaveMachineIframeSizeRequest)
      );
    },
    [id, dispatch]
  );

  useEffect(() => {
    const courseId = id as string;

    if (!course) {
      dispatch(getCourse(courseId)).then(() => {
        dispatch(getMyDesktop(courseId));
        dispatch(getFreshStartDesktop(courseId));
        dispatch(getParticipants(courseId));
      });
    } else {
      !course.joinCode && dispatch(getCourseAdditionalData(courseId));
      !course.myDesktop && dispatch(getMyDesktop(courseId));
      !course.freshStartDesktop && dispatch(getFreshStartDesktop(courseId));
      !course.participants?.length && dispatch(getParticipants(courseId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {!areParticipantsLoading && course ? (
        <>
          <TopRow>
            <LeftButtonsContainer>
              {!course.isCompleted ? (
                <>
                  <Link to={urls.courseSettingsPage(course.id!)}>
                    <Button variant="outlined">{t('coursePanel.courseSettings')}</Button>
                  </Link>

                  <SettingsButtonModal
                    onStartParticipantsMachines={handleStartParticipantsMachines}
                    onStopParticipantsMachines={handleStopParticipantsMachines}
                    onRemoveParticipantsMachines={handleRemoveParticipantsMachines}
                    shouldShowStartParticipantsMachines={
                      !!(
                        course.hasFreshStart &&
                        course.isFreshStartMachineReadyToClone &&
                        course?.participants?.length &&
                        course?.participants?.some(x => x.desktop?.instanceId)
                      )
                    }
                    shouldShowStopParticipantsMachines={
                      !!(
                        course?.participants?.length &&
                        course?.participants?.some(x => x.desktop?.isRunning)
                      )
                    }
                    shouldShowRemoveParticipantsMachines={
                      !!(
                        course?.participants?.length &&
                        course?.participants?.some(x => x.desktop?.instanceId)
                      )
                    }
                    joinLink={joinLink}
                  />
                </>
              ) : (
                <Typography variant={Typo.title}>{t('coursePanel.trainingCompleted')}</Typography>
              )}
            </LeftButtonsContainer>

            <ListModeActions
              listMode={listMode}
              defaultMachineIframeSize={course.machineIframeSize as number}
              onListModeChange={setListMode}
              onMachineIframeSizeChange={handleMachineIframeSize}
              onSaveMachineIframeSize={handleSaveMachineIframeSize}
            />
          </TopRow>

          <ParticipantList
            course={course}
            participantList={course.participants || []}
            trainer={
              {
                displayName: currentUserModel.displayName,
                avatar: currentUserModel.avatar,
                id: currentUserModel.id,
                desktop: course.myDesktop
              } as ParticipantModel
            }
            columns={columns}
          />
        </>
      ) : (
        ''
      )}

      {areParticipantsLoading ? (
        <Skeleton variant="rounded" animation="wave" width={200} height={200} sx={{ m: 'auto' }} />
      ) : (
        ''
      )}
    </Container>
  );
}

export default memo(CoursePage);

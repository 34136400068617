interface DropdownOption {
  id: string;
  label: string;
}

export type DropdownOptions = Array<DropdownOption>;

export const memoryFormValues: DropdownOptions = [
  {
    label: '2 GB',
    id: '2'
  },
  {
    label: '4 GB',
    id: '4'
  },
  {
    label: '8 GB',
    id: '8'
  },
  {
    label: '16 GB',
    id: '16'
  },
  {
    label: '32 GB',
    id: '32'
  },
  {
    label: '64 GB',
    id: '64'
  }
];

export const cpyFormValues: DropdownOptions = [
  {
    label: '1 CPU',
    id: '1'
  },
  {
    label: '2 CPU',
    id: '2'
  },
  {
    label: '3 CPU',
    id: '3'
  },
  {
    label: '4 CPU',
    id: '4'
  },
  {
    label: '6 CPU',
    id: '6'
  },
  {
    label: '8 CPU',
    id: '8'
  },
  {
    label: '16 CPU',
    id: '16'
  }
];

export const diskSizeFormValues: DropdownOptions = [
  {
    label: '50 GB',
    id: '50'
  },
  {
    label: '100 GB',
    id: '100'
  },
  {
    label: '150 GB',
    id: '150'
  },
  {
    label: '200 GB',
    id: '200'
  },
  {
    label: '250 GB',
    id: '250'
  },
  {
    label: '300 GB',
    id: '300'
  },
  {
    label: '350 GB',
    id: '350'
  },
  {
    label: '400 GB',
    id: '400'
  },
  {
    label: '450 GB',
    id: '450'
  }
];

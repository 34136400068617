import { ChangeEvent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { InputAdornment, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { Colors } from 'Themes/colors';
import { Spacing } from 'Themes/spacing';
import { useAppDispatch, useAppSelector } from 'Store';
import { saveIsStartBannerHidden, selectIsStartBannerHidden } from 'User/userSlice';
import { LoginState, selectLoginState } from 'Auth/authSlice';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${Spacing.large} 0;
  > * {
    width: 300px;
  }
`;

const StyledTextField = styled(TextField)`
  background-color: ${Colors.white};
  width: 348px !important;
  align-self: center;

  .MuiOutlinedInput-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${Colors.red};
    }
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

const SpanStyledAsLink = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  text-decoration-line: underline;
  color: ${Colors.blue};
  cursor: pointer;
`;

const FoldedStartBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Colors.lineGrey};
  background: ${Colors.white};
  padding: ${Spacing.small};
  gap: ${Spacing.small};
`;

interface SearchBarProps {
  value: string;
  onChangeText: (searchText: string) => void;
}

const SearchBar = ({ value, onChangeText }: SearchBarProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => onChangeText(event.target.value);
  const clearValue = () => onChangeText('');

  const loginState = useAppSelector(selectLoginState);
  const isStartBannerHidden = useAppSelector(selectIsStartBannerHidden);
  const showStartBanner = () => {
    dispatch(
      saveIsStartBannerHidden({
        isStartBannerHidden: false,
        isLogged: loginState === LoginState.LOGGED
      })
    );
  };

  return (
    <Container>
      {isStartBannerHidden ? (
        <FoldedStartBanner>
          <Typography>{t('welcomeInTreneo')}</Typography>
          <SpanStyledAsLink onClick={showStartBanner}>{t('whatIsTreneo')}</SpanStyledAsLink>
        </FoldedStartBanner>
      ) : (
        <div></div>
      )}
      <StyledTextField
        color={!value ? 'primary' : 'error'}
        label={!value ? t('coursesPage.searchCourse') : t('coursesPage.searchResults')}
        fullWidth
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {!value ? <SearchIcon /> : <StyledCloseIcon onClick={clearValue} />}
            </InputAdornment>
          )
        }}
        value={value}
      />
      <div></div>
    </Container>
  );
};

export default memo(SearchBar);

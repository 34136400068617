import React, { memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { HistoryRouter } from 'redux-first-history/rr6';
import { history } from 'Store';
import {
  RequireAuth,
  NotRequireAuth,
  AuthLayoutRoute,
  ForgotPasswordPage,
  LoginPage,
  RegistrationPage,
  ResetPasswordPage,
  JoinToCoursePage
} from 'Auth';
import { CoursesPage } from 'Courses';
import { CoursePage } from 'Course';
import CourseSettingsPage from 'Course/Settings/CourseSettingsPage';
import NotFoundPage from 'Common/NotFoundPage/NotFoundPage';
import ForbiddenPage from 'Common/ForbiddenPage/ForbiddenPage';
import { urls } from './urls';

const Router = () => {
  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route element={<RequireAuth />}>
          <Route path={urls.homePage} element={<CoursesPage />} />
          <Route path={urls.coursePage(':id')} element={<CoursePage />} />
          <Route path={urls.courseSettingsPage(':id')} element={<CourseSettingsPage />} />
        </Route>

        <Route element={<NotRequireAuth />}>
          <Route path={urls.forbiddenPage} element={<ForbiddenPage />} />
          <Route path={urls.notFoundPage} element={<NotFoundPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>

        <Route element={<AuthLayoutRoute />}>
          <Route path={urls.loginPage} element={<LoginPage />} />
          <Route path={urls.registrationPage} element={<RegistrationPage />} />
          <Route path={urls.confirmEmailAndActivateAccountPage(':code')} element={<LoginPage />} />
          <Route path={urls.forgotPasswordPage} element={<ForgotPasswordPage />} />
          <Route path={urls.resetPasswordPage(':hash')} element={<ResetPasswordPage />} />
          <Route
            path={urls.joinToCoursePage(':joinCode')}
            element={<JoinToCoursePage shouldSkipConfirmation={false} />}
          />
        </Route>
      </Routes>
    </HistoryRouter>
  );
};

export default memo(Router);

import { Pipe, custom, minLength } from 'valibot'; // 0.63 k
import {
  passwordMustContainAtLeastOneNumberTranslationKey,
  passwordMustContainAtLeastOneSpecialCharacterTranslationKey,
  passwordMustContainAtLeastOneUppercaseLetterTranslationKey,
  passwordMustHave8CharactersOrMoreTranslationKey,
  stringRequiredWrapper
} from './schema.utils';

export const PasswordPipes: Pipe<string> = [
  custom(
    (value: string) => value.match(/[A-Z]/) !== null,
    passwordMustContainAtLeastOneUppercaseLetterTranslationKey
  ),
  custom(
    (value: string) => value.match(/[0-9]/) !== null,
    passwordMustContainAtLeastOneNumberTranslationKey
  ),
  custom(
    (value: string) => value.match(/[^a-zA-Z0-9]/) !== null,
    passwordMustContainAtLeastOneSpecialCharacterTranslationKey
  ),
  minLength(8, passwordMustHave8CharactersOrMoreTranslationKey)
];

export const PasswordSchema = stringRequiredWrapper(PasswordPipes);

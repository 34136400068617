export namespace Colors {
  export const lightGrey = '#F2F6F9';
  export const lineGrey = '#E6E6E6';
  export const darkGrey = '#87909A';
  export const textColor = '#3E4247';
  export const black = '#000000';
  export const red = '#E6244F';
  export const blue = '#3D7ED1';
  export const green = '#3D9A9E';
  export const white = '#ffffff';

  export const switchChecked = '#EAEAEA';

  export const disabledButton = '#BFC5C8';

  export const availableUsersAvatarsColors = [
    { value: '#8BA90F', translationKey: 'lightGreen' },
    { value: '#3D9E41', translationKey: 'darkGreen' },
    { value: '#0EABE6', translationKey: 'lightBlue' },
    { value: blue, translationKey: 'darkBlue' },
    { value: '#E6DC3C', translationKey: 'yellow' },
    { value: '#D17D47', translationKey: 'orange' },
    { value: red, translationKey: 'red' },
    { value: '#947065', translationKey: 'brown' },
    { value: '#ED6BC8', translationKey: 'pink' },
    { value: '#8C4BDE', translationKey: 'violet' },
    { value: '#929292', translationKey: 'grey' },
    { value: black, translationKey: 'black' }
  ];
}

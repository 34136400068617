import { memo } from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import courses_empty from 'Assets/images/courses_empty.svg';
import { Spacing } from 'Themes/spacing';
import { Link, SingleLine } from 'Components';
import { urls } from 'Router';

const Wrapper = styled.div`
  display: flex;
  gap: ${Spacing.large};
  align-items: flex-start;
  width: 100%;
  margin: ${Spacing.large} 0;
  justify-content: center;
  & > * {
    flex: 1;
    max-width: 350px;
  }
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.medium};
`;

function CoursesEmpty() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <img src={courses_empty} width="100%" />
      <RightColumn>
        <Typography>{t('coursesEmpty.firstLine')}</Typography>
        <SingleLine>
          <Typography>{t('coursesEmpty.secondLine')}</Typography>
          <Link to={urls.treneoSite} label={t('coursesEmpty.here')} />{' '}
        </SingleLine>
      </RightColumn>
    </Wrapper>
  );
}

export default memo(CoursesEmpty);

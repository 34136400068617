import { memo } from 'react';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useAppDispatch, useAppSelector } from 'Store';
import { Themes } from 'Consts/themes';
import { LoginState, selectLoginState } from 'Auth/authSlice';
import { selectCurrentTheme, saveTheme } from 'User/userSlice';
import { StyledWrapper, ThemeSwitcherButton } from './ThemeSwitcher.styles';

export const ThemeSwitcher = () => {
  const dispatch = useAppDispatch();
  const currentTheme = useAppSelector(selectCurrentTheme);
  const loginState = useAppSelector(selectLoginState);

  const handleChangeTheme = () => {
    const isLogged = loginState === LoginState.LOGGED;

    currentTheme === Themes.light
      ? dispatch(saveTheme({ theme: Themes.dark, isLogged }))
      : dispatch(saveTheme({ theme: Themes.light, isLogged }));
  };

  return null; // TODO: wait for design

  // return (
  //   <StyledWrapper>
  //     <ThemeSwitcherButton onClick={handleChangeTheme} color="inherit">
  //       {currentTheme === Themes.dark ? <Brightness7Icon /> : <Brightness4Icon />}
  //     </ThemeSwitcherButton>
  //   </StyledWrapper>
  // );
};

export default memo(ThemeSwitcher);

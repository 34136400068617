import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import {
  authApi,
  ChangePasswordRequest,
  ConfirmEmailAndActivateAccountRequest,
  ForgotPasswordRequest,
  LogInAndJoinToCourseRequest,
  LogInOrSignUpViaGoogleRequest,
  LogInOrSignUpViaLinkedInRequest,
  LogInRequest,
  RegisterAndJoinToCourseRequest,
  RegisterRequest,
  ResetPasswordRequest
} from 'Api';
import { RootState } from 'Store';
import { urls } from 'Router';
import { clearCourses } from 'Courses/coursesSlice';
import { clearCurrentUserInfo, getCurrentUserInfo } from 'User/userSlice';
import { disconnectSignalR } from 'SignalR';

export const enum LoginState {
  LOGGED,
  PENDING,
  NOT_LOGGED,
  LOGIN_REJECTED
}

export interface AuthState {
  loginState: LoginState;
  loginError?: string | null | undefined;
  registrationError?: string | null | undefined;
  resetPasswordError?: string | null | undefined;
  changePasswordError?: string | null | undefined;
  isChangePasswordSuccess?: boolean;
  isRegistrationSuccess?: boolean;
}

const initialState: AuthState = {
  loginState: LoginState.NOT_LOGGED
};

async function getCurrentUserInfoAndRedirectToHomePageAfterSuccess(
  callback: any /* TODO: GetThunkAPI<AsyncThunkConfig>Promise<AxiosResponse<any, any>> */,
  thunkApi: any /* TODO: GetThunkAPI<AsyncThunkConfig> */
) {
  try {
    const result = await callback;

    if (!(result as any) /* TODO: type */.data.errorTranslationCode) {
      thunkApi.dispatch(getCurrentUserInfo());
      thunkApi.dispatch(push(urls.homePage));
    }

    return result;
  } catch (exception: any /* TODO: type */) {
    return thunkApi.rejectWithValue(exception.response.data);
  }
}

async function handleError(
  callback: any /* TODO: GetThunkAPI<AsyncThunkConfig>Promise<AxiosResponse<any, any>> */,
  thunkApi: any /* TODO: GetThunkAPI<AsyncThunkConfig> */
) {
  try {
    await callback;
  } catch (exception: any /* TODO: type */) {
    return thunkApi.rejectWithValue(exception.response.data);
  }
}

export const logInOrSignUpViaGoogle = createAsyncThunk(
  'auth/logInOrSignUpViaGoogle',
  async (request: LogInOrSignUpViaGoogleRequest, thunkApi) =>
    getCurrentUserInfoAndRedirectToHomePageAfterSuccess(
      authApi.authLogInOrSignUpViaGooglePost(request),
      thunkApi
    )
);

export const logInOrSignUpViaLinkedIn = createAsyncThunk(
  'auth/logInOrSignUpViaLinkedIn',
  async (request: LogInOrSignUpViaLinkedInRequest, thunkApi) =>
    getCurrentUserInfoAndRedirectToHomePageAfterSuccess(
      authApi.authLogInOrSignUpViaLinkedInPost(request),
      thunkApi
    )
);

export const login = createAsyncThunk('auth/login', async (request: LogInRequest, thunkApi) =>
  getCurrentUserInfoAndRedirectToHomePageAfterSuccess(authApi.authLogInPost(request), thunkApi)
);

export const loginAndJoinToCourse = createAsyncThunk(
  'auth/loginAndJoinToCourse',
  async (request: LogInAndJoinToCourseRequest, thunkApi) =>
    getCurrentUserInfoAndRedirectToHomePageAfterSuccess(
      authApi.authLogInAndJoinToCoursePost(request),
      thunkApi
    )
);

export const register = createAsyncThunk(
  'auth/register',
  async (request: RegisterRequest, thunkApi) => {
    request.origin = window.location.origin + import.meta.env.BASE_URL;
    return handleError(authApi.authRegisterPost(request), thunkApi);
  }
);

export const confirmEmailAndActivateAccount = createAsyncThunk(
  'auth/confirmEmailAndActivateAccount',
  async (code: string) =>
    authApi.authConfirmEmailAndActivateAccountPost({
      code
    } as ConfirmEmailAndActivateAccountRequest)
);

export const registerAndJoinToCourse = createAsyncThunk(
  'auth/registerAndJoinToCourse',
  async (request: RegisterAndJoinToCourseRequest, thunkApi) =>
    getCurrentUserInfoAndRedirectToHomePageAfterSuccess(
      authApi.authRegisterAndJoinToCoursePost(request),
      thunkApi
    )
);

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (request: ForgotPasswordRequest, thunkApi) => {
    request.origin = window.location.origin + import.meta.env.BASE_URL;
    return handleError(authApi.authForgotPasswordPost(request), thunkApi);
  }
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (request: ResetPasswordRequest, thunkApi) => {
    try {
      await authApi.authResetPasswordPost(request);
      thunkApi.dispatch(push(urls.loginPage, { isAfterResetPassword: true }));
    } catch (exception: any /* TODO: type */) {
      return thunkApi.rejectWithValue(exception.response.data);
    }
  }
);

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async (request: ChangePasswordRequest, thunkApi) =>
    handleError(authApi.authChangePasswordPost(request), thunkApi)
);

export const removeAccount = createAsyncThunk('auth/removeAccount', async () =>
  authApi.authRemoveAccountPost()
);

export const logout = createAsyncThunk('auth/logout', async (_, thunkApi) => {
  await authApi.authLogOutPost();
  thunkApi.dispatch(clearCurrentUserInfo());
  thunkApi.dispatch(clearCourses());
  disconnectSignalR();
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearLoginError(state) {
      state.loginError = null;
    },
    clearRegistrationError(state) {
      state.registrationError = null;
    },
    clearResetPasswordError(state) {
      state.resetPasswordError = null;
    },
    clearChangePasswordError(state) {
      state.changePasswordError = null;
    },
    clearIsChangePasswordSuccess(state) {
      state.isChangePasswordSuccess = false;
    },
    clearIsRegistrationSuccess(state) {
      state.isRegistrationSuccess = false;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(login.pending, state => {
        state.loginState = LoginState.PENDING;
        state.loginError = null;
      })
      .addCase(login.fulfilled, state => {
        state.loginState = LoginState.LOGGED;
        state.loginError = null;
      })
      .addCase(login.rejected, (state, action: any /* TODO: type */) => {
        state.loginState = LoginState.LOGIN_REJECTED;
        state.loginError = action.payload.message;
      })

      .addCase(logInOrSignUpViaGoogle.pending, state => {
        state.loginState = LoginState.PENDING;
        state.loginError = null;
      })
      .addCase(logInOrSignUpViaGoogle.fulfilled, state => {
        state.loginState = LoginState.LOGGED;
        state.loginError = null;
      })
      .addCase(logInOrSignUpViaGoogle.rejected, (state, action: any /* TODO: type */) => {
        state.loginState = LoginState.LOGIN_REJECTED;
        state.loginError = action.payload.message;
      })

      .addCase(logInOrSignUpViaLinkedIn.pending, state => {
        state.loginState = LoginState.PENDING;
        state.loginError = null;
      })
      .addCase(logInOrSignUpViaLinkedIn.fulfilled, state => {
        state.loginState = LoginState.LOGGED;
        state.loginError = null;
      })
      .addCase(logInOrSignUpViaLinkedIn.rejected, (state, action: any /* TODO: type */) => {
        state.loginState = LoginState.LOGIN_REJECTED;
        state.loginError = action.payload.message;
      })
      .addCase(loginAndJoinToCourse.pending, state => {
        state.loginState = LoginState.PENDING;
        state.loginError = null;
      })
      .addCase(loginAndJoinToCourse.fulfilled, (state, action: any /* TODO: type */) => {
        if (!action.payload.data.errorTranslationCode) {
          state.loginState = LoginState.LOGGED;
          state.loginError = null;
        } else {
          state.loginState = LoginState.LOGIN_REJECTED;
          state.loginError = action.payload.data.errorTranslationCode;
        }
      })
      .addCase(loginAndJoinToCourse.rejected, (state, action: any /* TODO: type */) => {
        state.loginState = LoginState.LOGIN_REJECTED;
        if (action.payload?.message) {
          state.loginError = action.payload.message;
        }
      })
      .addCase(register.pending, state => {
        state.registrationError = null;
        state.isRegistrationSuccess = false;
      })
      .addCase(register.fulfilled, state => {
        state.registrationError = null;
        state.isRegistrationSuccess = true;
      })
      .addCase(register.rejected, (state, action: any /* TODO: type */) => {
        state.registrationError = action.payload.message;
        state.isRegistrationSuccess = false;
      })
      .addCase(registerAndJoinToCourse.pending, state => {
        state.loginState = LoginState.PENDING;
        state.registrationError = null;
      })
      .addCase(registerAndJoinToCourse.fulfilled, (state, action: any /* TODO: type */) => {
        if (!action.payload.data.errorTranslationCode) {
          state.loginState = LoginState.LOGGED;
          state.registrationError = null;
        } else {
          state.loginState = LoginState.LOGIN_REJECTED;
          state.registrationError = action.payload.data.errorTranslationCode;
        }
      })
      .addCase(registerAndJoinToCourse.rejected, (state, action: any /* TODO: type */) => {
        state.loginState = LoginState.LOGIN_REJECTED;
        if (action.payload?.message) {
          state.registrationError = action.payload.message;
        }
      })
      .addCase(resetPassword.pending, state => {
        state.resetPasswordError = null;
      })
      .addCase(resetPassword.fulfilled, state => {
        state.resetPasswordError = null;
      })
      .addCase(resetPassword.rejected, (state, action: any /* TODO: type */) => {
        state.resetPasswordError = action.payload.message;
      })
      .addCase(changePassword.pending, state => {
        state.changePasswordError = null;
        state.isChangePasswordSuccess = false;
      })
      .addCase(changePassword.fulfilled, state => {
        state.changePasswordError = null;
        state.isChangePasswordSuccess = true;
      })
      .addCase(changePassword.rejected, (state, action: any /* TODO: type */) => {
        state.changePasswordError = action.payload.message;
        state.isChangePasswordSuccess = false;
      })
      .addCase(logout.fulfilled, state => {
        state.loginState = LoginState.NOT_LOGGED;
        state.loginError = null;
        state.registrationError = null;
      });
  }
});

export const {
  clearLoginError,
  clearRegistrationError,
  clearResetPasswordError,
  clearChangePasswordError,
  clearIsChangePasswordSuccess,
  clearIsRegistrationSuccess
} = authSlice.actions;

export default authSlice.reducer;

const selectSelf = (state: RootState) => state.auth;

export const selectLoginState = createSelector(selectSelf, state => state.loginState);
export const selectLoginError = createSelector(selectSelf, state => state.loginError);
export const selectRegistrationError = createSelector(selectSelf, state => state.registrationError);
export const selectResetPasswordError = createSelector(
  selectSelf,
  state => state.resetPasswordError
);
export const selectChangePasswordError = createSelector(
  selectSelf,
  state => state.changePasswordError
);

export const selectIsChangePasswordSuccess = createSelector(
  selectSelf,
  state => state.isChangePasswordSuccess
);
export const selectIsRegistrationSuccess = createSelector(
  selectSelf,
  state => state.isRegistrationSuccess
);

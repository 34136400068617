import { memo } from 'react';
import { useWatch } from 'react-hook-form';
import styled, { css } from 'styled-components';
import almaLinuxLogo from 'Assets/images/os/almaLinuxLogo.png';
import centosLogo from 'Assets/images/os/centosLogo.png';
import debianLogo from 'Assets/images/os/debianLogo.png';
import kaliLogo from 'Assets/images/os/kaliLogo.png';
import microsoftWindowsLogo from 'Assets/images/os/microsoftWindowsLogo.png';
import ubuntuLogo from 'Assets/images/os/ubuntuLogo.png';
import noneLogo from 'Assets/images/os/noneLogo.svg';
import { Colors } from 'Themes/colors';
import { DropdownOptions } from './DropdownOptions.type';
import { formFieldNames } from './formFieldNames';

const Option = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  padding: 5px 24px;
  gap: 21px;
  border-radius: 22px;
  outline: 1px solid ${Colors.darkGrey};
  cursor: pointer;

  &:hover {
    outline-width: 2px;
    outline-color: ${Colors.blue};
  }

  ${({ selected }) =>
    selected &&
    css`
      outline-width: 2px;
      outline-color: ${Colors.textColor};
      background: ${Colors.lightGrey};
    `};
`;

const Logo = styled.img`
  height: 28px;
  width: 28px;
  object-fit: contain;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const GetOsIcon = (id: string) => {
  if (id.startsWith('almalinux')) {
    return almaLinuxLogo;
  }

  if (id.startsWith('centos')) {
    return centosLogo;
  }

  if (id.startsWith('debian')) {
    return debianLogo;
  }

  if (id.startsWith('kali')) {
    return kaliLogo;
  }

  if (id.startsWith('windows')) {
    return microsoftWindowsLogo;
  }

  if (id.startsWith('ubuntu')) {
    return ubuntuLogo;
  }

  return noneLogo;
};

interface Props {
  options?: DropdownOptions;
  onTemplateClick: (id: string) => void;
}

function OsSelectElement({ options = [], onTemplateClick }: Props) {
  const selectedTemplate = useWatch({ name: formFieldNames.template });

  const handleTemplateClick = (id: string) => onTemplateClick(id);

  return (
    <Container>
      {options.map(option => (
        <Option
          selected={selectedTemplate === option.id}
          key={option.id}
          onClick={() => handleTemplateClick(option.id)}
        >
          <Logo alt="os logo" src={GetOsIcon(option.id)} />
          {option.label}
        </Option>
      ))}
    </Container>
  );
}

export default memo(OsSelectElement);

import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { urls } from 'Router';
import { Link } from 'Components';
import styled from 'styled-components';

const Container = styled.span`
  display: flex;
  gap: 5px;
`;

function AcceptTermsAndPrivacyPolicyMessage() {
  const { t } = useTranslation();

  return (
    <Container>
      {t('auth.acceptTermsPart1')}{' '}
      <Link to={urls.regulationsPage} label={t('auth.termsOfUse')} shouldOpenInNewTab={false} />{' '}
      {t('auth.acceptTermsPart2')}{' '}
      <Link
        to={urls.privacyPolicyPage}
        label={t('auth.privacyPolicy')}
        shouldOpenInNewTab={false}
      />
    </Container>
  );
}

export default memo(AcceptTermsAndPrivacyPolicyMessage);

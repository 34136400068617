import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import styled from 'styled-components';
import { Colors } from 'Themes/colors';
import { Typo } from 'Themes/typo';
import { Spacing } from 'Themes/spacing';
import { Logo } from 'Components';

const Wrapper = styled.div`
  margin-top: auto;
`;

const Container = styled.div`
  margin-top: ${Spacing.xlarge};
  background: ${Colors.white};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  padding-bottom: ${Spacing.large};
  &:before {
    content: '';
    background: ${Colors.white};
    position: absolute;
    height: 100%;
    width: 100vw;
    z-index: -1;
    margin: 0 calc(-50vw + 50%);
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 25px;
  padding: 25px 0;
`;

function Footer() {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Container>
        <Column>
          <Logo variant="footer" />
          <Typography>Treneo@2022 {t('footer.allRightsReserved')}</Typography>
        </Column>
        <Column>
          <Typography variant={Typo.title}>{t('footer.contact')}</Typography>
          <Typography>email: kontakt@treneo.pl</Typography>
          <Typography>tel.: +48 71 88 11 689</Typography>
        </Column>
        <Column>
          <Typography variant={Typo.title}>{t('footer.navigation')}</Typography>
          <Typography>{t('footer.training')}</Typography>
          <Typography>{t('footer.whatIsTreneo')}</Typography>
          <Typography>{t('footer.myAccount')}</Typography>
          <Typography>{t('footer.privacy')}</Typography>
          <Typography>{t('footer.statue')}</Typography>
        </Column>
      </Container>
    </Wrapper>
  );
}

export default memo(Footer);

import { memo } from 'react';
import { Stack, Typography } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import styled from 'styled-components';
import { CourseDates } from 'Api';
import { Colors } from 'Themes/colors';
import { DateInfo } from 'Common/DateInfo';
import { TimeInfo } from 'Common/TimeInfo';

const StyledPlayArrowIcon = styled(PlayArrowIcon)`
  color: ${Colors.darkGrey};
  margin-left: -0.5rem !important;
`;

interface DatesProps {
  dates: CourseDates[] | undefined;
}

export function Dates({ dates }: DatesProps) {
  const dateComponents = dates?.map((date, index) => {
    console.log(date);

    return (
      <Stack key={index} direction="row" spacing={1} alignItems="center">
        <StyledPlayArrowIcon />
        <Typography>
          {date.startDateTime ? (
            <>
              <DateInfo date={new Date(date.startDateTime)} />{' '}
              <TimeInfo date={new Date(date.startDateTime)} />
            </>
          ) : (
            ''
          )}{' '}
          -{' '}
          {date.endDateTime ? (
            <>
              <DateInfo date={new Date(date.endDateTime)} />{' '}
              <TimeInfo date={new Date(date.endDateTime)} />
            </>
          ) : (
            ''
          )}
        </Typography>
      </Stack>
    );
  });

  return dates ? <Stack gap={1}>{dateComponents}</Stack> : '';
}

export default memo(Dates);

import React, { memo, useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { Spacing } from 'Themes/spacing';
import { Colors } from 'Themes/colors';

const MainContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  background: ${Colors.white};
  border: 2px solid ${Colors.textColor};
  border-radius: 7px;
  display: flex;
  padding: ${Spacing.medium};
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${Spacing.medium};
`;

const StyledIconButton = styled(IconButton)`
  margin: -14px !important;
`;

const ChildrenContainer = styled.div``;

interface Props {
  onClose: () => void;
  children: React.ReactNode;
}

function OptionsModal({ onClose, children }: Props) {
  const ref = useRef(null);
  useOnClickOutside(ref, onClose);

  return (
    <MainContainer ref={ref}>
      <ChildrenContainer>{children}</ChildrenContainer>
      <StyledIconButton onClick={onClose}>
        <CloseIcon fontSize="medium" />
      </StyledIconButton>
    </MainContainer>
  );
}

export default memo(OptionsModal);

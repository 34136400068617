import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PasswordField } from 'Common/Form';

const formFieldNames = {
  password: 'password',
  confirmPassword: 'confirmPassword'
} as const;

interface PasswordAndConfirmPasswordFieldsProps {
  formContext: UseFormReturn;
  passwordLabel?: string;
  confirmPasswordLabel?: string;
}

export function PasswordAndConfirmPasswordFields({
  formContext,
  passwordLabel,
  confirmPasswordLabel
}: PasswordAndConfirmPasswordFieldsProps) {
  const { t } = useTranslation();

  const [passwordsNotMatch, setPasswordsNotMatch] = useState<boolean>();

  const passwordValue = formContext.watch(formFieldNames.password);
  const confirmPasswordValue = formContext.watch(formFieldNames.confirmPassword);

  useEffect(() => {
    if (passwordValue && confirmPasswordValue && passwordValue !== confirmPasswordValue) {
      setPasswordsNotMatch(true);
    } else {
      setPasswordsNotMatch(false);
    }
  }, [passwordValue, confirmPasswordValue]);

  return (
    <>
      <PasswordField name={formFieldNames.password} label={passwordLabel} />

      <PasswordField
        name={formFieldNames.confirmPassword}
        label={confirmPasswordLabel ? confirmPasswordLabel : t('auth.confirmPassword')}
        helperText={passwordsNotMatch ? t('password_not_match') : ''}
        error={passwordsNotMatch}
      />
    </>
  );
}

export default PasswordAndConfirmPasswordFields;

import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface CloseIconButtonProps {
  onClose: () => void;
  className?: string;
}

// TODO: this is similar component like "CloseDialogIconButton", only without "sx" prop -> maybe merge both component to the one
const CloseIconButton = ({ onClose, className }: CloseIconButtonProps) =>
  onClose ? (
    <IconButton aria-label="close" onClick={onClose} className={className}>
      <CloseIcon />
    </IconButton>
  ) : null;

export default CloseIconButton;

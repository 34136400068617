import { IconButton, Typography } from '@mui/material';
import { styled, css } from '@mui/system';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Colors } from 'Themes/colors';
import { Spacing } from 'Themes/spacing';

const transition = 'all .1s ease-in-out';

interface ConnectIconButtonProps {
  isFreshStart?: boolean;
  isHighlighted?: boolean;
  disabled?: boolean;
}

export const ConnectIconButton = styled(IconButton)<ConnectIconButtonProps>`
  width: 52px;
  height: 52px;
  color: ${Colors.white};
  z-index: 1;
  transition: ${transition};
  background-color: ${Colors.red};

  ${({ isHighlighted }) =>
    !isHighlighted &&
    css`
      background-color: ${Colors.white};
      border: 1px solid ${Colors.darkGrey};
    `}

  ${({ isFreshStart }) =>
    isFreshStart &&
    css`
      background-color: ${Colors.green};
    `}

  &:hover {
    background-color: ${Colors.blue};
    transition: ${transition};
    border: none;

    svg {
      color: ${Colors.white};
    }
  }

  + div p {
    font-weight: 700;
  }
`;

interface PlayArrowIconProps {
  isFreshStart?: boolean;
  isHighlighted?: boolean;
}

export const StyledPlayArrowIcon = styled(PlayArrowIcon)<PlayArrowIconProps>`
  color: ${Colors.white};

  &:hover {
    color: ${Colors.white};
  }

  ${({ isHighlighted }) =>
    !isHighlighted &&
    css`
      color: ${Colors.textColor};
    `}

  ${({ isFreshStart }) =>
    isFreshStart &&
    css`
      color: ${Colors.white};
    `}
`;

interface SecondaryComponentProps {
  disabled?: boolean;
}

interface SecondaryButtonWrapperProps extends SecondaryComponentProps {
  imitateButton?: boolean;
}

export const SecondaryButtonWrapper = styled('div')<SecondaryButtonWrapperProps>`
  border: 1px solid ${Colors.darkGrey};
  height: 38px;
  display: flex;
  padding-left: 25px;
  align-items: center;
  /* TODO Vorbert: Extract it to the Colors consts */
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-left: 0 !important;
  transform: translate(-15px);

  ${({ disabled, imitateButton }) =>
    imitateButton &&
    !disabled &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
`;

export const SecondaryButtonText = styled(Typography)<SecondaryComponentProps>`
  padding-right: ${Spacing.small};
  font-weight: 700;
  color: ${Colors.textColor};

  ${({ disabled }) =>
    disabled &&
    css`
      /* TODO Norbert: Connect MUI Theme to the styled-components and use disabled text value here */
      color: rgba(0, 0, 0, 0.26);
      pointer-events: none;
    `}
`;

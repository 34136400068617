export const urls = {
  homePage: '/',
  loginPage: '/login',
  registrationPage: '/registration',
  confirmEmailAndActivateAccountPage: (code: string) => `/confirmEmailAndActivateAccount/${code}`,
  forgotPasswordPage: '/forgotPassword',
  resetPasswordPage: (hash: string) => `/resetPassword/${hash}`,
  joinToCoursePage: (joinCode: string) => `/j/${joinCode}`,
  courseSettingsPage: (id: string) => `/course/${id}/settings`,
  coursePage: (id: string) => `/course/${id}`,
  regulationsPage: '/regulations',
  privacyPolicyPage: '/privacyPolicy',
  unauthorizedPage: '/401',
  forbiddenPage: '/403',
  notFoundPage: '/404',
  treneoSite: 'https://treneo.pl'
};

import { Colors } from 'Themes/colors';

export namespace AvatarUtils {
  export const getAvatarInitials = (text: string) => {
    return text.at(0)?.toUpperCase();
  };

  export const generateHashFromText = (text: string): number => {
    let hash = 0;

    for (let i = 0; i < text.length; i++) {
      hash = text.charCodeAt(i) + ((hash << 5) - hash);
    }

    return hash;
  };

  export const generateHexColorFromHash = (hash: number): string => {
    let color = '#';

    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += ('00' + value.toString(16)).substring(2);
    }

    return color;
  };

  export const generateHexColorFromInitials = (text: string | undefined): string => {
    const charCode = text?.charCodeAt(0) || 0;
    return Colors.availableUsersAvatarsColors.map(x => x.value)[
      charCode % Colors.availableUsersAvatarsColors.length
    ];
  };

  export const getRandomHexColor = (): string =>
    Colors.availableUsersAvatarsColors.map(x => x.value)[
      Math.floor(Math.random() * Colors.availableUsersAvatarsColors.length)
    ];
}

import { useEffect } from 'react';
import { useAppDispatch } from 'Store';
import { BreadcrumbItem, setBreadcrumbs } from './breadcrumbSlice';

export function useBreadcrumbs(items: BreadcrumbItem[]) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setBreadcrumbs(items));

    return () => {
      dispatch(setBreadcrumbs([]));
    };
  }, [dispatch, items]);
}
